function formatMoneyInput(value) {
  if (!value || value === "0") return "";
  return value.replace(/^(\d+.?\d{0,2})\d*$/, "$1");
  // const formatted = /^\d+(\.\d{0,2})?/g.exec(value);
  // return formatted ? formatted[0] : "";
}

function formatMaxChars(value, max) {
  if (!value) return value;
  return value.slice(0, max);
}

function formatCurrencyDisplay(_amount = 0) {
  const amount = Number(_amount);
  const paddedAmt = !Number.isInteger(amount) ? amount.toFixed(2) : amount;
  return paddedAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function formatCurrencyWithSymbol(amount) {
  return `$${formatCurrencyDisplay(amount)}`;
}

function formatImpact(donation, matchingX) {
  const total = donation * matchingX;
  return formatCurrencyDisplay(total);
}

export {
  formatMoneyInput,
  formatMaxChars,
  formatImpact,
  formatCurrencyDisplay,
  formatCurrencyWithSymbol,
};
