import React from "react";
import { Snackbar, SnackbarContent, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch } from "react-redux";
import { uiActions } from "../../state";
import { toastStyles } from "./Toast.styles";

export function Toast({ toast, setToast }) {
  const classes = toastStyles();
  const dispatch = useDispatch();
  const onClose = () => {
    setToast(null);
    dispatch(uiActions.resetToast());
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={Boolean(toast)}
      autoHideDuration={8000}
      onClose={(_, type) => {
        if (type === "timeout") onClose();
      }}
      className={classes.root}
    >
      <SnackbarContent
        className={classes.toast}
        message={<div>{toast}</div>}
        action={[
          <IconButton key="close" color="inherit" onClick={onClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
}
