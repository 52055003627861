import { APIClient, RETRY_ABLE_DONATION_CODE } from "../../lib";
import { productTypes, uiTypes } from "..";

const donationTypes = {
  DONATION_SUCCESS: "DONATION_SUCCESS",
  DONATION_ERROR: "DONATION_ERROR",
  DONATION_LIST_SUCCESS: "DONATION_LIST_SUCCESS",
  DONATION_LIST_CLEAR: "DONATION_LIST_CLEAR",
  MATCHING_DONATION_SYNC_AMOUNT_SET: "MATCHING_DONATION_SYNC_AMOUNT_SET",
  MATCHING_BAR_DONATION_CHANGE: "MATCHING_BAR_DONATION_CHANGE",
  DONATION_LIST_QUERY_UPDATE: "DONATION_LIST_QUERY_UPDATE",
  PAYMENT_TYPE_SET: "PAYMENT_TYPE_SET",
  SELECTED_DONATION_ITEM_DAYS_ADD: "SELECTED_DONATION_ITEM_DAYS_ADD",
  SELECTED_DONATION_ITEM_DAYS_REMOVE: "SELECTED_DONATION_ITEM_DAYS_REMOVE",
  DONATION_TOTALS_SET: "DONATION_TOTALS_SET",
  CC_ENTRY_MODE_CHANGE: "CC_ENTRY_MODE_CHANGE",
  GRAND_TOTAL_SET: "GRAND_TOTAL_SET",
  DONATION_RESET: "DONATION_RESET",
};

const donationActions = {
  submitDonation(donation, authToken) {
    const config = {};
    if (authToken) {
      config.headers = { authorization: `Bearer ${authToken}` };
    }
    return async (dispatch, _getState) => {
      dispatch({
        type: donationTypes.DONATION_ERROR,
        data: { donationError: false, disableRetry: false },
      });
      const response = await APIClient.post(`/donations`, donation, config);
      const { data, error, code } = response;
      if (error) {
        dispatch({
          type: donationTypes.DONATION_ERROR,
          data: {
            donationError: true,
            disableRetry: code !== RETRY_ABLE_DONATION_CODE,
          },
        });
        return response;
      }
      dispatch({
        type: donationTypes.DONATION_SUCCESS,
        data,
      });
      return { donationId: data.id };
    };
  },
  getDonationList(
    campaignId,
    campaign_type_id,
    profit_based,
    volunteerId,
    teamId,
    offset,
    limit,
    currTtl,
    sort,
    search,
  ) {
    return async (dispatch, _getState) => {
      dispatch({ type: uiTypes.UI_LOADING });

      let url = `/campaigns/${campaignId}/donations?campaign_type_id=${campaign_type_id}&profit_based=${profit_based}&offset=${offset}&limit=${limit}&_sort=${sort}&q=${search}`;
      if (volunteerId) url += `&volunteer_id=${volunteerId}`;
      if (teamId) url += `&team_id=${teamId}`;
      const response = await APIClient.get(url);

      const { data, errorMessage } = response;

      if (data) {
        dispatch({
          type: donationTypes.DONATION_LIST_SUCCESS,
          ...data,
          refresh: offset === 0,
        });
        const newTtl = data.total;
        // TODO: this was done quickly to fix the issue where new donations come in between `view mores`. It can use some code cleanup
        if (currTtl > 0 && newTtl !== currTtl) {
          const newLimit = offset + limit;
          let url = `/campaigns/${campaignId}/donations?campaign_type_id=${campaign_type_id}&profit_based=${profit_based}&offset=0&limit=${newLimit}&_sort=${sort}&q=${search}`;
          if (volunteerId) url += `&volunteer_id=${volunteerId}`;
          if (teamId) url += `&team_id=${teamId}`;
          const refreshRes = await APIClient.get(url);
          const { data, errorMessage } = refreshRes;
          if (data) {
            dispatch({
              type: donationTypes.DONATION_LIST_SUCCESS,
              ...data,
              refresh: true,
            });
          }
          if (errorMessage) {
            dispatch({ type: uiTypes.UI_DATA_FETCH_ERROR, errorMessage });
          }
          dispatch({ type: uiTypes.UI_LOADING_RESET });
        }
      }
      if (errorMessage) {
        dispatch({ type: uiTypes.UI_DATA_FETCH_ERROR, errorMessage });
      }
      dispatch({ type: uiTypes.UI_LOADING_RESET });
    };
  },
  clearDonationList() {
    return { type: donationTypes.DONATION_LIST_CLEAR };
  },
  setMatchingDonationSyncAmount(amount) {
    return { type: donationTypes.MATCHING_DONATION_SYNC_AMOUNT_SET, amount };
  },
  matchingBarDonationChange(amount) {
    return { type: donationTypes.MATCHING_BAR_DONATION_CHANGE, amount };
  },
  setDonationListQuery(field, value) {
    return { type: donationTypes.DONATION_LIST_QUERY_UPDATE, field, value };
  },
  setPaymentType(paymentType) {
    return { type: donationTypes.PAYMENT_TYPE_SET, paymentType };
  },
  addSelectedDonationItemDays(newDay) {
    return { type: donationTypes.SELECTED_DONATION_ITEM_DAYS_ADD, newDay };
  },
  removeSelectedDonationItemDays(id) {
    return {
      type: donationTypes.SELECTED_DONATION_ITEM_DAYS_REMOVE,
      id: `${id}`,
    };
  },
  setDonationTotals(totals) {
    return {
      type: donationTypes.DONATION_TOTALS_SET,
      totals,
    };
  },
  changeCcEntryMode(ccEntryMode) {
    return { type: donationTypes.CC_ENTRY_MODE_CHANGE, ccEntryMode };
  },
  setGrandTotal(grandTotal) {
    return { type: donationTypes.GRAND_TOTAL_SET, grandTotal };
  },
  clearDonationForm() {
    return async (dispatch, _getState) => {
      dispatch({ type: donationTypes.DONATION_RESET });
      dispatch({ type: productTypes.CART_RESET });
    };
  },
};

export { donationTypes, donationActions };
