import { APIClient } from "../../lib";

const submissionTypes = {
  SUBMISSION_OPEN_DIALOG: "SUBMISSION_OPEN_DIALOG",
  SUBMISSION_CLOSE_DIALOG: "SUBMISSION_CLOSE_DIALOG",
  SUBMISSION_SUCCESS: "SUBMISSION_SUCCESS",
  SUBMISSION_RESET: "SUBMISSION_RESET",
};

const submissionActions = {
  openSubmissionDialog(recipient) {
    return { type: submissionTypes.SUBMISSION_OPEN_DIALOG, recipient };
  },
  closeSubmissionDialog() {
    return { type: submissionTypes.SUBMISSION_CLOSE_DIALOG };
  },
  postSubmission(submission) {
    return async (dispatch, _getState) => {
      const response = await APIClient.post(`/submissions`, submission);
      const { error, errorMessage } = response;
      if (error) return { errorMessage };
      dispatch({
        type: submissionTypes.SUBMISSION_SUCCESS,
      });
    };
  },
};

export { submissionTypes, submissionActions };
