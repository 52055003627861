import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import Slider from "react-slick";
import TimeAgo from "react-timeago";
import { connect } from "react-redux";
import { APIClient } from "../../lib";

export function _EBDonationSlider({
  campaignId,
  campaign_type_id,
  showProfitProgress,
}) {
  const classes = styles();
  const [donations, setDonations] = useState([]);

  const settings = {
    accessibility: false,
    dots: false,
    centerMode: true,
    infinite: true,
    focusOnSelect: false,
    swipeToSlide: false,
    swipe: false,
    draggable: false,
    touchMove: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    variableWidth: true,
    speed: 3000,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: false,
  };

  useEffect(() => {
    const fetch = async () => {
      if (!campaignId || !campaign_type_id) return;
      let url = `/campaigns/${campaignId}/slider_donations?campaign_type_id=${campaign_type_id}`;
      if (showProfitProgress) url = `${url}&profit_based=true`;
      const res = await APIClient.get(url);
      const { error, /*errorMessage,*/ data } = res;
      if (error) {
        console.error(error);
        return;
      }
      setDonations(data);
    };

    setInterval(() => {
      fetch();
    }, 1000 * 60);
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, campaign_type_id]);

  return (
    <div className={classes.wrapper}>
      {donations.length > 0 && (
        <Slider {...settings}>
          {donations.map((d, index) => {
            const { name, amount, created_at } = d;
            return (
              <div key={index} className={classes.cardWrapper}>
                <div key={index} className={classes.card}>
                  <div>
                    <div className={classes.amount}>${amount}</div>
                    <div className={classes.name}>{name}</div>
                    <TimeAgo
                      date={new Date(created_at)}
                      className={classes.timeAgo}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      )}
    </div>
  );
}

export const EBDonationSlider = connect(state => {
  const {
    campaign: { campaign_type_id, campaignId, showProfitProgress },
  } = state;
  return { campaign_type_id, campaignId, showProfitProgress };
})(_EBDonationSlider);

const styles = makeStyles(theme => ({
  wrapper: {
    padding: "40px 0",
  },
  cardWrapper: {
    outline: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  card: {
    height: 200,
    minWidth: "252px !important",
    maxWidth: "420px !important",
    margin: "10px",
    boxShadow: "0 8px 14px 0 rgba(224,229,238,0.5)",
  },
  amount: {
    width: "100%",
    height: 98,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F5F5F5",
    color: theme.palette.secondary.main,
    fontWeight: 500,
    letterSpacing: 0.51,
    fontSize: "40px",
  },
  name: {
    fontSize: 20,
    color: theme.palette.primary.main,
    fontWeight: "bold",
    padding: "24px 16px",
    textAlign: "center",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  timeAgo: {
    color: "#AEAEAE",
    fontSize: 14,
    letterSpacing: 0.17,
    display: "flex",
    justifyContent: "center",
    paddingBottom: 24,
  },
}));
