import React, { Fragment } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { DonationForm, DonationConfirmation } from "../components";
import queryString from "query-string";
import { isProductCampaign } from "../lib";

function _DonationFormIframe(props) {
  const {
    campaign: { campaign_type_id, orgId, isActive },
    donationSuccess,
    location,
  } = props;
  const classes = styles();
  const isProduct = isProductCampaign(campaign_type_id);
  const { token, volunteerId, canSubmitPledges, orgEmail } = queryString.parse(
    location.search,
  );
  const forVolunteer = Boolean(volunteerId);

  if (!isActive && (isProduct || forVolunteer)) {
    return <div className={classes.over}>THIS CAMPAIGN IS NOT ACTIVE!</div>;
  }
  return (
    <Fragment>
      {orgId && !donationSuccess && (
        <Fragment>
          <DonationForm
            adminIframe
            forOrgIframe={!forVolunteer}
            forVolunteer={forVolunteer}
            authToken={token}
            iframeVolunteerId={volunteerId}
            iframeCanSubmitPledges={Boolean(canSubmitPledges)}
            iframeOrgEmail={orgEmail ? orgEmail : ""}
          />
        </Fragment>
      )}
      {donationSuccess && <DonationConfirmation />}
    </Fragment>
  );
}

export const DonationFormIframe = connect(state => {
  const {
    campaign,
    donation: { donationSuccess },
  } = state;
  return { campaign, donationSuccess };
})(_DonationFormIframe);

const styles = makeStyles(_theme => ({
  over: {
    marginTop: 100,
    fontSize: 36,
    textAlign: "center",
  },
}));
