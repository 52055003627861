import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { MenuItem, TextField } from "@material-ui/core";
import { donationFormStyles } from "./DonationForm.styles";
import { ccEntryModes, gatewayProviderIds, isProductCampaign } from "../../lib";
import { donationActions } from "../../state";

function _CCEntryMethod({
  campaignId,
  ccEntryMode,
  gatewayProviderId,
  forOrgIframe,
  campaign_type_id,
}) {
  const dispatch = useDispatch();
  const isProduct = isProductCampaign(campaign_type_id);
  const classes = donationFormStyles({ isProduct });
  const [isSwipeable, setIsSwipeable] = useState(false);

  useEffect(() => {
    if (
      gatewayProviderId === gatewayProviderIds.CARDKNOX ||
      gatewayProviderId === gatewayProviderIds.BANQUEST
    ) {
      setIsSwipeable(true);
    }
  }, [gatewayProviderId]);

  // temp custom code for a Givvr campaign
  useEffect(() => {
    if (campaignId === 25547) {
      dispatch(donationActions.changeCcEntryMode(ccEntryModes.SWIPE));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId]);

  const handleOptionChange = e => {
    dispatch(donationActions.changeCcEntryMode(e.target.value));
  };

  if (!forOrgIframe || !isSwipeable) return <></>;
  return (
    <Fragment>
      <div className={classes.formSubHeader}>Credit Card Entry Method</div>
      <div className={classes.inputGroup}>
        <TextField
          select
          value={ccEntryMode}
          label="Select an Option"
          onChange={handleOptionChange}
          className={classes.input}
        >
          {Object.values(ccEntryModes).map(mode => (
            <MenuItem key={mode} value={mode}>
              {mode}
            </MenuItem>
          ))}
        </TextField>
      </div>
    </Fragment>
  );
}

export const CCEntryMethod = connect(state => {
  const {
    campaign: { campaignId, gatewayProviderId, campaign_type_id },
    donation: { ccEntryMode },
  } = state;
  return { campaignId, gatewayProviderId, ccEntryMode, campaign_type_id };
})(_CCEntryMethod);
