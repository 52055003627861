import React, { useEffect, useState } from "react";
import { Collapse, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { Field, useField } from "react-final-form";
import classnames from "classnames";
import { formatCurrencyDisplay, hexToRGBA, paymentPlans } from "../../lib";

export function _PaymentSchedule(props) {
  const {
    campaign: { allowed_payment_plan, recurring_options },
    baseDonation,
    formValues: { hasInstallments, installments },
  } = props;
  const [allowRecurring, setAllowRecurring] = useState(false);
  const [allowInstallments, setAllowInstallments] = useState(false);
  const classes = styles();
  const installmentsField = useField("installments");
  const hasInstallmentsField = useField("hasInstallments");
  const recurringTotalField = useField("recurringTotal");

  useEffect(() => {
    if (
      allowed_payment_plan === paymentPlans.RECURRING ||
      allowed_payment_plan === paymentPlans.RECURRING_DEFAULTED
    ) {
      setAllowRecurring(true);
    } else if (
      allowed_payment_plan === paymentPlans.INSTALLMENTS ||
      allowed_payment_plan === paymentPlans.INSTALLMENTS_DEFAULTED
    ) {
      setAllowInstallments(true);
    }
  }, [allowed_payment_plan]);

  useEffect(() => {
    if (
      (allowRecurring || allowInstallments) &&
      (allowed_payment_plan === paymentPlans.RECURRING_DEFAULTED ||
        allowed_payment_plan === paymentPlans.INSTALLMENTS_DEFAULTED)
    ) {
      // need this timeout
      setTimeout(() => onModeChange(true), 50);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowRecurring, allowInstallments, allowed_payment_plan]);

  useEffect(() => {
    if (allowRecurring) {
      let RT;
      if (!installments || !baseDonation) RT = 0;
      else RT = Number((baseDonation * installments).toFixed(2));
      recurringTotalField.input.onChange(RT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseDonation, installments, allowRecurring]);

  const onModeChange = isMultipleMode => {
    hasInstallmentsField.input.onChange(isMultipleMode);
    if (!isMultipleMode) {
      installmentsField.input.onChange(0);
      if (allowRecurring) recurringTotalField.input.onChange(0);
    }
  };

  const onInstallmentChange = (months, input) => {
    input.onChange(months);
  };

  if (!allowInstallments && !allowRecurring) return <></>;
  return (
    <div className={classes.container}>
      <div className={classes.optionsHeader}>Payment options</div>

      <div className={classes.toggleContainer}>
        <div
          className={classnames(
            classes.toggle,
            classes.leftToggle,
            !hasInstallments && classes.activeToggle,
          )}
          onClick={() => onModeChange(false)}
        >
          {allowRecurring ? "Donate once" : "One time"}
        </div>

        <div
          className={classnames(
            classes.toggle,
            classes.rightToggle,
            hasInstallments && classes.activeToggle,
          )}
          onClick={() => onModeChange(true)}
        >
          {allowRecurring ? "Donate monthly" : "Installments"}
        </div>
      </div>

      <Collapse
        in={hasInstallments}
        collapsedSize={0}
        timeout={500}
        className={classes.collapse}
        classes={{
          wrapper: classes.collapseWrapper,
          wrapperInner: classes.collapseWrapper,
        }}
      >
        <div className={classes.optionsContainer}>
          {Array.isArray(recurring_options) &&
            recurring_options.map(option => {
              const active = installments === option;
              return (
                <Field
                  key={option}
                  name="installments"
                  component={({ input }) => (
                    <div
                      className={classnames(
                        classes.optionCard,
                        active && classes.activeOption,
                      )}
                      {...input}
                      onClick={() => onInstallmentChange(option, input)}
                    >
                      <div className={classes.optionInfoContainer}>
                        <div>{`${option} months`}</div>
                        {baseDonation > 0 && (
                          <div className={classes.line2}>
                            {allowRecurring &&
                              `$${formatCurrencyDisplay(baseDonation)}/month`}
                            {!allowRecurring &&
                              getInstallmentAmount(option, baseDonation)}
                          </div>
                        )}
                        {allowRecurring && baseDonation > 0 && (
                          <div className={classes.bottomRow}>
                            {getRecurringTotal(option, baseDonation)}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                />
              );
            })}
        </div>
      </Collapse>
    </div>
  );
}

export const PaymentSchedule = connect(state => {
  const {
    campaign,
    donation: { baseDonation },
  } = state;
  return { campaign, baseDonation };
})(_PaymentSchedule);

function getRecurringTotal(months = 0, baseDonation) {
  if (!baseDonation) return;
  const ttl = Number(months) * baseDonation;
  return `$${formatCurrencyDisplay(ttl)} total`;
}

function getInstallmentAmount(months = 0, baseDonation) {
  if (!baseDonation) return;
  const intValue = baseDonation * 100;
  const breakdown = Math.floor(intValue / months) / 100;
  return `$${formatCurrencyDisplay(breakdown)} per month`;
}

const styles = makeStyles(theme => ({
  container: {
    width: 732,
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "0 16px",
      width: 764,
    },
    [theme.breakpoints.down("xs")]: {
      width: 362,
    },
  },
  optionsHeader: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.25,
    marginTop: 56,
    marginBottom: 32,
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
      marginBottom: 24,
    },
  },
  toggleContainer: {
    marginBottom: 12,
    display: "flex",
    height: 56,
    width: 700,
    maxWidth: "100%",
    cursor: "pointer",
  },
  toggle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0,
    width: "50%",
    height: "100%",
    color: theme.palette.text.secondary,
    backgroundColor: "#FFFFFF",
    "&:hover": {
      backgroundColor: hexToRGBA(theme.palette.primary.main, 0.06),
    },
  },
  leftToggle: {
    borderRadius: "4px 0 0 4px",
    border: `1px solid ${theme.palette.text.secondary}`,
    borderRight: "none",
  },
  rightToggle: {
    borderRadius: "0 4px 4px 0",
    border: `1px solid ${theme.palette.text.secondary}`,
    borderLeft: "none",
  },
  activeToggle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  collapse: {
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    justifyContent: "center",
  },
  collapseWrapper: {
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    justifyContent: "center",
  },
  optionsContainer: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    maxWidth: "100%",
    width: 724,
    marginLeft: -12,
    marginRight: -12,
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-between",
    },
  },
  optionCard: {
    backgroundColor: "#FFFFFF",
    border: `1px solid ${theme.palette.text.secondary}`,
    color: theme.palette.text.secondary,
    minHeight: 98,
    width: 157,
    padding: "8px 2px",
    margin: "12px 12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: 4,
    cursor: "pointer",
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0,
    textAlign: "center",
    overflow: "hidden",
    "&:hover": {
      backgroundColor: hexToRGBA(theme.palette.primary.main, 0.06),
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
      margin: "8px 0",
      width: 156,
      maxWidth: "48%",
      minHeight: 52,
    },
  },
  activeOption: {
    border: `3px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
  },
  optionInfoContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  line2: {
    fontWeight: 600,
    paddingTop: 8,
  },
  bottomRow: {
    paddingTop: 8,
  },
}));
