import React, { useState, useEffect } from "react";
import { Container, InputAdornment, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import { useField } from "react-final-form";
import { formatCurrencyWithSymbol, formatMoneyInput } from "../../lib";
import { donationStyles } from "./Donation.styles";
import { PaymentSchedule } from "./PaymentSchedule";

export function _RegDonationInfo(props) {
  const {
    campaign: { allow_custom_amt, suggested_amounts = [], hasEvents },
    formValues,
  } = props;
  const classes = donationStyles();
  const [hasSuggestions, setHasSuggestions] = useState(false);

  useEffect(() => {
    if (Array.isArray(suggested_amounts) && suggested_amounts.length) {
      setHasSuggestions(true);
    }
  }, [suggested_amounts]);

  // don't show for event ticket sites with no donation
  if (hasEvents && !hasSuggestions && !allow_custom_amt) return <></>;
  return (
    <div className={classes.donationSectionWrapper}>
      <Container>
        <div className={classes.donationSectionContent}>
          <div className={classes.donationSectionHeader}>Donate now</div>

          {hasSuggestions && (
            <div className={classes.cardContainer}>
              {suggested_amounts.map(({ amount, text }, index) => {
                return <Card key={index} amount={amount} text={text} />;
              })}
              {allow_custom_amt && <CustomCard />}
            </div>
          )}

          {!hasSuggestions && <CustomCard noSuggestions />}

          <PaymentSchedule formValues={formValues} />
        </div>
      </Container>
    </div>
  );
}

export const RegDonationInfo = connect(state => {
  return { campaign: state.campaign };
})(_RegDonationInfo);

function Card({ amount, text }) {
  const amountInput = useField("amount").input;
  const customAmountInput = useField("customAmount").input;
  const activeAmount = amountInput.value;
  const isSelected = Number(amount) === Number(activeAmount);
  const classes = donationStyles({ isSelected });

  function handleClick() {
    const newVal = isSelected ? null : amount;
    amountInput.onChange(newVal);
    customAmountInput.onChange("");
  }

  return (
    <div className={classes.card} onClick={handleClick}>
      <div className={classes.amount}>{formatCurrencyWithSymbol(amount)}</div>
      {text && <div className={classes.txt}>{text}</div>}
    </div>
  );
}

function CustomCard({ noSuggestions }) {
  const customAmountInput = useField("customAmount").input;
  const customAmount = customAmountInput.value;
  const amountInput = useField("amount").input;
  const isSelected = Boolean(customAmount);
  const classes = donationStyles({ isSelected });

  const onChange = e => {
    const formattedVal = formatMoneyInput(e.target.value);
    customAmountInput.onChange(formattedVal);
    amountInput.onChange(null);
  };

  if (noSuggestions) {
    return (
      <div className={classes.bigInputWrapper}>
        <TextField
          label="Enter amount"
          className={classes.bigInput}
          value={customAmount || ""}
          onChange={onChange}
          InputProps={{
            autoComplete: "off",
            startAdornment: customAmount ? (
              <InputAdornment position="start">$</InputAdornment>
            ) : null,
          }}
          type="number"
        />
      </div>
    );
  }

  return (
    <div className={classes.card}>
      <TextField
        size="small"
        className={classes.input}
        value={customAmount || ""}
        onChange={onChange}
        InputProps={{
          autoComplete: "off",
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        type="number"
      />
      <div className={classes.txt}>Custom amount</div>
    </div>
  );
}
