import { makeStyles } from "@material-ui/core";

export const campaignStatsStyles = makeStyles(theme => ({
  wrapper: {
    width: "100%",
  },
  statsContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "40px 0",
    backgroundColor: ({ isMatch }) => (isMatch ? "#F5F5F5" : "#FFFFFF"),
    // [theme.breakpoints.down("md")]: {
    //   padding: () => 0,
    // },
    [theme.breakpoints.down("sm")]: {
      backgroundColor: () => "#FFFFFF",
      padding: "0 0 24px 0",
    },
  },
  progressContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "100%",
      minWidth: "unset",
    },
  },
  progSpacer: {
    marginTop: 48,
    [theme.breakpoints.down("sm")]: {
      marginTop: 24,
    },
  },
  linkButtonWrapper: {
    marginTop: 48,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: 32,
    },
  },
  linkButton: {
    height: 56,
    width: ({ has2Buttons }) => (has2Buttons ? 200 : 280),
    margin: ({ has2Buttons }) => (has2Buttons ? "0 12px" : 0),
    fontSize: 20,
    letterSpacing: 0,
    fontWeight: 600,
    borderRadius: 28,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      height: 40,
      width: ({ has2Buttons }) => (has2Buttons ? 150 : "calc(100% - 32px)"),
      margin: ({ has2Buttons }) => (has2Buttons ? "0 8px" : 16),
    },
  },
  endDateContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingLeft: ({ showCountdownClock }) => (!showCountdownClock ? "20px" : 0),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0 !important",
      justifyContent: "center",
    },
  },
  endsTxt: {
    color: theme.palette.secondary.main,
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.2,
    marginTop: 32,
  },
  endedTxt: {
    color: theme.palette.secondary.main,
    fontSize: 32,
    fontWeight: 600,
    letterSpacing: 0.4,
    lineHeight: "35px",
    textAlign: "center",
    padding: "0 12px",
    marginTop: ({ hide_stats_component }) => (hide_stats_component ? 40 : 16),
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
      lineHeight: "27px",
    },
  },
}));
