import React, { useEffect, useRef } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { makeStyles } from "@material-ui/core";
import { useForm } from "react-final-form";
import { useDispatch } from "react-redux";
import { PROCESSOR_ERROR_MSG } from "../../lib";
import { setToast } from "../../state";

export function Stripe({ onSubmitRef, setFetchingTokens }) {
  const elementsRef = useRef(null);
  const elements = useElements();
  const stripe = useStripe();
  const stripeRef = useRef(null);
  const classes = styles();
  const form = useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    onSubmitRef.current = onSubmit;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (elements && elementsRef.current === null) {
      elementsRef.current = elements;
    }

    if (stripe && stripeRef.current === null) {
      stripeRef.current = stripe;
    }
  }, [elements, stripe]);

  const onSubmit = async () => {
    try {
      setFetchingTokens(true);
      const { values } = form.getState();
      const card = elementsRef.current.getElement(CardElement);
      const result = await stripeRef.current.createToken(card, {
        address_zip: values.zip,
      });

      if (result.error) dispatch(setToast(result.error.message));
      else {
        form.change("ccToken", result.token.id);
        form.submit();
      }
    } catch (error) {
      dispatch(setToast(PROCESSOR_ERROR_MSG));
    }
    setTimeout(() => setFetchingTokens(false), 1000);
  };

  return (
    <CardElement
      className={classes.ccIframe}
      options={{ hidePostalCode: true }}
    />
  );
}

const styles = makeStyles(theme => ({
  ccIframe: {
    width: "100%",
    border: `1px solid ${theme.palette.text.secondary} !important`,
    borderRadius: 4,
    height: 56,
    padding: "18px 14px",
    marginBottom: 32,
    [theme.breakpoints.down("sm")]: {
      padding: "18px 4px",
    },
  },
}));
