import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import {
  Button,
  CircularProgress,
  Container,
  makeStyles,
} from "@material-ui/core";
import {
  VolunteerRegistrationForm,
  ExistingVolunteerRegistrationForm,
  VolunteerRegistrationConfirmation,
  MainBanner,
  VolunteerRegistrationUserSelector,
  VolunteerRegistrationLogin,
} from "../components";
import { volunteerRegistrationModes } from "../lib";
import { getShowRegistration, getUrls, volunteerActions } from "../state";

function _VolunteerRegistration(props) {
  const {
    campaign: {
      campaignId,
      name = "",
      first_name = "",
      last_name = "",
      volunteer_label = "",
    },
    volunteerRegistrationMode,
    location,
    homeUrl,
  } = props;
  // if there is a first_name then it's on a volunteer page
  const title = first_name ? `${name} - ${first_name} ${last_name}` : name;
  const classes = styles();
  const dispatch = useDispatch();
  const showRegistration = useSelector(getShowRegistration);
  const history = useHistory();
  const [Content, setContent] = useState(null);
  const isReactivation =
    volunteerRegistrationMode === volunteerRegistrationModes.ADD_TO_CAMPAIGN;
  const { external_id } = queryString.parse(location.search);

  useEffect(() => {
    window.document.title = title;
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  useEffect(() => {
    return () => dispatch(volunteerActions.resetVolunteerRegistrationForm());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (volunteerRegistrationMode) {
      case volunteerRegistrationModes.SELECT_USER:
        setContent(VolunteerRegistrationUserSelector);
        break;
      case volunteerRegistrationModes.ADD_TO_CAMPAIGN:
        setContent(ExistingVolunteerRegistrationForm);
        break;
      case volunteerRegistrationModes.LOGIN:
        setContent(VolunteerRegistrationLogin);
        break;
      default:
        setContent(VolunteerRegistrationForm);
    }
  }, [volunteerRegistrationMode]);

  useEffect(() => {
    if (volunteerRegistrationMode === volunteerRegistrationModes.SUCCESS) {
      window.scrollTo(0, 0);
    }
  }, [volunteerRegistrationMode]);

  if (!campaignId) {
    return (
      <Container>
        <div className={classes.spinnerWrapper}>
          <CircularProgress className={classes.spinner} size={50} />
        </div>
      </Container>
    );
  }
  if (!showRegistration) {
    return (
      <Container>
        <div className={classes.closedWrapper}>
          <div className={classes.closed}>
            Registration for this campaign is closed
          </div>
          <Button
            className={classes.home}
            variant="contained"
            color="primary"
            onClick={() => history.replace(`${homeUrl}#top`)}
          >
            Go home
          </Button>
        </div>
      </Container>
    );
  }
  if (volunteerRegistrationMode === volunteerRegistrationModes.SUCCESS) {
    return <VolunteerRegistrationConfirmation />;
  }
  return (
    <Fragment>
      <MainBanner />
      <Container>
        <div className={classes.container}>
          <div className={classes.title}>
            <h1>
              {name}
              <br />
              {volunteer_label}{" "}
              {isReactivation ? "reactivation" : "registration"}
            </h1>
          </div>

          {Content && <Content external_id={external_id} />}
        </div>
      </Container>
    </Fragment>
  );
}

export const VolunteerRegistration = connect(state => {
  const { homeUrl } = getUrls(state);
  const {
    campaign,
    volunteer: { volunteerRegistrationMode },
  } = state;
  return {
    campaign,
    volunteerRegistrationMode,
    homeUrl,
  };
})(_VolunteerRegistration);

const styles = makeStyles(theme => ({
  container: {
    padding: "0 16px 56px 16px",
  },
  spinnerWrapper: {
    width: "100%",
    height: "300px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  spinner: {
    color: "#248BEF",
  },
  closedWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 70,
    fontSize: 24,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
      padding: "70px 16px 24px 16px",
      lineHeight: "24px",
    },
  },
  closed: {
    marginBottom: 28,
  },
  home: {
    width: 146,
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 32,
    lineHeight: "35px",
    fontWeight: 600,
    letterSpacing: 0,
    textAlign: "center",
    padding: "40px 0 16px 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
      paddingTop: 32,
      lineHeight: "30px",
    },
  },
}));
