import React, { useEffect, useState, useRef } from "react";
import { makeStyles, Button } from "@material-ui/core";
import Slider from "react-slick";
import TimeAgo from "react-timeago";
import { connect } from "react-redux";
import {
  APIClient,
  isProductCampaign,
  hexToRGBA,
  isGivverCompany,
} from "../../lib";

const settings = {
  accessibility: false,
  dots: false,
  centerMode: true,
  infinite: true,
  focusOnSelect: false,
  swipeToSlide: false,
  swipe: false,
  draggable: false,
  touchMove: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  variableWidth: true,
  speed: 3000,
  autoplaySpeed: 0,
  cssEase: "linear",
  pauseOnHover: false,
};

function _DonationSlider({
  campaignId,
  companyId,
  campaign_type_id,
  showAllDonations,
  handleShowAllDonations,
  setHasRecentDonations,
  forVolunteer,
  forTeam,
  showProfitProgress,
  volunteerId,
  teamId,
}) {
  const classes = styles();
  const [donations, setDonations] = useState([]);
  const [webpageIsActiveState, setWebpageIsActiveState] = useState(true);
  const webpageIsActiveRef = useRef(true);
  const interval = useRef(null);
  const label = isProductCampaign(campaign_type_id) ? "sales" : "donations";

  useEffect(() => {
    const handler = () => {
      const isActive = document.visibilityState === "visible";
      webpageIsActiveRef.current = isActive;
      setWebpageIsActiveState(isActive);
    };

    window.addEventListener("visibilitychange", handler);
    return () => {
      window.removeEventListener("visibilitychange", handler);
    };
  }, []);

  useEffect(() => {
    const time = isGivverCompany(companyId) ? 1000 * 60 : 2000 * 60;
    interval.current = setInterval(() => {
      fetchDonations();
    }, time);

    return () => {
      clearInterval(interval.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchDonations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, campaign_type_id, webpageIsActiveState]);

  const fetchDonations = async () => {
    if (!campaignId || !campaign_type_id || !webpageIsActiveRef.current) return;
    let url = `/campaigns/${campaignId}/slider_donations?campaign_type_id=${campaign_type_id}`;
    if (showProfitProgress) url = `${url}&profit_based=true`;
    if (forVolunteer) url = `${url}&volunteer_id=${volunteerId}`;
    if (forTeam) url = `${url}&team_id=${teamId}`;
    const res = await APIClient.get(url);
    const { error, /*errorMessage,*/ data } = res;
    if (error) {
      console.error(error);
      return;
    }
    setHasRecentDonations(Boolean(data.length));
    setDonations(data);
  };

  if (!donations.length) return <></>;
  return (
    <div>
      <Slider {...settings}>
        {donations.map((d, index) => {
          const { name, amount, comments, withName, created_at } = d;
          return (
            <div key={index} className={classes.cardWrapper}>
              <div key={index} className={classes.card}>
                <div className={classes.top}>
                  <div className={classes.amount}>${amount}</div>
                  <TimeAgo
                    date={new Date(created_at)}
                    className={classes.timeAgo}
                  />
                </div>

                <div className={classes.name}>{name}</div>
                {withName && (
                  <div className={classes.withName}>{`With ${withName}`}</div>
                )}
                {comments && <div className={classes.comments}>{comments}</div>}
              </div>
            </div>
          );
        })}
      </Slider>

      <div className={classes.bottom}>
        {!showAllDonations && (
          <Button
            variant="contained"
            color="secondary"
            className={classes.btn}
            onClick={handleShowAllDonations}
          >
            {`View all ${label}`}
          </Button>
        )}
      </div>
    </div>
  );
}

export const DonationSlider = connect(state => {
  const {
    campaign: {
      companyId,
      campaign_type_id,
      campaignId,
      showProfitProgress,
      volunteerId,
      teamId,
    },
  } = state;
  return {
    companyId,
    campaign_type_id,
    campaignId,
    showProfitProgress,
    volunteerId,
    teamId,
  };
})(_DonationSlider);

const styles = makeStyles(theme => ({
  cardWrapper: {
    outline: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  card: {
    minHeight: 160,
    height: "100%",
    minWidth: "252px !important",
    maxWidth: "325px !important",
    margin: "0 10px",
    borderRadius: 8,
    background: "#FFFFFF",
    padding: 16,
  },
  top: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  amount: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    letterSpacing: 0.25,
    fontSize: 20,
  },
  timeAgo: {
    color: hexToRGBA(theme.palette.text.primary, 0.31),
    fontSize: 14,
    letterSpacing: 0.17,
  },
  name: {
    maxWidth: "100%",
    fontSize: "16px",
    lineHeight: "19px",
    fontWeight: 600,
    letterSpacing: 0,
    paddingBottom: 8,
    // textAlign: "center",
    textTransform: "capitalize",
  },
  withName: {
    color: hexToRGBA(theme.palette.text.primary, 0.31),
    maxWidth: "100%",
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0,
    paddingBottom: 8,
    lineHeight: "16px",
  },
  comments: {
    maxWidth: "100%",
    // textAlign: "center",
    fontSize: 14,
    letterSpacing: 0.17,
    lineHeight: "19px",
  },

  bottom: {
    marginTop: 32,
    display: "flex",
    justifyContent: "center",
  },
  btn: {
    width: 210,
    zIndex: 9,
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.2,
  },
}));
