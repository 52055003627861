import React, { Fragment } from "react";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import { connect } from "react-redux";
import { ProductCard } from "./ProductCard";
import { productCardStyles } from "./ProductCard.styles";

function _Products(props) {
  const { categorizedProducts, selectedProducts, activeCategory } = props;
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));
  const classes = styles();
  const cardClasses = productCardStyles();

  return (
    <div className={classes.cardsContainer}>
      {categorizedProducts[activeCategory] && (
        <Fragment>
          {categorizedProducts[activeCategory].map(product => {
            const id = product.id;
            const isSelected = selectedProducts[id];
            const quantity = isSelected ? selectedProducts[id].qty : null;
            return (
              <ProductCard
                key={id}
                isSelected={isSelected}
                product={product}
                quantity={quantity}
              />
            );
          })}
          {isMobile && categorizedProducts[activeCategory].length % 2 > 0 && (
            <div className={cardClasses.card}></div>
          )}
        </Fragment>
      )}
    </div>
  );
}

export const Products = connect(state => {
  const { selectedProducts } = state.product;
  return { selectedProducts };
})(_Products);

const styles = makeStyles(theme => ({
  cardsContainer: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    width: "calc(100% + 40px)",
    marginLeft: -40,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: 0,
      justifyContent: "center",
    },
  },
}));
