import { teamTypes } from "./actions";
const initialState = {
  teams: [],
  teamCount: 0,
};

const teamReducers = (state = initialState, action) => {
  switch (action.type) {
    case teamTypes.TEAMS_SUCCESS:
      const currList = action.refresh ? [] : state.teams;
      return {
        ...state,
        teamCount: action.count,
        teams: [...currList, ...action.teams],
      };
    case teamTypes.TEAMS_CLEAR:
      return { ...state, teams: [], teamCount: 0 };
    default:
      return state;
  }
};

export { teamReducers };
