import React, { Fragment, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import {
  RulesAndDisclaimers,
  CampaignStats,
  DonationForm,
  DonationConfirmation,
  SocialMediaBar,
  PictureAndParagraph,
  MainBanner,
  ParticipantContainer,
  Sponsors,
  UnderBannerHeader,
  Showcase,
  VolunteerPagesOnly,
} from "../components";
import { donationActions } from "../state";

function _OrgHome(props) {
  const {
    donationSuccess,
    campaign: {
      orgId,
      picture,
      text_html,
      video_link,
      volunteer_pages_only,
      informational_site_only,
    },
  } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(donationActions.clearDonationForm());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!orgId) return <></>;
  if (volunteer_pages_only) return <VolunteerPagesOnly />;
  if (donationSuccess) return <DonationConfirmation />;
  return (
    <Fragment>
      <MainBanner />
      <UnderBannerHeader />
      <CampaignStats />
      <Showcase />
      <Sponsors />
      <PictureAndParagraph
        picture={picture}
        video_link={video_link}
        text_html={text_html}
      />
      <ParticipantContainer isHomePage={true} />
      {!informational_site_only && <DonationForm />}
      <RulesAndDisclaimers />
      <SocialMediaBar />
    </Fragment>
  );
}

export const OrgHome = connect(state => {
  const {
    campaign,
    donation: { donationSuccess },
  } = state;
  return { campaign, donationSuccess };
})(_OrgHome);
