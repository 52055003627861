import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { connect } from "react-redux";
import { Container, useMediaQuery } from "@material-ui/core";
import { sponsorsStyles } from "./Sponsors.styles";
import { isProductCampaign } from "../../lib";

export function _Sponsors({ sponsors, sponsors_header }) {
  const classes = sponsorsStyles();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));
  const [data, setData] = useState([]);

  const settings = {
    accessibility: false,
    dots: false,
    centerMode: true,
    infinite: true,
    focusOnSelect: false,
    swipeToSlide: false,
    swipe: false,
    draggable: false,
    touchMove: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    variableWidth: true,
    speed: isMobile ? 3000 : 4500,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: false,
  };

  useEffect(() => {
    if (Array.isArray(sponsors) && sponsors.length) {
      if (sponsors.length < 4) {
        setData([...sponsors, ...sponsors, ...sponsors, ...sponsors]);
      } else {
        setData(sponsors);
      }
    }
  }, [sponsors]);

  if (!data.length) return <></>;
  return (
    <Container>
      <div className={classes.wrapper}>
        <div className={classes.header}>
          {sponsors_header ? sponsors_header : "Sponsors"}
        </div>
        <Slider {...settings}>
          {data.map((info, i) => (
            <Card info={info} key={i} />
          ))}
        </Slider>
      </div>
    </Container>
  );
}

export const Sponsors = connect(state => {
  const {
    campaign: { sponsors, sponsors_header, campaign_type_id },
  } = state;
  return {
    sponsors,
    sponsors_header,
    isProduct: isProductCampaign(campaign_type_id),
  };
})(_Sponsors);

function Card({ info }) {
  const { name, message, logoUrl, logo_link } = info;
  const classes = sponsorsStyles({ isImgCard: Boolean(logoUrl) });

  return (
    <div className={classes.cardWrapper}>
      <div className={classes.card}>
        {logoUrl && logo_link && (
          <a
            href={logo_link}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.logoWrapper}
          >
            <img src={logoUrl} alt="sponsor-logo" className={classes.logo} />
          </a>
        )}
        {logoUrl && !logo_link && (
          <div className={classes.logoWrapper}>
            <img src={logoUrl} alt="sponsor-logo" className={classes.logo} />
          </div>
        )}
        {!logoUrl && (
          <div>
            <div className={classes.name}>{name}</div>
            {message && <div className={classes.message}>{message}</div>}
          </div>
        )}
      </div>
    </div>
  );
}
