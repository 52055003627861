import React from "react";
import { connect } from "react-redux";
import CountdownComp from "react-countdown-now";
import { makeStyles } from "@material-ui/core";
import classnames from "classnames";

export function _Countdown({ endDate }) {
  const classes = styles();

  if (!endDate) return <></>;
  return (
    <CountdownComp
      date={new Date(endDate)}
      intervalDelay={1000}
      renderer={({ days, hours, minutes, seconds }) => {
        return (
          <div className={classes.countdown}>
            <div className={classes.timeRemaining}>Time remaining</div>

            <div className={classes.timesContainer}>
              <div className={classes.timesSection}>
                <div className={classes.number}>{days}</div>
                <div className={classes.name}>days</div>
              </div>

              <div className={classes.timesSection}>
                <div className={classes.number}>{hours}</div>
                <div className={classes.name}>hours</div>
              </div>

              <div className={classes.timesSection}>
                <div className={classes.number}>{minutes}</div>
                <div className={classes.name}>minutes</div>
              </div>

              <div className={classes.timesSection}>
                <div className={classnames(classes.number, classes.seconds)}>
                  {seconds}
                </div>
                <div className={classes.name}>seconds</div>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
}

export const Countdown = connect(state => {
  return { endDate: state.campaign.end_date };
})(_Countdown);

const styles = makeStyles(theme => ({
  countdown: {
    display: "flex",
    alignItems: "center",
    marginTop: 40,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  timeRemaining: {
    color: theme.palette.secondary.main,
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.25,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      letterSpacing: 0.2,
    },
  },
  timesContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      marginTop: 16,
    },
  },
  timesSection: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      height: 50,
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "0 16px",
    },
  },
  number: {
    color: theme.palette.primary.main,
    marginLeft: 36,
    marginRight: 8,
    fontSize: 32,
    fontWeight: "bold",
    letterSpacing: 0,
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      fontSize: 24,
    },
  },
  seconds: {
    minWidth: 44,
    [theme.breakpoints.down("sm")]: {
      minWidth: 0,
    },
  },
  name: {
    color: theme.palette.secondary.main,
    fontSize: 14,
    letterSpacing: 0.17,
  },
}));
