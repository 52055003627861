import { makeStyles } from "@material-ui/core";

export const participantContainerStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.background.light,
    width: "100%",
    minHeight: "200px",
    paddingBottom: "36px",
    paddingTop: "40px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "24px",
    },
  },
  tabs: {
    paddingBottom: "48px",
    fontWeight: 600,
  },
  tab: {
    fontWeight: 600,
    fontSize: 20,
    letterSpacing: 0.25,
    textTransform: "capitalize !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  singleTab: {
    paddingBottom: "20px",
    textAlign: "center",
    fontWeight: 600,
    fontSize: 32,
    letterSpacing: 0.4,
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
      letterSpacing: 0.3,
    },
  },
  sortAndFilter: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
    [theme.breakpoints.down("md")]: {
      padding: "0 16px",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  searchAndRefresh: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      marginBottom: 12,
      width: "100%",
    },
  },
  refresh: {
    color: theme.palette.text.primary,
  },
  rightActions: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-between",
      width: "100%",
    },
  },
  sortWrapper: {
    display: "flex",
    alignItems: "center",
  },
  sortBy: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.25,
    marginRight: 24,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      marginRight: 10,
    },
  },
  sort: {
    minWidth: 190,
    borderColor: "red",
  },
  search: {
    marginRight: 18,
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
      width: "100%",
    },
  },
  cardContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.only("md")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      padding: "0 16px",
    },
  },
  card: {
    cursor: "pointer",
    width: 364,
    minHeight: 130,
    backgroundColor: "#ffffff",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginBottom: "24px",
    padding: 16,
    [theme.breakpoints.only("md")]: {
      marginLeft: 8,
      marginRight: 8,
    },
    [theme.breakpoints.only("sm")]: {
      marginLeft: 8,
      marginRight: 8,
    },
    [theme.breakpoints.only("xs")]: {
      maxWidth: "100%",
      width: "100%",
      marginBottom: "16px",
    },
  },
  placeholderCard: {
    width: 364,
    [theme.breakpoints.only("md")]: {
      marginLeft: 8,
      marginRight: 8,
    },
  },
  seeMore: {
    display: "flex",
    justifyContent: "center",
    marginTop: 18,
    padding: "0 16px",
  },
  seeMoreBtn: {
    zIndex: 9,
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.2,
    minWidth: 160,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  emptyContainer: {
    height: "350px",
    [theme.breakpoints.down("sm")]: {
      height: "250px",
    },
  },
  loader: {
    color: theme.palette.primary.contrastText,
  },
  emptyState: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
  },
  emptyStateTxt: {
    paddingTop: "12px",
    fontSize: "16px",
  },
}));
