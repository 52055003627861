import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import { useForm } from "react-final-form";
import { PROCESSOR_ERROR_MSG } from "../../lib";
import { setToast } from "../../state";

function _UsaEpay({ onSubmitRef, setFetchingTokens, gatewayPublicKey }) {
  const usaEpayClient = useRef(null);
  const interval = useRef(null);
  const usaEpayPaymentCard = useRef({});
  const classes = styles();
  const form = useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    onSubmitRef.current = onSubmit;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (gatewayPublicKey) {
      interval.current = setInterval(() => {
        if (window.usaepay && typeof window.usaepay.Client === "function") {
          usaEpayClient.current = new window.usaepay.Client(gatewayPublicKey);
          const paymentCard = usaEpayClient.current.createPaymentCardEntry();
          paymentCard.generateHTML({ base: { fontSize: "16px" } });
          paymentCard.addHTML("paymentCardContainer");
          usaEpayPaymentCard.current = paymentCard;
          clearInterval(interval.current);
        }
      }, 200);
    }

    return () => clearInterval(interval.current);
  }, [gatewayPublicKey]);

  const onSubmit = async () => {
    if (!usaEpayClient.current) {
      return dispatch(setToast(PROCESSOR_ERROR_MSG));
    }
    try {
      setFetchingTokens(true);
      const result = await usaEpayClient.current.getPaymentKey(
        usaEpayPaymentCard.current,
      );

      if (result.error) dispatch(setToast(result.error.message));
      else {
        form.change("ccToken", result);
        form.submit();
      }
    } catch (error) {
      let msg = PROCESSOR_ERROR_MSG;
      if (typeof error === "string") msg = error;
      dispatch(setToast(msg));
    }
    setTimeout(() => setFetchingTokens(false), 1000);
  };

  return <div className={classes.ccIframe} id="paymentCardContainer" />;
}

export const UsaEpay = connect(state => {
  return { gatewayPublicKey: state.campaign.gatewayPublicKey };
})(_UsaEpay);

const styles = makeStyles(theme => ({
  ccIframe: {
    width: "100%",
    border: `1px solid ${theme.palette.text.secondary} !important`,
    height: 56,
    padding: "18px 14px",
    borderRadius: 4,
    marginBottom: 32,
    [theme.breakpoints.down("sm")]: {
      padding: "18px 0",
    },
  },
}));
