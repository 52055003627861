import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import { APIClient } from "../../lib";
import { Progress } from "..";
import { campaignTypes } from "../../state";

export function _EBProgress({
  campaignId,
  campaign_type_id,
  showProfitProgress,
}) {
  const classes = styles();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetch = async () => {
      if (!campaignId || !campaign_type_id) return;
      let url = `/campaigns/${campaignId}/progress?campaign_type_id=${campaign_type_id}`;
      if (showProfitProgress) url = `${url}&profit_based=true`;
      const res = await APIClient.get(url);
      const { error, /*errorMessage,*/ data } = res;
      if (error) {
        console.error(error);
        return;
      }
      dispatch({ type: campaignTypes.CAMPAIGN_PROGRESS_FETCH_SUCCESS, data });
    };
    setInterval(() => {
      fetch();
    }, 1000 * 60);
    // fetch(); this is fetched already in the layout so we don't have to initially do it here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, campaign_type_id]);

  return (
    <div className={classes.container}>
      <div className={classes.progressWrapper}>
        <Progress />
      </div>
    </div>
  );
}

export const EBProgress = connect(state => {
  const {
    campaign: { campaign_type_id, campaignId, showProfitProgress },
  } = state;
  return { campaign_type_id, campaignId, showProfitProgress };
})(_EBProgress);

const styles = makeStyles(_theme => ({
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  progressWrapper: {
    width: "65%",
    maxWidth: "100%",
  },
}));
