import { makeStyles } from "@material-ui/core";
import { hexToRGBA } from "../../lib";

export const donationStyles = makeStyles(theme => ({
  donationSectionWrapper: {
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "#FFFFFF",
    padding: "48px 0",
    [theme.breakpoints.down("sm")]: {
      padding: "24px 0",
    },
  },
  donationSectionContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  donationSectionHeader: {
    fontSize: 32,
    fontWeight: 600,
    letterSpacing: 0.4,
    textAlign: "center",
    paddingBottom: 16,
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
      letterSpacing: 0.3,
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  cardContainer: {
    paddingTop: 28,
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    width: "calc(100% + 24px)",
    marginLeft: 12,
    marginRight: 12,
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginRight: 0,
      width: "100%",
      padding: "24px 8px 0 8px",
    },
  },
  card: {
    width: 170,
    // minHeight: 165,
    padding: "24px 11px",
    borderRadius: 8,
    marginLeft: 12,
    marginRight: 12,
    marginBottom: 24,
    backgroundColor: ({ isSelected }) =>
      isSelected ? hexToRGBA(theme.palette.primary.main, 0.09) : "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    alignItems: "center",
    border: ({ isSelected }) =>
      isSelected
        ? `2px solid ${theme.palette.primary.main}`
        : `1px solid ${theme.palette.text.secondary}`,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: ({ isSelected }) =>
        hexToRGBA(theme.palette.primary.main, isSelected ? 0.09 : 0.06),
    },
    [theme.breakpoints.down("md")]: {
      width: 155,
      minHeight: 155,
      marginLeft: 8,
      marginRight: 8,
      padding: "22px 11px",
      marginBottom: 16,
    },
  },
  amount: {
    color: theme.palette.secondary.main,
    fontSize: 32,
    fontWeight: 600,
    letterSpacing: 0.4,
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
      letterSpacing: 0.3,
    },
  },
  txt: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "16px",
    textAlign: "center",
    marginTop: 14,
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
      lineHeight: "14px",
    },
  },
  input: {
    width: 100,
    [`& fieldset`]: {
      borderRadius: 19,
    },
  },
  bigInputWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 24,
  },
  bigInput: {
    width: 280,
  },
  tickets: {
    marginTop: 8,
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0,
  },
  ticketLimit: {
    marginBottom: 16,
    fontSize: 16,
    letterSpacing: 0,
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      padding: "0 16px",
    },
  },
}));
