import {
  CircularProgress,
  Dialog,
  IconButton,
  makeStyles,
  SwipeableDrawer,
  useMediaQuery,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { APIClient } from "../../lib";
import { setToast } from "../../state";

export function PreviewDiscountCard({ close, id }) {
  const classes = styles();
  const dispatch = useDispatch();
  const [iframeSrc, setIframeSrc] = useState("");

  useEffect(() => {
    const fetch = async () => {
      const url = `/discount_cards/${id}/get_preview_token`;
      const response = await APIClient.get(url);
      const { error, errorMessage, data } = response;
      if (error) {
        dispatch(setToast(errorMessage));
        return;
      }
      const src = `${process.env.REACT_APP_DISCOUNT_APP_URL}/admin/card-offers/${id}?jwt=${data.token}`;
      setIframeSrc(src);
    };
    if (id) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <ResponsiveModal onClose={close}>
      <div className={classes.container}>
        <div className={classes.top}>
          <div>Discount card offers</div>
          <div>
            <IconButton onClick={close} className={classes.close}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>

        <div className={classes.iframeWrapper}>
          {!iframeSrc && (
            <div className={classes.loadingWrapper}>
              <CircularProgress size={48} />
            </div>
          )}

          {iframeSrc && (
            <iframe
              className={classes.iframe}
              title="Discount app"
              src={iframeSrc}
            />
          )}
        </div>
      </div>
    </ResponsiveModal>
  );
}

export function ResponsiveModal({ children, onClose }) {
  const classes = styles();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));

  if (isMobile) {
    return (
      <SwipeableDrawer
        open={true}
        onClose={onClose}
        onOpen={() => {}}
        classes={{ paper: classes.drawer }}
        transitionDuration={500}
        anchor="bottom"
      >
        <div className={classes.drawerWrapper}>{children}</div>
      </SwipeableDrawer>
    );
  } else {
    return (
      <Dialog
        open={true}
        onClose={onClose}
        classes={{ paper: classes.dialogPaper }}
      >
        {children}
      </Dialog>
    );
  }
}

const styles = makeStyles(theme => ({
  drawer: {
    top: 75,
    borderRadius: "20px 20px 0 0",
    maxHeight: "unset",
  },
  drawerWrapper: {
    width: "100vw",
    maxWidth: "100vw",
    minWidth: "100vw",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflow: "scroll",
  },
  dialogPaper: {
    height: "calc(100% - 64px)",
  },
  container: {
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  top: {
    height: 80,
    minHeight: 80,
    position: "sticky",
    top: 0,
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: "0 32px",
    backgroundColor: "#FFFFFF",
    zIndex: 999,
    [theme.breakpoints.down("xs")]: {
      padding: "0 24px",
    },
  },
  close: {
    marginTop: -12,
    marginRight: -12,
    [theme.breakpoints.down("xs")]: {
      marginRight: -2,
    },
  },
  iframeWrapper: {
    flex: 1,
    padding: "0 32px 32px 32px",
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  iframe: {
    border: "none",
    padding: 0,
    margin: 0,
    height: "100%",
    minHeight: "100%",
    width: 400,
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
      width: "100vw",
    },
  },
  loadingWrapper: {
    width: 400,
    paddingTop: 50,
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
      width: "100vw",
    },
  },
}));
