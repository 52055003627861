import React from "react";
import { Button } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { submissionActions } from "../../state";
import { submissionSuccessStyles } from "./SubmissionSuccess.styles";

function _SubmissionSuccess({
  orgName,
  company_name,
  recipient,
  actions: { closeSubmissionDialog },
}) {
  const classes = submissionSuccessStyles();
  const forCompany = recipient === "company";

  return (
    <div className={classes.container}>
      <CheckIcon className={classes.checkIcon} />
      <div className={classes.message}>
        Your message has been sent successfully to&nbsp;
        <span className={classes.name}>
          {forCompany ? company_name : orgName}
        </span>
      </div>
      <Button className={classes.button} onClick={closeSubmissionDialog}>
        OK
      </Button>
    </div>
  );
}

export const SubmissionSuccess = connect(
  state => {
    const {
      campaign: { name: orgName, company_name },
      submission: { recipient },
    } = state;
    return { orgName, company_name, recipient };
  },
  dispatch => {
    return { actions: bindActionCreators(submissionActions, dispatch) };
  },
)(_SubmissionSuccess);
