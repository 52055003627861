import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import { theme } from "../../styles/theme";
import { connect } from "react-redux";
import { createTheme } from "@material-ui/core";

function _CustomThemeProvider(props) {
  const {
    primary_color,
    primary_text,
    secondary_color,
    secondary_text,
    children,
  } = props;
  if (primary_color) {
    theme.palette.primary.main = primary_color;
  }
  if (primary_text) {
    theme.palette.primary.contrastText = primary_text;
  }
  if (secondary_color) {
    theme.palette.secondary.main = secondary_color;
  }
  if (secondary_text) {
    theme.palette.secondary.contrastText = secondary_text;
  }

  return <ThemeProvider theme={createTheme(theme)}>{children}</ThemeProvider>;
}

function mapStateToProps(state) {
  const {
    primary_color,
    primary_text,
    secondary_color,
    secondary_text,
  } = state.campaign;
  return { primary_color, primary_text, secondary_color, secondary_text };
}

export const CustomThemeProvider = connect(mapStateToProps)(
  _CustomThemeProvider,
);
