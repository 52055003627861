import React, { useState, useEffect } from "react";
import { Button, useMediaQuery } from "@material-ui/core";
import { Field, Form } from "react-final-form";
import { TextField } from "final-form-material-ui";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { submissionActions, setToast } from "../../state";
import classnames from "classnames";
import { required, email, composeValidators } from "../../lib/validators";
import { submissionFormStyles } from "./SubmissionForm.styles";

function _SubmissionForm(props) {
  const {
    recipient,
    actions: { closeSubmissionDialog, postSubmission },
    orgName = "",
    campaign_id,
    company_name = "",
    org_logo,
    company_logo,
  } = props;
  const classes = submissionFormStyles();
  const dispatch = useDispatch();
  const [forCompany, setForCompany] = useState(false);
  const [title, setTitle] = useState("");
  const [src, setSrc] = useState("");
  const [alt, setAlt] = useState("");
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));

  useEffect(() => {
    switch (recipient) {
      case "company":
        setForCompany(true);
        setTitle(`Contact ${company_name}`);
        setSrc(company_logo);
        setAlt("Company logo");
        break;
      case "org":
        setForCompany(false);
        setTitle(`Contact ${orgName}`);
        setSrc(org_logo);
        setAlt("Org logo");
        break;
      default:
        setForCompany(false);
        setTitle("");
        setSrc("");
        setAlt("");
    }
  }, [company_logo, company_name, orgName, org_logo, recipient]);

  const onSubmit = async values => {
    const postObj = { ...values, campaign_id };
    if (forCompany) postObj.for_company = true;
    const res = await postSubmission(postObj);
    if (res && res.errorMessage) {
      dispatch(setToast(res.errorMessage));
      return { submitFailed: true };
    }
  };

  return (
    <div className={classes.container}>
      {!isMobile && (
        <div className={classes.top}>
          {src && <img className={classes.logo} src={src} alt={alt} />}
          <div className={classes.title}>{title}</div>
        </div>
      )}

      {isMobile && src && <img className={classes.logo} src={src} alt={alt} />}
      {isMobile && <div className={classes.title}>{title}</div>}

      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, submitSucceeded }) => {
          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              <div className={classes.inputGroup}>
                <Field
                  name="name"
                  label="Your name"
                  component={TextField}
                  validate={required}
                  className={classes.halfInput}
                  type="text"
                />

                <Field
                  name="email"
                  label="Email"
                  component={TextField}
                  validate={composeValidators(required, email)}
                  className={classes.halfInput}
                  type="text"
                />
              </div>
              <Field
                name="message"
                multiline={true}
                minRows={4}
                label="Message"
                validate={required}
                className={classes.msg}
                component={TextField}
                type="textarea"
              />

              <div className={classes.buttonsContainer}>
                <Button
                  color="default"
                  variant="contained"
                  className={classnames(classes.button, classes.cancelButton)}
                  onClick={closeSubmissionDialog}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={submitting || submitSucceeded}
                  className={classes.button}
                >
                  Send
                </Button>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
}

export const SubmissionForm = connect(
  state => {
    const {
      campaign: {
        name: orgName,
        campaignId,
        company_name,
        logo: org_logo,
        company_logo,
      },
      submission: { recipient },
    } = state;
    return {
      orgName,
      campaign_id: campaignId,
      company_name,
      org_logo,
      company_logo,
      recipient,
    };
  },
  dispatch => {
    return { actions: bindActionCreators(submissionActions, dispatch) };
  },
)(_SubmissionForm);
