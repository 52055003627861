import React, { useState, useEffect, Fragment } from "react";
import { makeStyles, Container } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useField } from "react-final-form";
import { APIClient, EVENT_TICKET_EL_ID, nonEmptyArray } from "../../lib";
import { uiTypes } from "../../state";
import { Events } from "./Events.jsx";

export function EventTickets() {
  const { hasEvents, campaignId } = useSelector(state => state.campaign);
  const classes = styles();
  const dispatch = useDispatch();
  const [events, setEvents] = useState(null);
  const ticketsField = useField("eventTickets").input;

  useEffect(() => {
    if (campaignId && hasEvents) {
      fetchEvents();
      ticketsField.onChange({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, hasEvents]);

  const fetchEvents = async () => {
    const url = `/campaign_events/webpage_data?campaign_id=${campaignId}`;
    const response = await APIClient.get(url);
    const { error, errorMessage, data } = response;
    if (error) {
      return dispatch({ type: uiTypes.UI_DATA_FETCH_ERROR, errorMessage });
    }
    if (nonEmptyArray(data)) setEvents(data);
  };

  if (!hasEvents) return <></>;
  return (
    <Fragment>
      <div className={classes.hashLink} id={EVENT_TICKET_EL_ID}></div>
      <div className={classes.wrapper}>
        <Container>
          <div className={classes.header}>Select tickets</div>
          {nonEmptyArray(events) && <Events events={events} />}
        </Container>
      </div>
    </Fragment>
  );
}

const styles = makeStyles(theme => ({
  hashLink: {
    height: 1,
    visibility: "hidden",
    position: "absolute",
    marginTop: -80,
    [theme.breakpoints.down("sm")]: {
      marginTop: -56,
    },
  },
  wrapper: {
    padding: "40px 0",
  },
  header: {
    fontSize: 32,
    fontWeight: 600,
    textAlign: "center",
    marginBottom: 40,
  },
}));
