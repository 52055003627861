import React, { Fragment } from "react";
import { socialMediaStyles } from "./SocialMedia.styles";
import queryString from "query-string";
import { Facebook, Twitter, Linkedin, Whatsapp, IMessage, Email } from "..";
import { withRouter } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";

function _SocialMediaBar({ location }) {
  const classes = socialMediaStyles();
  const { iframe } = queryString.parse(location.search);
  const isIframe = iframe === "true";
  const smallDevice = useMediaQuery(theme => theme.breakpoints.down("sm"));

  return (
    <Fragment>
      {!smallDevice && !isIframe && (
        <div className={classes.container}>
          <Facebook /> <Twitter /> <Linkedin /> <Whatsapp /> <Email />{" "}
          <IMessage />
        </div>
      )}
    </Fragment>
  );
}

export const SocialMediaBar = withRouter(_SocialMediaBar);
