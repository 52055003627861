import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { makeStyles, Container, useMediaQuery } from "@material-ui/core";
import { ProductDeliveryOptions } from "./ProductDeliveryOptions";
import { CategorySelector } from "./CategorySelector";
import { Products } from "./Products";
import { PRODUCTS_EL_ID } from "../../lib";
import { OrderChangeListener } from "./OrderChangeListener";
import classNames from "classnames";
const MAIN_CATEGORIES_EL_ID = "main-categories";
const DC_CATEGORIES_EL_ID = "dc-categories";

function _ProductsContainer(props) {
  const {
    formValues,
    adminIframe,
    categories,
    digitalAddonCategories,
    categorizedProducts,
    digitalAddonCategorizedProducts,
    use_digital_content,
  } = props;
  const classes = styles({ adminIframe });
  const isWideScreen = useMediaQuery(theme => theme.breakpoints.up("lg"));
  const [activeCategory, setActiveCategory] = useState(null);
  const [activeDCCategory, setActiveDCCategory] = useState(null);
  const [twoColLayout, setTwoColLayout] = useState(null);
  const [hasDigital, setHasDigital] = useState(false);

  useEffect(() => {
    if (categories.length) {
      setActiveCategory(categories[0].id);
    }
  }, [categories]);

  useEffect(() => {
    setTwoColLayout(
      isWideScreen && (categories.length > 1 || digitalAddonCategories > 1),
    );
  }, [isWideScreen, categories, digitalAddonCategories]);

  useEffect(() => {
    if (use_digital_content && digitalAddonCategories.length) {
      setHasDigital(true);
      setActiveDCCategory(digitalAddonCategories[0].id);
    }
  }, [use_digital_content, digitalAddonCategories]);

  return (
    <Fragment>
      <div className={classes.hashLink} id={PRODUCTS_EL_ID}></div>

      <Container>
        <div
          className={classNames(
            classes.container,
            twoColLayout && classes.container2Col,
          )}
        >
          {twoColLayout === true && (
            <CategorySelector
              setActiveCategory={setActiveCategory}
              twoColLayout={twoColLayout}
              categories={categories}
              elId={MAIN_CATEGORIES_EL_ID}
              adminIframe={adminIframe}
            />
          )}
          <div className={classes.mainContent}>
            <ProductDeliveryOptions formValues={formValues} />
            {twoColLayout === false && (
              <CategorySelector
                setActiveCategory={setActiveCategory}
                twoColLayout={twoColLayout}
                categories={categories}
                elId={MAIN_CATEGORIES_EL_ID}
                adminIframe={adminIframe}
              />
            )}
            <Products
              activeCategory={activeCategory}
              categorizedProducts={categorizedProducts}
            />
          </div>
        </div>

        {/* DIGITAL CONTENT */}
        {hasDigital && (
          <div
            className={classNames(
              classes.container,
              twoColLayout && classes.container2Col,
            )}
          >
            {twoColLayout && (
              <CategorySelector
                setActiveCategory={setActiveDCCategory}
                twoColLayout={twoColLayout}
                categories={digitalAddonCategories}
                elId={DC_CATEGORIES_EL_ID}
                adminIframe={adminIframe}
              />
            )}
            <div className={classes.mainContent}>
              <div className={classes.dcHeader}>Digital products</div>
              <div className={classes.dcSubHeader}>
                No shipping! No waiting! Codes delivered in email receipt
              </div>
              {!twoColLayout && (
                <CategorySelector
                  setActiveCategory={setActiveDCCategory}
                  twoColLayout={twoColLayout}
                  categories={digitalAddonCategories}
                  elId={DC_CATEGORIES_EL_ID}
                  adminIframe={adminIframe}
                />
              )}
              <Products
                activeCategory={activeDCCategory}
                categorizedProducts={digitalAddonCategorizedProducts}
              />
            </div>
          </div>
        )}
      </Container>
      <OrderChangeListener />
    </Fragment>
  );
}

export const ProductsContainer = connect(state => {
  const {
    product: {
      categories,
      digitalAddonCategories,
      categorizedProducts,
      digitalAddonCategorizedProducts,
    },
    campaign: { use_digital_content },
  } = state;
  return {
    categories,
    digitalAddonCategories,
    categorizedProducts,
    digitalAddonCategorizedProducts,
    use_digital_content,
  };
})(_ProductsContainer);

const styles = makeStyles(theme => ({
  hashLink: {
    height: 1,
    visibility: "hidden",
    position: "absolute",
    marginTop: -80,
    [theme.breakpoints.down("sm")]: {
      marginTop: -56,
    },
  },
  container: {
    position: "relative", // keep this for navigation
    width: "100%",
    maxWidth: "100%",
    minHeight: "200px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 48,
  },
  container2Col: {
    flexDirection: "row",
    alignItems: "unset",
  },
  mainContent: {
    width: 860,
    maxWidth: "100%",
  },
  dcHeader: {
    fontSize: 32,
    fontWeight: 600,
    letterSpacing: 0.4,
    marginBottom: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
      letterSpacing: 0.3,
      marginBottom: 8,
      textAlign: "center",
      padding: "0 24px",
    },
  },
  dcSubHeader: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.25,
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      letterSpacing: 0.2,
      lineHeight: "20px",
      marginBottom: 16,
      textAlign: "center",
      padding: "0 24px",
    },
  },
}));
