import React, { useEffect } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import HomeIcon from "@material-ui/icons/Home";
import queryString from "query-string";
import { Link } from "react-router-dom";
import { Button, Container, makeStyles } from "@material-ui/core";

export function Privacy({ location }) {
  const classes = styles();
  const { redirect } = queryString.parse(location.search);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Container>
      <div className={classes.container}>
        <Button
          size="small"
          className={classes.button}
          component={Link}
          to={redirect ? redirect : "/"}
          startIcon={
            redirect ? (
              <ArrowBackIcon className={classes.icon} />
            ) : (
              <HomeIcon className={classes.icon} />
            )
          }
        >
          {redirect ? "Back" : "Home"}
        </Button>
        <h1 className={classes.title}>Privacy Notice</h1>
        <p className={classes.p}>
          This privacy notice discloses the privacy practices for&nbsp;
          <a
            href="http://instaraise.com/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <span className={classes.link}>instaraise.com</span>
          </a>{" "}
          <span className={classes.bold}>
            and any other website using InstaRaise platform including this
            website.
          </span>
        </p>
        <p className={classes.p}>
          This privacy notice applies solely to information collected by these
          websites. It will notify you of the following:
        </p>
        <h2 className={classes.subheader}>Security</h2>
        <p className={classes.p}>
          We take precautions to protect your information. When you submit
          sensitive information via the website, your information and used only
          for the purpose intended. Wherever we collect sensitive information
          (such as credit card data), that information is encrypted and
          transmitted to us in a secure way. You can verify this by looking for
          a lock icon in the address bar and looking for "https" at the
          beginning of the address of the Web page. Your credit card information
          is only used for intended one-time transaction and in no way saved or
          stored in our internal server. No member of our company or tech
          partners have access to credit card information after transaction is
          completed.
        </p>
        <div className={classes.spacer} />
        <p className={classes.p}>
          While we use encryption to protect sensitive information transmitted
          online, we also protect your contact and personal (non-financial)
          information offline. As part of our promotion management services, we
          do receive information collected by promotion sponsors or other
          parties contracted to collect such information and use it only to
          perform our contracted business services, such as performing drawings
          and other administrative tasks related to our business. That
          information typically includes name and address, and may include
          telephone number, email address and/or other data. We only use that
          information to perform our administrative services, or in response to
          a bona fide government or legal request. A client's data at all times
          remains the property of the sponsor(s), is used by us only for the
          promotion for which it was provided.
        </p>
        <div className={classes.spacer} />
        <p className={classes.p}>
          This website is open to registrants from the age of 13 and up.
          Submission of registration is confirmation of the registrant's
          compliance with this age policy. Personal information of children ages
          12 and under may only be provided by the child's parent or guardian.
        </p>

        <h2 className={classes.subheader} id="data-deletion">
          Data Deletion
        </h2>
        <p className={classes.p}>
          To request the deletion of your personal information please contact us
          at{" "}
          <a href="mailto:info@instaraise.com?subject=Data%20Deletion%20Request&body=Hi%2C%0A%0APlease%20remove%20my%20personally%20identifying%20information%20from%20InstaRaise.%0A%0AMy%20name%3A%0A%0AMy%20InstaRaise%20student%2Fparticipant%20ID%3A%0A%0AI%20am%20registered%20with%3A%20_name_of_organization%2Fgroup_%0A%0AMy%20InstaRaise%20user%20email%20address%3A%0A%0A">
            <span className={classes.link}>info@instaraise.com</span>
          </a>
          .
        </p>

        <h2 className={classes.subheader}>Transactions</h2>
        <p className={classes.p}>
          We request information from you on our checkout form. To checkout, you
          must provide contact information and financial information (like
          credit card number, expiration date). The credit card information is
          used to complete that transaction and is not saved for any further
          purpose. Your non-financial personal information will only be
          accessible by your intended sponsor and is solely used by them. No
          names or personal information are ever added to lists or sold or
          rented to others for any purpose.
        </p>

        <h2 className={classes.subheader}>Cookies</h2>
        <p className={classes.p}>
          We may use "cookies" on this site. A cookie is a piece of data stored
          on a site visitor's hard drive to help us improve your access to our
          site and identify repeat visitors to our site. For instance, when we
          use a cookie to identify you, you would not have to log in a password
          more than once, thereby saving time while on our site. Cookies can
          also enable us to track and target the interests of our users to
          enhance the experience on our site. Usage of a cookie is in no way
          linked to any personally identifiable information on our site.
        </p>

        <h2 className={classes.subheader}>Sharing</h2>
        <p className={classes.p}>
          We may share aggregated demographic information with our partners.
          This is not linked to any personal information that can identify any
          individual person. We use an outside shipping company to ship orders,
          and a credit card processing company to bill users for goods and
          services. These companies do not retain, share, store or use
          personally identifiable information for any secondary purposes beyond
          filling your order.
        </p>
        <div className={classes.spacer} />
        <p className={classes.p}>
          No mobile information will be shared with third parties/affiliates for
          marketing/promotional purposes. All other categories exclude text
          messaging originator opt-in data and consent; this information will
          not be shared with any third parties.
        </p>
        <div className={classes.spacer} />
        <p className={classes.p}>
          In our role as the promotion management for multiple sponsoring
          organizations, we employ hardware and software solutions, policies,
          procedures that act to greatly minimize&nbsp;the risk of accidental or
          intentional data breaches.
        </p>

        <p className={classes.bottomTxt}>
          If you feel that we are not abiding by this privacy policy, you should
          contact us immediately via telephone at&nbsp;
          <a href="tel:516-620-2855">
            <span className={classes.link}>516-620-2855</span>
          </a>
          &nbsp;or&nbsp;
          <a href="mailto:info@instaraise.com">
            <span className={classes.link}>info@instaraise.com</span>
          </a>
          .
        </p>
      </div>
    </Container>
  );
}

const styles = makeStyles(theme => ({
  container: {
    marginTop: 40,
    marginBottom: 40,
    [theme.breakpoints.down("md")]: {
      padding: "0 24px",
    },
  },
  button: {
    borderRadius: 4,
    color: "#1344C5",
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0,
  },
  icon: {
    fontSize: "24px !important",
  },
  title: {
    fontSize: 32,
    fontWeight: 600,
    letterSpacing: 0.24,
    marginTop: 36,
    marginBottom: 24,
  },
  subheader: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.15,
    marginTop: 40,
    marginBottom: 24,
  },
  p: {
    fontSize: 16,
    letterSpacing: 0.12,
    lineHeight: "26px",
  },
  link: {
    color: "#1344C5",
    fontWeight: 500,
  },
  bold: {
    fontWeight: 500,
  },
  spacer: {
    marginBottom: 24,
  },
  bottomTxt: {
    marginTop: 40,
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.12,
    lineHeight: "26px",
  },
}));
