import { makeStyles } from "@material-ui/core";

export const donationConfirmationStyles = makeStyles(theme => ({
  wrapper: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    minHeight: "100%",
    paddingTop: 88,
    paddingBottom: 56,
    backgroundColor: ({ twoColLayout }) =>
      twoColLayout ? "#F5F5F5" : "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 60,
    },
  },
  twoColContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  thankyouCol: {
    width: 700,
    backgroundColor: "#FFFFFF",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "72px 0",
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 32,
    fontWeight: 600,
    letterSpacing: 0,
    textAlign: "center",
    margin: "48px 16px 24px 16px",
    [theme.breakpoints.down("sm")]: {
      lineHeight: "40px",
      marginTop: 32,
    },
  },
  message: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.2,
    lineHeight: "26px",
    textAlign: "center",
    margin: "0 16px",
    [theme.breakpoints.down("sm")]: {
      lineHeight: "24px",
    },
  },
  spreadThewWord: {
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: 0.3,
    margin: "72px 16px 26px 16px",
    [theme.breakpoints.down("sm")]: {
      margin: "40px 16px 14px 16px",
    },
  },
  orderSummary: {
    marginTop: 72,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));
