import React from "react";
import { connect } from "react-redux";
import {
  Breadcrumbs as MuiBreadcrumbs,
  makeStyles,
  Container,
} from "@material-ui/core";
import { getUrls } from "../../state";

function _Breadcrumbs(props) {
  const {
    forVolunteer,
    orgName,
    teamSlug,
    team_name,
    first_name,
    last_name,
    orgUrl,
    teamUrl,
  } = props;
  const classes = styles();

  return (
    <Container>
      <div className={classes.container}>
        <MuiBreadcrumbs className={classes.breadCrumbs}>
          <a className={classes.link} href={`${orgUrl}#top`}>
            {orgName}
          </a>
          {teamSlug && forVolunteer && (
            <a className={classes.link} href={`${teamUrl}#top`}>
              {team_name}
            </a>
          )}
          {teamSlug && !forVolunteer && (
            <span className={classes.active}>{team_name}</span>
          )}
          {forVolunteer && (
            <span className={classes.active}>
              {`${first_name} ${last_name}`}
            </span>
          )}
        </MuiBreadcrumbs>
      </div>
    </Container>
  );
}

export const Breadcrumbs = connect(state => {
  const {
    campaign: {
      name: orgName,
      team_slug: teamSlug,
      team_name,
      first_name,
      last_name,
    },
  } = state;
  const { orgUrl, teamUrl } = getUrls(state);
  return {
    orgName,
    teamSlug,
    team_name,
    first_name,
    last_name,
    orgUrl,
    teamUrl,
  };
})(_Breadcrumbs);

const styles = makeStyles(theme => ({
  container: {
    marginTop: 24,
    display: "flex",
    [theme.breakpoints.down("md")]: {
      marginLeft: 16,
      marginRight: 16,
    },
  },
  breadCrumbs: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.2,
    display: "flex",
    color: theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {
      fontSize: 9,
    },
  },
  link: {
    textDecoration: "underline",
    color: theme.palette.text.primary,
  },
  active: {
    color: theme.palette.primary.main,
  },
}));
