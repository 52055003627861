import React, { Fragment, useEffect, useState } from "react";
import {
  makeStyles,
  Dialog,
  IconButton,
  Button,
  Fade,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Clear";
import HomeIcon from "@material-ui/icons/Home";
import SchoolIcon from "@material-ui/icons/LocationCity";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useField } from "react-final-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import classnames from "classnames";
import { productActions } from "../../state";
import { hexToRGBA } from "../../lib";
import { SwitchDeliveryWarningDialog } from "./SwitchDeliveryWarningDialog";

function _ItemUnavailableWithShippingMethodModal(props) {
  const {
    onClose,
    selectedProducts,
    ship_to_school_label: STSLbl = "",
    ship_to_school_delivery_message = "",
    actions: { setIsShipToSchool },
  } = props;
  const classes = styles();
  const [show, setShow] = useState(false);
  const [hasConflicts, setHasConflicts] = useState(false);
  const [showConflictWarning, seShowConflictWarning] = useState(false);
  const stsInput = useField("ship_to_school").input;
  const ship_to_school = stsInput.value;
  const [tempSTS, setTempSTS] = useState(ship_to_school);
  const [methodChanged, setMethodChanged] = useState(false);

  useEffect(() => {
    setTimeout(() => setShow(true), 2000);
  }, []);

  useEffect(() => {
    setMethodChanged(tempSTS !== ship_to_school);
  }, [tempSTS, ship_to_school]);

  useEffect(() => {
    const changeToSTS = !ship_to_school;
    const _hasConflicts = Object.keys(selectedProducts).some(id => {
      const {
        ship_to_school_only: STS,
        ship_to_home_only: STH,
      } = selectedProducts[id].product;
      if ((changeToSTS && STH) || (!changeToSTS && STS)) {
        return true;
      }
    });
    setHasConflicts(_hasConflicts);
  }, [selectedProducts, ship_to_school]);

  const onSave = () => {
    if (hasConflicts) {
      seShowConflictWarning(true);
      setShow(false);
    } else {
      stsInput.onChange(tempSTS);
      setIsShipToSchool(tempSTS);
      onClose();
    }
  };

  const closeDeliverySwitchWarning = () => {
    onClose();
    seShowConflictWarning(false);
  };

  return (
    <Fragment>
      <Dialog
        open={show}
        onClose={null}
        maxWidth={false}
        classes={{
          paperWidthFalse: classes.paperWidthFalse,
          paper: classes.paper,
        }}
        TransitionComponent={CustomFade}
      >
        <div className={classes.container}>
          <div className={classes.top}>
            <div className={classes.title}>
              Item unavailable with selected shipping method
            </div>
            <div className={classes.closeWrapper}>
              <IconButton size="small" onClick={onClose}>
                <CloseIcon className={classes.close} />
              </IconButton>
            </div>
          </div>

          <div className={classes.subTitle}>
            The{" "}
            <span className={classes.bold}>
              `Ship to {ship_to_school ? STSLbl : "home"}`
            </span>{" "}
            shipping method is currently selected. This item is only available
            for{" "}
            <span className={classes.bold}>
              `Ship to {ship_to_school ? "home" : STSLbl}`
            </span>{" "}
            delivery. You may update your shipping method below.
          </div>

          <div className={classes.options}>
            <div
              className={classnames(
                classes.option,
                !tempSTS && classes.selectedOption,
              )}
            >
              <div>
                <div className={classes.optionTopRow}>
                  <div>
                    <HomeIcon className={classes.icon} />
                  </div>
                  <div>Ship to home</div>
                </div>
                <div className={classes.optionTxt}>
                  Shipping fees will be applied at checkout
                </div>
              </div>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => setTempSTS(false)}
                startIcon={!tempSTS ? <CheckCircleIcon /> : undefined}
                size="large"
              >
                {!tempSTS ? "Selected" : "Select"}
              </Button>
            </div>

            <div
              className={classnames(
                classes.option,
                tempSTS && classes.selectedOption,
              )}
            >
              <div className={classes.optionTopRow}>
                <div>
                  <SchoolIcon className={classes.icon} />
                </div>
                <div>Ship to {STSLbl}</div>
              </div>
              <div className={classes.optionTxt}>
                {ship_to_school_delivery_message}
              </div>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => setTempSTS(true)}
                startIcon={tempSTS ? <CheckCircleIcon /> : undefined}
                size="large"
              >
                {tempSTS ? "Selected" : "Select"}
              </Button>
            </div>
          </div>

          <div className={classes.buttons}>
            <Button
              variant="contained"
              color="primary"
              className={classes.save}
              onClick={onSave}
              disabled={!methodChanged}
              size="large"
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className={classes.cancel}
              onClick={onClose}
              size="large"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Dialog>

      {showConflictWarning && (
        <SwitchDeliveryWarningDialog closeDialog={closeDeliverySwitchWarning} />
      )}
    </Fragment>
  );
}

export const ItemUnavailableWithShippingMethodModal = connect(
  state => {
    const {
      campaign: { ship_to_school_label, ship_to_school_delivery_message },
      product: { selectedProducts },
    } = state;
    return {
      selectedProducts,
      ship_to_school_label,
      ship_to_school_delivery_message,
    };
  },
  dispatch => {
    return { actions: bindActionCreators(productActions, dispatch) };
  },
)(_ItemUnavailableWithShippingMethodModal);

const CustomFade = React.forwardRef((props, ref) => {
  return (
    <Fade ref={ref} {...props} timeout={{ appear: 500, enter: 500, exit: 0 }} />
  );
});

const styles = makeStyles(theme => ({
  paperWidthFalse: {
    maxWidth: "100%",
  },
  paper: {
    margin: 8,
  },
  container: {
    width: 600,
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 32,
    [theme.breakpoints.down("sm")]: {
      padding: "12px 16px 32px 16px",
    },
  },
  top: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0,
    paddingBottom: 16,
    lineHeight: "26px",
    paddingRight: 16,
  },
  closeWrapper: {
    marginTop: -16,
    marginRight: -16,
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: 0,
      marginRight: -6,
    },
  },
  close: {
    fontSize: 32,
    color: "#A9A9A9",
    [theme.breakpoints.down("sm")]: {},
  },
  subTitle: {
    fontSize: 16,
    letterSpacing: 0.2,
    lineHeight: "22px",
    marginBottom: 40,
  },
  bold: {
    fontWeight: 500,
  },
  options: {
    display: "flex",
    justifyContent: "space-between",
  },
  option: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 16,
    minHeight: 120,
    borderRadius: 4,
    width: "calc(50% - 8px)",
    border: `1px solid ${hexToRGBA(theme.palette.text.primary, 0.31)}`,
  },
  selectedOption: {
    border: `3px solid ${theme.palette.primary.main}`,
  },
  optionTopRow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.25,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      fontSize: 16,
      letterSpacing: 0.2,
    },
  },
  icon: {
    marginRight: 4,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  optionTxt: {
    paddingTop: 8,
    fontSize: 14,
    lineHeight: "18px",
    letterSpacing: 0.17,
    textAlign: "center",
    marginBottom: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
      lineHeight: "15px",
      letterSpacing: 0.13,
    },
  },
  buttons: {
    marginTop: 40,
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  save: {
    marginRight: 16,
    width: 140,
    [theme.breakpoints.down("sm")]: {
      width: "calc(50% - 8px)",
      maxWidth: 160,
    },
  },
  cancel: {
    width: 140,
    [theme.breakpoints.down("sm")]: {
      width: "calc(50% - 8px)",
      maxWidth: 160,
    },
  },
}));
