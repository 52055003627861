import React, { useEffect } from "react";
import { useMediaQuery, makeStyles } from "@material-ui/core";
import { ThankyouMobileSVG, ThankyouSVG } from "..";

export function VolunteerRegistrationConfirmation() {
  const classes = styles();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.container}>
      {isMobile ? <ThankyouMobileSVG /> : <ThankyouSVG />}
      <div className={classes.title}>Thank you for registering</div>
      <div className={classes.subTitle}>
        Please see your email for login instructions
      </div>
    </div>
  );
}

const styles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    padding: "40px 0",
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 32,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "35px",
    textAlign: "center",
    margin: "60px 0 36px 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
      lineHeight: "28px",
      margin: "32px 0 24px 0",
    },
  },
  subTitle: {
    fontSize: 22,
    fontWeight: 600,
    textAlign: "center",
    letterSpacing: "0.28px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
}));
