import React from "react";
import { IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { socialMediaStyles } from "./SocialMedia.styles";
import { getUrls } from "../../state";
import EmailIcon from "../../images/Email.svg";
import { AFFILIATE_SUB_CODES, timestamp } from "../../lib";
import { socialMediaTextPrefill } from "./socialMediaTextPrefill";

export function _Email({ large, orgName, siteLink }) {
  const classes = socialMediaStyles();
  const link = `${siteLink}&as=${AFFILIATE_SUB_CODES.EMAIL}&at=${timestamp()}`;
  const body = socialMediaTextPrefill({
    link,
    orgName,
    withUrl: true,
    includeGreeting: true,
  });

  return (
    <IconButton
      href={`mailto:?subject=${orgName}&body=${body}`}
      className={large ? classes.largeButton : classes.button}
    >
      <img
        src={EmailIcon}
        alt="email"
        className={large ? classes.largeIcon : classes.icon}
      ></img>
    </IconButton>
  );
}

export const Email = connect(state => {
  const { fullHomeUrlWithAffiliate } = getUrls(state);
  return {
    orgName: state.campaign.name,
    siteLink: fullHomeUrlWithAffiliate,
  };
})(_Email);
