import { Button, makeStyles } from "@material-ui/core";

export function ErrorScreen() {
  const classes = styles();

  return (
    <div className={classes.container}>
      <div className={classes.txtContainer}>
        <div>We are sorry</div>
        <div>an unexpected Error ocurred</div>
      </div>

      <Button variant="contained" onClick={() => window.location.reload()}>
        Reload page
      </Button>
    </div>
  );
}

const styles = makeStyles(theme => ({
  container: {
    minHeight: "100vh",
    minWidth: "100vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  txtContainer: {
    marginBottom: 24,
    textAlign: "center",
    fontSize: 24,
    lineHeight: "32px",
    padding: "0 8px",
    [theme.breakpoints.down("xs")]: {
      fontSize: 22,
    },
  },
}));
