import React, { useEffect, Fragment, useState } from "react";
import queryString from "query-string";
import { Button, makeStyles } from "@material-ui/core";
import { Toast } from "../components";
import { APIClient } from "../lib";

export function Unsubscribe({ location }) {
  const { u: contactUuid } = queryString.parse(location.search);
  const classes = styles();
  const [toast, setToast] = useState(null);
  const [volunteerName, setVolunteerName] = useState("");
  const [orgName, setOrgName] = useState("");
  const [disable, setDisable] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (!contactUuid) setToast("Invalid unsubscribe link!");
    else fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactUuid]);

  const fetchData = async () => {
    const res = await APIClient.get(
      `contacts/unsubscribe_data?uuid=${contactUuid}`,
    );
    const { error, errorMessage, data } = res;
    if (error) {
      setToast(errorMessage);
    } else {
      const { volunteer_name, org_name } = data;
      setVolunteerName(volunteer_name);
      setOrgName(org_name);
    }
  };

  const unsubscribe = async () => {
    setDisable(true);
    const res = await APIClient.post("contacts/unsubscribe", {
      uuid: contactUuid,
    });
    const { error, errorMessage } = res;
    if (error) setToast(errorMessage);
    else setSuccess(true);
  };

  if (success) {
    return (
      <div className={classes.success}>You have successfully unsubscribed</div>
    );
  }
  return (
    <Fragment>
      <div className={classes.container}>
        <div className={classes.header}>Unsubscribe</div>
        {orgName && (
          <Fragment>
            <div className={classes.msg}>
              Please confirm that you want to unsubscribe from receiving
              solicitation emails
            </div>
            <div className={classes.msg}>
              from <span className={classes.name}>{volunteerName}</span> with{" "}
              <span className={classes.name}>{orgName}</span>.
            </div>
            <Button
              className={classes.button}
              variant="contained"
              onClick={unsubscribe}
              disabled={disable}
            >
              Unsubscribe
            </Button>
          </Fragment>
        )}
      </div>
      <Toast toast={toast} setToast={setToast} />
    </Fragment>
  );
}

const styles = makeStyles(_theme => ({
  success: {
    padding: "100px 16px",
    textAlign: "center",
    fontWeight: 500,
    fontSize: 18,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    padding: "100px 16px",
  },
  header: {
    color: "#E31936",
    fontSize: 24,
    marginBottom: 36,
    fontWeight: 500,
  },
  name: {
    fontWeight: 500,
  },
  msg: {
    fontSize: "15px",
    lineHeight: "19px",
    textAlign: "center",
  },
  button: {
    marginTop: 36,
    backgroundColor: "#E31936",
    color: "#FFFFFF",
  },
}));
