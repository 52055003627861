import React from "react";
import {
  Dialog,
  IconButton,
  useMediaQuery,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { SubmissionForm, SubmissionSuccess } from "..";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { submissionActions } from "../../state";

export function _SubmissionDialog(props) {
  const {
    actions: { closeSubmissionDialog },
    submissionSuccess,
    showDialog,
  } = props;
  const isSmallDevice = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const classes = styles();

  return (
    <Dialog
      onClose={closeSubmissionDialog}
      open={showDialog}
      maxWidth={false}
      fullScreen={isSmallDevice}
    >
      <div className={classes.container}>
        <div className={classes.close}>
          <IconButton onClick={closeSubmissionDialog}>
            <CloseIcon />
          </IconButton>
        </div>
        {!submissionSuccess && <SubmissionForm />}
        {submissionSuccess && <SubmissionSuccess />}
      </div>
    </Dialog>
  );
}

export const SubmissionDialog = connect(
  state => {
    return { ...state.submission };
  },
  dispatch => {
    return { actions: bindActionCreators(submissionActions, dispatch) };
  },
)(_SubmissionDialog);

const styles = makeStyles(theme => ({
  container: {
    display: "flex",
    justifyContent: "center",
    padding: 32,
    width: 600,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "unset",
      padding: 24,
      minHeight: "100vh",
      alignItems: "center",
    },
  },
  close: {
    position: "absolute",
    top: 22,
    right: 22,
    zIndex: 99,
    [theme.breakpoints.down("sm")]: {
      top: 24,
      right: 24,
    },
  },
}));
