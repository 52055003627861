import React from "react";
import { IconButton } from "@material-ui/core";
import ImessageIcon from "../../images/IMessage.svg";
import { connect } from "react-redux";
import { getUrls } from "../../state";
import { socialMediaStyles } from "./SocialMedia.styles";
import { isIOS } from "react-device-detect";
import { AFFILIATE_SUB_CODES, timestamp } from "../../lib";
import { socialMediaTextPrefill } from "./socialMediaTextPrefill";

export function _IMessage({ large, orgName, siteLink }) {
  const classes = socialMediaStyles();
  const link = `${siteLink}&as=${AFFILIATE_SUB_CODES.TEXT}&at=${timestamp()}`;
  const body = socialMediaTextPrefill({
    link,
    orgName,
    withUrl: true,
  });

  return (
    <IconButton
      href={isIOS ? `sms://&body=${body}` : `sms:?&body=${body}`}
      className={large ? classes.largeButton : classes.button}
    >
      <img
        src={ImessageIcon}
        alt="iMessage"
        className={large ? classes.largeIcon : classes.icon}
      ></img>
    </IconButton>
  );
}

export const IMessage = connect(state => {
  const { fullHomeUrlWithAffiliate } = getUrls(state);
  return {
    orgName: state.campaign.name,
    siteLink: fullHomeUrlWithAffiliate,
  };
})(_IMessage);
