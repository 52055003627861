import React from "react";
import { Button, makeStyles, IconButton, MenuItem } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { Field } from "react-final-form";
import { TextField } from "final-form-material-ui";
import { FieldArray } from "react-final-form-arrays";
import { connect } from "react-redux";
import { required } from "../../lib/validators";
import { hexToRGBA } from "../../lib";

function _FamilyMembers({ allow_family_accounts, class_options }) {
  const classes = styles();

  if (!allow_family_accounts) return <></>;
  return (
    <FieldArray name="family_members">
      {({ fields }) => (
        <div className={classes.container}>
          <div className={classes.header}>Add additional family members</div>

          {fields && fields.length > 0 && (
            <div className={classes.inputs}>
              {fields.map((row, index) => {
                return (
                  <div key={index} className={classes.group}>
                    <div className={classes.row}>
                      <Field
                        name={`${row}.first_name`}
                        component={TextField}
                        placeholder="First name"
                        validate={required}
                        className={classes.input}
                      />
                      <Field
                        name={`${row}.last_name`}
                        component={TextField}
                        placeholder="Last name"
                        validate={required}
                        className={classes.input}
                      />
                    </div>

                    <div className={classes.row}>
                      {Array.isArray(class_options) ? (
                        <Field
                          name={`${row}.class_name`}
                          placeholder="Class"
                          validate={required}
                          select
                          SelectProps={{
                            displayEmpty: true,
                            renderValue: value =>
                              value !== "" ? value : "Select a Class",
                          }}
                          component={TextField}
                          className={classes.input}
                        >
                          {class_options.map((option, i) => (
                            <MenuItem key={i} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Field>
                      ) : (
                        <Field
                          name={`${row}.class_name`}
                          placeholder="Class"
                          component={TextField}
                          className={classes.input}
                        />
                      )}

                      <IconButton
                        onClick={() => fields.remove(index)}
                        className={classes.icon}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          <Button
            variant="contained"
            color="secondary"
            onClick={() => fields.push({})}
            startIcon={<AddIcon />}
          >
            Add family member
          </Button>
        </div>
      )}
    </FieldArray>
  );
}

export const FamilyMembers = connect(state => {
  const { allow_family_accounts, class_options } = state.campaign;
  return { allow_family_accounts, class_options };
})(_FamilyMembers);

const styles = makeStyles(theme => ({
  container: {
    marginTop: 24,
  },
  header: {
    color: hexToRGBA(theme.palette.text.primary, 0.86),
    fontSize: 16,
    lineHeight: "20px",
    letterSpacing: 0,
    marginBottom: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      lineHeight: "17px",
    },
  },
  inputs: {
    border: `1px solid ${theme.palette.text.secondary}`,
    padding: "16px 16px 0 16px",
    borderRadius: 8,
    marginBottom: 24,
  },
  group: {
    paddingBottom: 8,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 20,
    },
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  input: {
    marginBottom: 24,
    width: "calc(50% - 12px)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  icon: {
    color: theme.palette.text.primary,
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0,
      marginTop: -12,
    },
  },
}));
