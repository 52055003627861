import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import { Container, makeStyles } from "@material-ui/core";
import { ProductDetails } from "./ProductDetails";

function _ProductSuggestions(props) {
  const { productSuggestions } = props;
  const classes = styles();

  if (!Array.isArray(productSuggestions) || !productSuggestions.length) {
    return <Fragment />;
  }
  return (
    <Container>
      <div className={classes.container}>
        <div className={classes.title}>Frequently added</div>
        <div className={classes.cardsWrapper}>
          <div className={classes.cardsContainer}>
            {productSuggestions.map(product => {
              return <Card key={product.id} product={product} />;
            })}
          </div>
        </div>
      </div>
    </Container>
  );
}

function Card({ product }) {
  const { image, product_name, product_price } = product;
  const classes = styles();
  const [showDetails, setShowDetails] = useState(false);

  return (
    <Fragment>
      <ProductDetails
        product={product}
        handleClose={setShowDetails}
        open={showDetails}
      />
      <div className={classes.card} onClick={() => setShowDetails(true)}>
        <div className={classes.imageWrapper}>
          <img className={classes.image} src={image} alt="product" />
        </div>
        <div className={classes.productName}> {product_name}</div>
        <div className={classes.price}>${product_price} </div>
      </div>
    </Fragment>
  );
}

export const ProductSuggestions = connect(state => {
  const {
    product: { productSuggestions },
  } = state;
  return { productSuggestions };
})(_ProductSuggestions);

const styles = makeStyles(theme => ({
  container: {
    width: "100%",
    paddingTop: "40px",
    paddingBottom: 18,
    [theme.breakpoints.down("sm")]: {
      paddingTop: "24px",
    },
  },
  title: {
    textAlign: "center",
    fontSize: 32,
    fontWeight: 600,
    letterSpacing: 0.4,
    marginBottom: 48,
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
      letterSpacing: 0.3,
      marginBottom: 32,
    },
  },
  cardsWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  cardsContainer: {
    display: "flex",
    height: "fit-content",
    overflowX: "auto",
    paddingBottom: 18,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  card: {
    cursor: "pointer",
    width: 225,
    margin: "0 17px",
    [theme.breakpoints.down("sm")]: {
      margin: "0 8px",
      width: 125,
    },
  },
  imageWrapper: {
    width: 225,
    height: 225,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: 125,
      height: 125,
    },
  },
  image: {
    maxWidth: 225,
    maxHeight: 225,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 125,
      maxHeight: 125,
    },
  },
  productName: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.2,
    padding: "17px 0 20px 0",
    lineHeight: "18px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      padding: "11px 0 8px 0",
      lineHeight: "14px",
    },
  },
  price: {
    fontSize: 20,
    letterSpacing: 0.25,
  },
}));
