import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import { Container } from "@material-ui/core";
import { RulesModal } from "..";
import parse from "html-react-parser";
import { rulesAndDisclaimersStyles } from "./RulesAndDisclaimers.styles";

function _RulesAndDisclaimers(props) {
  const { disclaimer_html, rulesHtml, forVolunteer } = props;
  const classes = rulesAndDisclaimersStyles({ forVolunteer });
  const [rulesModalOpen, toggleRulesModal] = useState(false);
  if (!disclaimer_html) return <Fragment />;

  return (
    <Container>
      <div className={classes.container}>
        {rulesHtml && (
          <RulesModal isOpen={rulesModalOpen} toggleModal={toggleRulesModal} />
        )}

        {disclaimer_html && (
          <div /* needed for quill css */ className="quill">
            {parse(`${disclaimer_html}`)}
          </div>
        )}

        {rulesHtml && (
          <div className={classes.rulesLinkWrapper}>
            <span
              className={classes.rulesLink}
              onClick={() => toggleRulesModal(true)}
            >
              See full rules and regulations
            </span>
          </div>
        )}
      </div>
    </Container>
  );
}

export const RulesAndDisclaimers = connect(state => {
  const {
    disclaimer_html,
    rules_and_regulations_html: rulesHtml,
  } = state.campaign;
  return { disclaimer_html, rulesHtml };
})(_RulesAndDisclaimers);
