import { APIClient } from "../../lib";
import { setToast } from "../ui/actions";

const productTypes = {
  CAMPAIGN_PRODUCTS_FETCH: "CAMPAIGN_PRODUCTS_FETCH",
  PRODUCT_FETCH_FAILURE: "PRODUCT_FETCH_FAILURE",
  ADD_PRODUCT: "ADD_PRODUCT",
  REMOVE_PRODUCT: "REMOVE_PRODUCT",
  CHANGE_QUANTITY: "CHANGE_QUANTITY",
  IS_SHIP_TO_SCHOOL_SET: "IS_SHIP_TO_SCHOOL_SET",
  PRODUCT_DONATION_SET: "PRODUCT_DONATION_SET",
  ORDER_FEES_LOADING: "ORDER_FEES_LOADING",
  ORDER_FEES_SET: "ORDER_FEES_SET",
  ORDER_FEES_ERROR: "ORDER_FEES_ERROR",
  NO_ORDER_FEES_SET: "NO_ORDER_FEES_SET",
  ORDER_FEES_RESOLVED_TOGGLE: "ORDER_FEES_RESOLVED_TOGGLE",
  CHECKOUT_TRIGGERED_SET: "CHECKOUT_TRIGGERED_SET",
  UPDATE_PERSONALIZATION: "UPDATE_PERSONALIZATION",
  CART_RESET: "CART_RESET",
  SHIPPING_SET: "SHIPPING_SET",
  ORDER_FIGURES_UPDATE: "ORDER_FIGURES_UPDATE",
};

const productActions = {
  getCampaignProducts(campaignId) {
    return async (dispatch, _getState) => {
      const response = await APIClient.get(
        `/campaign_products/store?campaign_id=${campaignId}`,
      );
      const { error, errorMessage, data } = response;
      if (error) {
        // TODO add reducer for this type
        return dispatch({
          type: productTypes.PRODUCT_FETCH_FAILURE,
          data: { error, errorMessage },
        });
      }
      dispatch({ type: productTypes.CAMPAIGN_PRODUCTS_FETCH, data });
    };
  },
  getOrderFees(post, noLoading = false) {
    return async (dispatch, _getState) => {
      if (!noLoading) {
        dispatch({ type: productTypes.ORDER_FEES_LOADING });
      }
      const response = await APIClient.post("/order_fees/get_fees", post);
      const { error, errorMessage, data } = response;
      if (error) {
        dispatch({
          type: productTypes.ORDER_FEES_ERROR,
          data: { error, errorMessage },
        });
        dispatch(setToast(errorMessage));
        return false;
      }
      dispatch({ type: productTypes.ORDER_FEES_SET, data });
      return true;
    };
  },
  addProduct(id, product) {
    return {
      type: productTypes.ADD_PRODUCT,
      data: { id, product },
    };
  },
  removeProduct(id) {
    return { type: productTypes.REMOVE_PRODUCT, id };
  },
  changeQuantity(id, action) {
    return { type: productTypes.CHANGE_QUANTITY, data: { id, action } };
  },
  setIsShipToSchool(isShipToSchool) {
    return { type: productTypes.IS_SHIP_TO_SCHOOL_SET, isShipToSchool };
  },
  setProductDonation(productDonation) {
    return { type: productTypes.PRODUCT_DONATION_SET, productDonation };
  },
  setCheckoutTriggered() {
    return { type: productTypes.CHECKOUT_TRIGGERED_SET };
  },
  setNoOrderFees() {
    return { type: productTypes.NO_ORDER_FEES_SET };
  },
  toggleOrderFeesResolved(orderFeesResolved) {
    return {
      type: productTypes.ORDER_FEES_RESOLVED_TOGGLE,
      orderFeesResolved,
    };
  },
  updatePersonalization(id, index, value) {
    return {
      type: productTypes.UPDATE_PERSONALIZATION,
      data: { id, index, value },
    };
  },
  setShipping(shipping) {
    return { type: productTypes.SHIPPING_SET, shipping };
  },
  updateOrderFigures(orderFigures) {
    return { type: productTypes.ORDER_FIGURES_UPDATE, orderFigures };
  },
};

export { productTypes, productActions };
