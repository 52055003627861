import { WEBSITE_SHARING_LINKS_AFFILIATE } from "../../lib";
import { isPast } from "date-fns";

function getUrls(state) {
  const query = window.location.search;
  const {
    slug: orgSlug = "",
    campaignSlug = "",
    volunteerSlug = "",
    team_slug: teamSlug = "",
    campaignId = "",
    company_frontend_url = "",
    orgId = "",
  } = state.campaign;
  const baseUrl = `/${orgSlug}/${campaignSlug}`;

  let homeUrl = baseUrl;
  if (volunteerSlug) homeUrl = `${baseUrl}/${volunteerSlug}`;
  else if (teamSlug) homeUrl = `${baseUrl}/teams/${teamSlug}`;

  const loginUrl = `${process.env.REACT_APP_ADMIN_URL}/login?c=${campaignId}&org_id=${orgId}`;

  return {
    homeUrl: `${homeUrl}${query}`,
    fullHomeUrl: `${company_frontend_url}${homeUrl}${query}`,
    fullHomeUrlWithAffiliate: `${company_frontend_url}${homeUrl}?a=${WEBSITE_SHARING_LINKS_AFFILIATE}`,
    orgUrl: `${baseUrl}${query}`,
    teamUrl: `${baseUrl}/teams/${teamSlug}${query}`,
    volunteerUrl: `${baseUrl}/${volunteerSlug}${query}`,
    registerUrl: `${homeUrl}/volunteers/register${query}`,
    loginUrl,
  };
}

function getShowRegistration(state) {
  const {
    volunteerSlug,
    no_volunteer_registration,
    registration_cutoff_date,
    end_date,
    campaignId,
  } = state.campaign;
  let showRegistration = true;

  if (!campaignId || Boolean(volunteerSlug) || no_volunteer_registration) {
    showRegistration = false;
  } else if (
    (registration_cutoff_date && isPast(new Date(registration_cutoff_date))) ||
    (end_date && isPast(new Date(end_date)))
  ) {
    showRegistration = false;
  }

  return showRegistration;
}

export { getUrls, getShowRegistration };
