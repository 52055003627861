import React, { useState, Fragment, useEffect } from "react";
import {
  AppBar,
  Button,
  SwipeableDrawer,
  Toolbar,
  IconButton,
  useMediaQuery,
  Container,
  Badge,
  useScrollTrigger,
  Link as MuiLink,
} from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  submissionActions,
  getUrls,
  uiActions,
  getShowRegistration,
} from "../state";
import classnames from "classnames";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { Link } from "react-router-dom";
import {
  PRODUCTS_EL_ID,
  DONATE_EL_ID,
  isBigKahunaCompany,
  campaignTypeBooleans,
  isSchoolathonCompany,
  nonEmptyArray,
  EVENT_TICKET_EL_ID,
} from "../lib";
import { headerStyles } from "./Header.styles";

function _Header(props) {
  const {
    campaignId,
    companyId,
    campaign_type_id,
    logo,
    allowProductDonations,
    allow_custom_amt,
    suggested_amounts,
    hasEvents,
    orderItemCount,
    homeUrl,
    registerUrl,
    loginUrl,
    donationSuccess,
    informational_site_only,
    volunteer_pages_only,
    isVolunteer,
    showRegistration,
    actions: { openSubmissionDialog, updateHashLinkTrigger },
  } = props;
  const notOnTop = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });
  const [menuOpen, toggleMenu] = useState(false);
  const [showDonateLinks, setShowDonateLinks] = useState(false);
  const [showCartLink, setShowCartLink] = useState(false);
  // it takes time for useMediaQuery to kick in so we can't just use one var with its opposite, because you will see a flash of the other
  const lgDevice = useMediaQuery(theme => theme.breakpoints.up("md"));
  const smallDevice = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const { isRaffle, isDonation, isMatching, isProduct } = campaignTypeBooleans(
    campaign_type_id,
  );
  const isBigKahuna = isBigKahunaCompany(companyId);
  const isSchoolathon = isSchoolathonCompany(companyId);
  const classes = headerStyles({ notOnTop, isProduct });

  useEffect(() => {
    if (!campaignId) return;
    if (
      informational_site_only ||
      isSchoolathon ||
      (volunteer_pages_only && !isVolunteer) ||
      (!isProduct &&
        !allow_custom_amt &&
        !nonEmptyArray(suggested_amounts) &&
        hasEvents)
    ) {
      setShowDonateLinks(false);
    } else setShowDonateLinks(true);

    if (
      !isProduct ||
      informational_site_only ||
      (volunteer_pages_only && !isVolunteer)
    ) {
      setShowCartLink(false);
    } else setShowCartLink(true);
  }, [
    campaignId,
    informational_site_only,
    isSchoolathon,
    isVolunteer,
    volunteer_pages_only,
    isProduct,
    allow_custom_amt,
    suggested_amounts,
    hasEvents,
  ]);

  const handleLinkClick = (updateHLTrigger = false) => {
    closeMenu();
    if (updateHLTrigger) updateHashLinkTrigger();
    if (donationSuccess) {
      setTimeout(() => window.location.reload(), 100);
    }
  };

  const closeMenu = () => {
    if (smallDevice) toggleMenu(false);
  };

  const Cart = () => {
    if (!showCartLink) return <></>;
    return (
      <IconButton
        edge="end"
        size="small"
        className={classes.cart}
        component={Link}
        to={`${homeUrl}#cart`}
        onClick={updateHashLinkTrigger}
      >
        <Badge
          badgeContent={donationSuccess ? 0 : orderItemCount}
          color="primary"
          overlap="rectangular"
        >
          <ShoppingCartIcon />
        </Badge>
      </IconButton>
    );
  };

  const Logo = () => (
    <div className={classes.logoContainer}>
      {logo && <img className={classes.logo} src={logo} alt="Org logo" />}
    </div>
  );

  return (
    <AppBar className={classes.appBar} elevation={0}>
      <Container>
        <Toolbar className={classes.toolbar}>
          {/* DESKTOP */}
          {lgDevice && (
            <Fragment>
              <Logo />

              <div>
                <Button
                  component={Link}
                  to={`${homeUrl}#top`}
                  className={classes.navButton}
                  onClick={() => handleLinkClick(true)}
                >
                  Home
                </Button>
                {showRegistration && (
                  <Button
                    component={Link}
                    to={`${registerUrl}`}
                    className={classes.navButton}
                    onClick={handleLinkClick}
                  >
                    Register
                  </Button>
                )}
                {!isBigKahuna && (
                  <Button
                    className={classes.navButton}
                    onClick={() => openSubmissionDialog("org")}
                  >
                    Contact
                  </Button>
                )}
                <Button
                  href={loginUrl}
                  className={classes.navButton}
                  onClick={handleLinkClick}
                >
                  Login
                </Button>
                {showDonateLinks && (
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={`${homeUrl}#${
                      isProduct ? PRODUCTS_EL_ID : DONATE_EL_ID
                    }`}
                    className={classes.donateButton}
                    size="medium"
                    onClick={() => handleLinkClick(true)}
                  >
                    {isProduct ? "Shop" : "Donate"}
                  </Button>
                )}
                {showDonateLinks && isProduct && allowProductDonations && (
                  <Button
                    variant="contained"
                    color="secondary"
                    component={Link}
                    to={`${homeUrl}#${DONATE_EL_ID}`}
                    className={classes.donateButton}
                    onClick={() => handleLinkClick(true)}
                  >
                    Donate
                  </Button>
                )}
                {hasEvents && (
                  <Button
                    variant="contained"
                    color="secondary"
                    component={Link}
                    to={`${homeUrl}#${EVENT_TICKET_EL_ID}`}
                    className={classes.donateButton}
                    onClick={() => handleLinkClick(true)}
                  >
                    Tickets
                  </Button>
                )}
                <Cart />
              </div>
            </Fragment>
          )}

          {/* MOBILE */}
          {smallDevice && (
            <Fragment>
              <div className={classes.mobileMenuAndLogo}>
                <IconButton
                  edge="start"
                  className={classes.menuIcon}
                  onClick={() => toggleMenu(true)}
                >
                  <MenuIcon />
                </IconButton>
                {(isRaffle || isDonation || isMatching) && <Logo />}
              </div>

              {isProduct && <Logo />}

              <Cart />

              {/* can't say if `!isProduct` because then this button will flash on product campaigns until the API comes back */}
              {(isRaffle || isDonation || isMatching) &&
                showDonateLinks &&
                !hasEvents && (
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={`${homeUrl}#${DONATE_EL_ID}`}
                    className={classes.mobileHeaderDonate}
                    onClick={() => handleLinkClick(true)}
                  >
                    Donate
                  </Button>
                )}

              {hasEvents && (
                <Button
                  variant="contained"
                  color="secondary"
                  component={Link}
                  to={`${homeUrl}#${EVENT_TICKET_EL_ID}`}
                  className={classes.mobileHeaderDonate}
                  onClick={() => handleLinkClick(true)}
                >
                  Tickets
                </Button>
              )}
            </Fragment>
          )}
        </Toolbar>
      </Container>

      {smallDevice && (
        <SwipeableDrawer
          anchor="bottom"
          open={menuOpen}
          onClose={() => toggleMenu(false)}
          onOpen={() => toggleMenu(true)}
          transitionDuration={500}
        >
          <div className={classes.mobileMenuContainer}>
            <div>
              <div className={classes.menuCloseContainer}>
                <IconButton
                  edge="start"
                  className={classes.menuClose}
                  onClick={() => toggleMenu(false)}
                >
                  <CloseIcon fontSize="medium" />
                </IconButton>
              </div>
              <div className={classes.mobileTopLinks}>
                <MuiLink
                  component={Link}
                  to={`${homeUrl}#top`}
                  className={classes.mobileLink}
                  onClick={() => handleLinkClick(true)}
                >
                  Home
                </MuiLink>
                {showRegistration && (
                  <MuiLink
                    component={Link}
                    to={registerUrl}
                    className={classes.mobileLink}
                    onClick={handleLinkClick}
                  >
                    Register
                  </MuiLink>
                )}
                {!isBigKahuna && (
                  <div
                    className={classes.mobileLink}
                    onClick={() => {
                      closeMenu();
                      openSubmissionDialog("org");
                    }}
                  >
                    Contact
                  </div>
                )}
                <MuiLink
                  href={loginUrl}
                  className={classes.mobileLink}
                  onClick={handleLinkClick}
                >
                  Login
                </MuiLink>
              </div>
            </div>

            <div>
              {hasEvents && (
                <Fragment>
                  <Button
                    variant="contained"
                    color="secondary"
                    component={Link}
                    to={`${homeUrl}#${EVENT_TICKET_EL_ID}`}
                    className={classes.mobileDonateButton}
                    size="large"
                    onClick={() => handleLinkClick(true)}
                  >
                    Tickets
                  </Button>
                  {showDonateLinks && <div className={classes.btnSpacer} />}
                </Fragment>
              )}

              {showDonateLinks && (
                <Fragment>
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={`${homeUrl}#${
                      isProduct ? PRODUCTS_EL_ID : DONATE_EL_ID
                    }`}
                    className={classes.mobileDonateButton}
                    size="large"
                    onClick={() => handleLinkClick(true)}
                  >
                    {isProduct ? "Shop" : "Donate"}
                  </Button>

                  {isProduct && allowProductDonations && (
                    <Button
                      variant="contained"
                      color="secondary"
                      component={Link}
                      to={`${homeUrl}#${DONATE_EL_ID}`}
                      className={classnames(
                        classes.mobileDonateButton,
                        classes.mobileBottomButton,
                      )}
                      size="large"
                      onClick={() => handleLinkClick(true)}
                    >
                      Donate
                    </Button>
                  )}
                </Fragment>
              )}
            </div>
          </div>
        </SwipeableDrawer>
      )}
    </AppBar>
  );
}

export const Header = connect(
  state => {
    const {
      campaign: {
        campaignId,
        companyId,
        campaign_type_id,
        logo,
        allow_donations,
        informational_site_only,
        volunteerSlug,
        volunteer_pages_only,
        allow_custom_amt,
        suggested_amounts,
        hasEvents,
      },
      product: { orderItemCount },
      donation: { donationSuccess },
    } = state;
    const { homeUrl, registerUrl, loginUrl } = getUrls(state);
    return {
      campaignId,
      companyId,
      campaign_type_id,
      logo,
      orderItemCount,
      homeUrl,
      registerUrl,
      loginUrl,
      donationSuccess,
      allowProductDonations: allow_donations,
      allow_custom_amt,
      suggested_amounts,
      hasEvents,
      informational_site_only,
      isVolunteer: Boolean(volunteerSlug),
      volunteer_pages_only,
      showRegistration: getShowRegistration(state),
    };
  },
  dispatch => {
    return {
      actions: {
        ...bindActionCreators(submissionActions, dispatch),
        ...bindActionCreators(uiActions, dispatch),
      },
    };
  },
)(_Header);
