import { makeStyles } from "@material-ui/core";

export const productDetailStyles = makeStyles(theme => ({
  drawer: {
    top: 0,
    maxHeight: "unset",
    [theme.breakpoints.down("sm")]: {
      top: 56,
      borderRadius: "15px 15px 0 0",
      maxHeight: "unset",
    },
  },
  wrapper: {
    width: "100vw",
    maxWidth: "100vw",
    minWidth: "100vw",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      overflow: "scroll",
    },
  },
  container: {
    maxWidth: "100%",
    width: 856,
    backgroundColor: "#FFFFFF",
    marginTop: 80,
    marginBottom: 40,
    height: "fit-content",
    [theme.breakpoints.down("sm")]: {
      marginTop: 40,
      padding: "0 16px",
    },
  },
  closeButton: {
    position: "absolute",
    right: 46,
    top: 46,
  },
  mainContent: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  loading: {
    paddingTop: 24,
    marginBottom: 72,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 8,
      marginBottom: 38,
    },
  },
  imageContainer: {
    width: 400,
    marginRight: 56,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  imageWrapper: {
    position: "relative",
    width: "100%",
    minWidth: "100%",
    maxWidth: "100%",
    textAlign: "center",
  },
  image: {
    maxWidth: "100%",
  },
  videoOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    opacity: 0.3,
    backgroundColor: "#000000",
    top: 0,
    cursor: "pointer",
  },
  thumbnails: {
    display: "flex",
    marginTop: 22,
    width: "fit-content",
  },
  thumbnailCard: {
    border: ({ active }) =>
      active
        ? `2px solid ${theme.palette.text.primary}`
        : `1px solid ${theme.palette.text.secondary}`,
    width: 55,
    height: 55,
    borderRadius: 4,
    marginRight: ({ lastCard }) => (lastCard ? 0 : 16),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    cursor: "pointer",
  },
  thumbnailWrapper: {
    width: "fit-content",
    height: "fit-content",
    maxWidth: 50,
    maxHeight: 50,
    position: "relative",
  },
  thumbnail: {
    maxWidth: 50,
    maxHeight: 50,
  },
  playIconWrapper: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#FFFFFF",
    zIndex: 9,
    top: 0,
  },
  thumbnailPlayIcon: {
    fontSize: 28,
    color: "#FFFFFF",
  },
  rightSection: {
    width: 400,
    maxWidth: "100%",
  },
  productName: {
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "26px",
    marginBottom: 8,
    [theme.breakpoints.down("sm")]: {
      marginTop: 32,
      marginBottom: 4,
    },
  },
  category: {
    color: theme.palette.text.secondary,
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "18px",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      lineHeight: "15px",
    },
  },
  price: {
    fontSize: 32,
    fontWeight: 500,
    letterSpacing: 0,
    marginBottom: "30px",
    color: theme.palette.primary.main,
  },
  shipMethod: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 18,
  },
  shipMethodTxt: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.2,
    color: theme.palette.primary.main,
    paddingLeft: 6,
    paddingRight: 4,
  },
  shipIcon: {
    fontSize: 24,
  },
  infoIcon: {
    fontSize: 20,
  },
  changeDelivery: {
    marginTop: -6,
    paddingBottom: 8,
    display: "flex",
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.17,
  },
  changeDeliveryLink: {
    textDecoration: "underline",
    color: theme.palette.primary.main,
    marginLeft: 4,
    cursor: "pointer",
  },
  tooltip: {
    marginBottom: "4px !important",
    backgroundColor: "#b1b1b1",
    fontSize: 12,
    letterSpacing: 0.15,
    lineHeight: "15px",
    color: "#FFFFFF",
    padding: 8,
  },
  optionGroup: {
    marginBottom: 24,
  },
  optionItems: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  optionLabel: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.17,
    marginBottom: 10,
    lineHeight: "15px",
  },
  displayText: {
    fontWeight: 600,
    paddingLeft: 8,
  },
  swatchContainer: {
    marginRight: 8,
    marginBottom: 8,
    borderRadius: "50%",
  },
  selectedColorSwatchContainer: {
    border: `2px solid ${theme.palette.text.primary} !important`,
  },
  colorSwatch: {
    borderRadius: "50%",
    height: 40,
    width: 40,
    border: `1px solid ${theme.palette.text.primary}`,
    position: "relative",
  },
  selectedColorSwatch: {
    border: `1px solid #FFFFFF !important`,
  },
  disabledSwatch: {
    opacity: 0.5,
  },
  textSwatch: {
    minHeight: 40,
    minWidth: 60,
    borderRadius: 20,
    width: "fit-content",
    padding: 6,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `1px solid ${theme.palette.text.primary}`,
    position: "relative",
    color: theme.palette.text.primary,
    fontSize: 14,
    letterSpacing: 0,
  },
  selectedTextSwatch: {
    borderWidth: 2,
    fontWeight: 600,
  },
  pointer: {
    cursor: "pointer",
  },
  unavailableTxt: {
    opacity: 0.5,
  },
  unavailableMenuItem: {
    textDecoration: "line-through",
  },
  diagonalLine: {
    borderRadius: 20,
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
    background: `linear-gradient(to bottom left, transparent calc(50% - 1px),  ${theme.palette.text.primary} calc(50% - 1px), ${theme.palette.text.primary} 50%, transparent 50%)`,
  },
  select: { minWidth: 200, maxWidth: 310, marginTop: 8 },
  addButton: {
    width: 224,
    height: 56,
    borderRadius: 28,
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0,
  },
  viewDiscountOffers: {
    width: 224,
    height: 56,
    borderRadius: 28,
    marginTop: 16,
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.2,
  },
  discountOfferRestrictions: {
    fontSize: 14,
    letterSpacing: 0.17,
    lineHeight: "18px",
    paddingTop: 8,
    color: "rgba(0,0,0,0.31)",
  },
  addedActions: {
    width: 240,
    height: 56,
    borderRadius: 28,
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 6px",
  },
  iconButton: {
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  addedActionIcons: {
    color: theme.palette.primary.contrastText,
  },
  addedNum: {
    color: theme.palette.primary.contrastText,
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0,
  },

  description: {
    marginTop: 24,
  },
  pnContainer: {
    marginBottom: 16,
  },
  pnText: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.17,
    marginBottom: 16,
  },
  pnInput: {
    marginBottom: 16,
  },
}));
