import { ccEntryModes } from "../../lib";
import { donationTypes } from "./actions";
const initialState = {
  donationSuccess: false,
  donationError: false,
  disableRetry: false,
  donationList: [],
  donationListCount: 0,
  matchingDonationSyncAmount: "",
  matchingBarAmount: "",
  sort: "",
  search: "",
  paymentType: "cc",
  selectedDonationItemDays: {},
  baseDonation: 0,
  donationAmount: 0,
  donationTotal: 0,
  ccEntryMode: ccEntryModes.MANUAL,
  grandTotal: "0",
  id: null,
};

const donationReducers = (state = initialState, action) => {
  switch (action.type) {
    case donationTypes.DONATION_SUCCESS:
      return {
        ...state,
        matchingDonationSyncAmount: "",
        matchingBarAmount: "",
        donationSuccess: true,
        ...action.data,
      };
    case donationTypes.DONATION_RESET:
      return {
        ...state,
        donationSuccess: false,
        donationError: false,
        disableRetry: false,
        matchingDonationSyncAmount: "",
        matchingBarAmount: "",
        selectedDonationItemDays: {},
        baseDonation: 0,
        donationAmount: 0,
        donationTotal: 0,
        paymentType: "cc",
        grandTotal: "0",
        id: null,
      };
    case donationTypes.DONATION_LIST_SUCCESS:
      const { total, donations, refresh } = action;
      return {
        ...state,
        donationListCount: total,
        donationList: refresh
          ? donations
          : [...state.donationList, ...donations],
      };
    case donationTypes.DONATION_LIST_CLEAR:
      return { ...state, donationListCount: 0, donationList: [] };
    case donationTypes.MATCHING_DONATION_SYNC_AMOUNT_SET:
      return { ...state, matchingDonationSyncAmount: action.amount };
    case donationTypes.MATCHING_BAR_DONATION_CHANGE:
      return { ...state, matchingBarAmount: action.amount };
    case donationTypes.DONATION_LIST_QUERY_UPDATE:
      return { ...state, [action.field]: action.value };
    case donationTypes.PAYMENT_TYPE_SET:
      return { ...state, paymentType: action.paymentType };
    case donationTypes.DONATION_ERROR:
      return { ...state, ...action.data };
    case donationTypes.SELECTED_DONATION_ITEM_DAYS_ADD:
      return {
        ...state,
        selectedDonationItemDays: {
          ...state.selectedDonationItemDays,
          ...action.newDay,
        },
      };
    case donationTypes.SELECTED_DONATION_ITEM_DAYS_REMOVE:
      // eslint-disable-next-line no-unused-vars
      const { [action.id]: _rm, ...newDays } = state.selectedDonationItemDays;
      return { ...state, selectedDonationItemDays: newDays };
    case donationTypes.DONATION_TOTALS_SET:
      return {
        ...state,
        ...action.totals,
      };
    case donationTypes.CC_ENTRY_MODE_CHANGE:
      return { ...state, ccEntryMode: action.ccEntryMode };
    case donationTypes.GRAND_TOTAL_SET:
      return { ...state, grandTotal: action.grandTotal };
    default:
      return state;
  }
};

export { donationReducers };
