import React, { useState, useEffect, Fragment, useRef } from "react";
import { Tabs, Tab, makeStyles } from "@material-ui/core";

export function CategorySelector(props) {
  const {
    categories,
    setActiveCategory,
    twoColLayout,
    elId,
    adminIframe,
  } = props;
  const classes = styles({ twoColLayout, adminIframe });
  const [showTabs, setShowTabs] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [isFixed, setIsFixed] = useState(false);
  const observerRef = useRef(null);
  const observerElRef = useRef(null);

  useEffect(() => {
    if (!elId || !("IntersectionObserver" in window)) return;
    const el = document.getElementById(elId);
    if (el) {
      observerElRef.current = el;
      observerRef.current = new IntersectionObserver(
        ([e]) => setIsFixed(e.intersectionRatio < 1),
        { threshold: [0, 1] },
      );
      observerRef.current.observe(observerElRef.current);
    }
    return () => {
      if (observerRef.current && observerElRef.current) {
        observerRef.current.unobserve(observerElRef.current);
      }
    };
  }, [elId]);

  useEffect(() => {
    if (Array.isArray(categories) && categories.length > 1) {
      const tabIds = categories.map(({ id }) => id);
      setShowTabs(tabIds);
      setActiveTab(tabIds[0]);
    }
  }, [categories]);

  const handleTabChange = (_e, newTab) => {
    // seems like a bug in MUI - Tab onChange is being triggered even when the curr tab is pressed again - so check if there was a change
    if (newTab !== activeTab) {
      setActiveTab(newTab);
      setActiveCategory(newTab);
      if (isFixed && observerElRef.current) {
        observerElRef.current.scrollIntoView();
      }
    }
  };

  return (
    <Fragment>
      {/* mount this right away so useEffect will pick up */}
      <div className={classes.stickyObserver} id={elId}></div>
      {showTabs && (
        <div className={classes.container}>
          <Tabs
            indicatorColor="primary"
            value={activeTab}
            onChange={handleTabChange}
            orientation={twoColLayout ? "vertical" : "horizontal"}
            variant={twoColLayout ? "standard" : "scrollable"}
            scrollButtons="on"
            className={classes.tabs}
            classes={{ indicator: classes.indicator }}
          >
            {categories.map(c => {
              const { id, name } = c;
              return (
                <Tab
                  key={id}
                  value={id}
                  className={classes.tab}
                  label={name}
                  classes={{ wrapper: classes.tabLabel }}
                />
              );
            })}
          </Tabs>
        </div>
      )}
    </Fragment>
  );
}

const styles = makeStyles(theme => ({
  stickyObserver: {
    height: 1,
    visibility: "hidden",
    position: "absolute",
    marginTop: -80,
    [theme.breakpoints.down("sm")]: {
      marginTop: -56,
    },
  },
  container: {
    position: "sticky",
    top: ({ adminIframe }) => (adminIframe ? 0 : 95),
    height: "fit-content",
    zIndex: 9,
    backgroundColor: "#FFFFFF",
    width: ({ twoColLayout }) => (twoColLayout ? 200 : "100%"),
    marginBottom: ({ twoColLayout }) => (twoColLayout ? 0 : 24),
    [theme.breakpoints.down("sm")]: {
      top: ({ adminIframe }) => (adminIframe ? 0 : 56),
    },
    [theme.breakpoints.only("md")]: {
      top: ({ adminIframe }) => (adminIframe ? 0 : 80),
    },
  },
  tabs: {
    width: ({ twoColLayout }) => (twoColLayout ? 200 : "100%"),
    maxWidth: ({ twoColLayout }) => (twoColLayout ? 200 : "100%"),
  },
  tab: {
    width: ({ twoColLayout }) => (twoColLayout ? 200 : "fit-content"),
    minWidth: "unset",
    maxWidth: 200,
    marginBottom: ({ twoColLayout }) => (twoColLayout ? 12 : 0),
  },
  indicator: {
    left: ({ twoColLayout }) => (twoColLayout ? 0 : "unset"),
  },
  tabLabel: {
    alignItems: ({ twoColLayout }) => (twoColLayout ? "self-start" : "unset"),
    justifyContent: ({ twoColLayout }) =>
      twoColLayout ? "flex-start" : "unset",
    textAlign: ({ twoColLayout }) => (twoColLayout ? "start" : "unset"),
    textTransform: "none",
    fontSize: 20,
    letterSpacing: 0.25,
    lineHeight: "22px",
  },
  category: {
    margin: "0 8px 12px 8px",
    fontSize: "18px",
    textTransform: "capitalize",
    letterSpacing: "0.3px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      margin: "0 6px 12px 6px",
    },
  },
}));
