import { makeStyles } from "@material-ui/core";

export const matchingBarStyles = makeStyles(theme => ({
  matchReached: {
    width: "100%",
    backgroundColor: "#F5F5F5",
    padding: "40px 16px 0 16px",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#FFFFFF",
    },
  },
  reachedMsg: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.2,
    lineHeight: "18px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {},
  },
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "32px 0",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      padding: "24px 16px",
      flexDirection: "column",
    },
    [theme.breakpoints.only("md")]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  matchingCircleContainer: {
    position: "relative",
    width: 138,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: -16,
    paddingTop: 6,
    marginLeft: 56,
    marginRight: 56,
    [theme.breakpoints.only("md")]: {
      marginLeft: 32,
      marginRight: 32,
    },
  },
  xTimes: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    borderRadius: "100%",
    backgroundColor: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 138,
    width: 138,
    fontSize: 52,
    fontWeight: 600,
    letterSpacing: 0.65,
    color: theme.palette.primary.main,
  },
  group: {
    display: "flex",
    alignItems: "center",
    zIndex: 5,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexDirection: "column",
    },
  },
  subheader: {
    color: theme.palette.secondary.main,
    fontSize: 32,
    fontWeight: 600,
    letterSpacing: 0.4,
    paddingRight: 24,
    [theme.breakpoints.only("md")]: {
      paddingRight: 16,
      fontSize: 26,
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: 0,
      fontSize: 24,
      letterSpacing: 0.3,
      paddingBottom: 16,
    },
  },
  input: {
    width: 160,
    "& fieldset": {
      borderRadius: 28,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: 450,
    },
  },
  adornment: {
    fontSize: 20,
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  inputTxt: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0,
    color: theme.palette.text.primary,
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  impactGroup: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 32,
    },
  },
  impact: {
    width: 160,
    height: 56,
    borderRadius: 28,
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0,
    border: `1px solid ${theme.palette.text.secondary}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 16px",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: 450,
      height: 44,
    },
  },
  impactAmount: {
    [theme.breakpoints.down("sm")]: {
      flexGrow: 1,
      textAlign: "center",
    },
  },
  matchBanner: {
    width: "100%",
    maxWidth: 450,
    height: 48,
    borderRadius: 8,
    backgroundColor: theme.palette.secondary.main,
    color: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    paddingLeft: 32,
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.2,
    marginBottom: 48,
    position: "relative",
  },
  xCircle: {
    position: "absolute",
    height: 64,
    width: 64,
    borderRadius: "100%",
    backgroundColor: theme.palette.secondary.main,
    border: "2px solid #FFFFFF",
    right: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: 0.3,
  },
  buttonWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "32px 16px",
    marginLeft: ({ isMatching }) => (isMatching ? 22 : 0),
    [theme.breakpoints.only("md")]: {
      marginLeft: ({ isMatching }) => (isMatching ? 10 : 0),
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: () => 0,
    },
  },
  button: {
    height: 56,
    width: 280,
    borderRadius: 28,
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: 450,
    },
  },
}));
