import React from "react";
import { IconButton } from "@material-ui/core";
import LinkedinIcon from "../../images/Linkedin.svg";
import { connect } from "react-redux";
import { getUrls } from "../../state";
import { socialMediaStyles } from "./SocialMedia.styles";
import { AFFILIATE_SUB_CODES, timestamp } from "../../lib";

function _Linkedin({ large, siteUrl }) {
  const classes = socialMediaStyles();
  const link = encodeURIComponent(
    `${siteUrl}&as=${AFFILIATE_SUB_CODES.LINKEDIN}&at=${timestamp()}`,
  );
  const href = `https://www.linkedin.com/shareArticle?url=${link}`;

  return (
    <IconButton
      href={href}
      target="_blank"
      className={large ? classes.largeButton : classes.button}
    >
      <img
        src={LinkedinIcon}
        alt="linkedin"
        className={large ? classes.largeIcon : classes.icon}
      ></img>
    </IconButton>
  );
}

export const Linkedin = connect(state => {
  const { fullHomeUrlWithAffiliate } = getUrls(state);
  return { siteUrl: fullHomeUrlWithAffiliate };
})(_Linkedin);
