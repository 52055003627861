import React from "react";
import { connect } from "react-redux";
import { format } from "date-fns";
import { makeStyles } from "@material-ui/core";
import StartingSoonSVG from "../../images/StartingSoon.svg";

export function _StartingSoon({ startDate }) {
  const classes = styles();

  return (
    <div className={classes.wrapper}>
      <img src={StartingSoonSVG} alt="starting soon" className={classes.svg} />
      <div className={classes.msg}>Campaign starting soon…</div>
      <div className={classes.date}>
        {startDate
          ? format(new Date(startDate), "MMM d, yyyy 'at' h:mm aaaaa'm'")
          : ""}
      </div>
    </div>
  );
}

export const StartingSoon = connect(state => {
  return { startDate: state.campaign.start_date };
})(_StartingSoon);

const styles = makeStyles(theme => ({
  wrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 56,
    marginBottom: 24,
  },
  svg: {
    width: "fit-content",
    height: "fit-content",
    marginBottom: 32,
  },
  msg: {
    color: theme.palette.primary.main,
    fontSize: 32,
    letterSpacing: 0.4,
    fontWeight: 600,
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
      letterSpacing: 0.33,
    },
  },
  date: {
    color: theme.palette.secondary.main,
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.25,
  },
}));
