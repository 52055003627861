import React, { Fragment, useEffect, useState } from "react";
import {
  makeStyles,
  Dialog,
  useMediaQuery,
  IconButton,
  Container,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import { VideoModal } from "..";
import classNames from "classnames";

function _Showcase({ has_showcase, showcase, hide_stats_component }) {
  const classes = styles({ hide_stats_component });
  const [displayMode, setDisplayMode] = useState(null);
  const [header, setHeader] = useState("Showcase");
  const [paragraphBL, setParagraphBL] = useState("about");
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (!showcase) return;
    const {
      display_mode,
      header: _header,
      items: _items,
      paragraph_button_label,
    } = showcase;
    setDisplayMode(display_mode);
    if (_header) setHeader(_header);
    if (paragraph_button_label) setParagraphBL(paragraph_button_label);
    if (Array.isArray(_items)) setItems(_items);
  }, [showcase]);

  if (!has_showcase || !displayMode || !items.length) return <></>;
  return (
    <div className={classes.wrapper}>
      <Container>
        <div className={classes.header}>{header}</div>
        <div className={classes.itemsWrapper}>
          {items.map((item, i) => {
            if (displayMode === "text-only") {
              return (
                <TextOnlyItem key={i} item={item} paragraphBL={paragraphBL} />
              );
            } else {
              return (
                <WithImgItem key={i} item={item} paragraphBL={paragraphBL} />
              );
            }
          })}
        </div>
      </Container>
    </div>
  );
}

export const Showcase = connect(state => {
  const { has_showcase, showcase, hide_stats_component } = state.campaign;
  return { has_showcase, showcase, hide_stats_component };
})(_Showcase);

function TextOnlyItem({ item, paragraphBL }) {
  const { line_1, line_2, line_3, paragraph } = item;
  const classes = styles();
  const [showDialog, setShowDialog] = useState(false);
  const closeDialog = () => setShowDialog(false);

  return (
    <Fragment>
      <div className={classNames(classes.card, classes.textCard)}>
        {line_1 && <div className={classes.line1}>{line_1}</div>}
        {line_2 && <div className={classes.line2}>{line_2}</div>}
        {line_3 && <div className={classes.line3}>{line_3}</div>}
        {paragraph && (
          <div className={classes.link} onClick={() => setShowDialog(true)}>
            {paragraphBL}
          </div>
        )}
      </div>

      <Modal item={item} showDialog={showDialog} closeDialog={closeDialog} />
    </Fragment>
  );
}

function WithImgItem({ item, paragraphBL }) {
  const { line_1, line_2, line_3, image, video_link, paragraph } = item;
  const classes = styles();
  const [showDialog, setShowDialog] = useState(false);
  const closeDialog = () => setShowDialog(false);

  return (
    <Fragment>
      <div className={classNames(classes.card, classes.imageCard)}>
        <div
          className={classes.image}
          style={{ backgroundImage: `url(${image})` }}
        >
          {video_link && <VideoModal videoLink={video_link} smallIcon />}
        </div>

        <div>
          {line_1 && <div className={classes.line1}>{line_1}</div>}
          {line_2 && <div className={classes.line2}>{line_2}</div>}
          {line_3 && <div className={classes.line3}>{line_3}</div>}
          {paragraph && (
            <div className={classes.link} onClick={() => setShowDialog(true)}>
              {paragraphBL}
            </div>
          )}
        </div>
      </div>

      <Modal item={item} showDialog={showDialog} closeDialog={closeDialog} />
    </Fragment>
  );
}

function Modal({ item, showDialog, closeDialog }) {
  const { line_1, line_2, line_3, paragraph } = item;
  const classes = styles();
  const isSmallDevice = useMediaQuery(theme => theme.breakpoints.down("sm"));

  return (
    <Dialog
      onClose={closeDialog}
      open={showDialog}
      maxWidth={false}
      fullScreen={isSmallDevice}
    >
      <div className={classes.modal}>
        <div className={classes.close}>
          <IconButton key="close" color="inherit" onClick={closeDialog}>
            <CloseIcon />
          </IconButton>
        </div>
        <div>
          {line_1 && <div className={classes.line1}>{line_1}</div>}
          {line_2 && <div className={classes.line2}>{line_2}</div>}
          {line_3 && <div className={classes.line3}>{line_3}</div>}
          <div className={classes.paragraph}>{paragraph}</div>
        </div>
      </div>
    </Dialog>
  );
}

const styles = makeStyles(theme => ({
  wrapper: {
    backgroundColor: "#F5F5F5",
    paddingBottom: 16,
    margin: "36px 0",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 8,
    },
  },
  header: {
    textAlign: "center",
    fontSize: 32,
    fontWeight: 600,
    letterSpacing: 0.4,
    lineHeight: "35px",
    color: theme.palette.secondary.main,
    padding: "56px 16px 48px 16px",
    marginTop: ({ hide_stats_component }) => (hide_stats_component ? 40 : 0),
    [theme.breakpoints.down("sm")]: {
      paddingTop: 40,
      fontSize: 24,
      letterSpacing: 0.3,
      lineHeight: "26px",
    },
  },
  itemsWrapper: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    margin: "0 0 0 -40px",
    width: "calc(100% + 40px)",
    [theme.breakpoints.down("md")]: {
      margin: "0 0 0 0",
      width: "100%",
    },
  },
  card: {
    width: 254,
    marginLeft: 40,
    marginBottom: 40,
    [theme.breakpoints.down("md")]: {
      margin: "0 16px 32px 16px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      maxWidth: 400,
    },
  },
  textCard: {
    backgroundColor: "#FFFFFF",
    borderRadius: 8,
    borderLeft: `12px solid ${theme.palette.primary.main}`,
    padding: "24px 16px",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 24,
    },
  },
  imageCard: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "row",
    },
  },
  image: {
    height: 254,
    width: 254,
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    borderRadius: 8,
    marginBottom: 16,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 0,
      marginRight: 24,
      height: 150,
      width: 150,
      minWidth: 150,
    },
  },
  line1: {
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "16px",
    color: theme.palette.text.secondary,
    marginBottom: 8,
    whiteSpace: "pre-wrap",
  },
  line2: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "25px",
    color: theme.palette.secondary.main,
    marginBottom: 8,
    whiteSpace: "pre-wrap",
  },
  line3: {
    fontSize: 16,
    letterSpacing: 1,
    fontWeight: 500,
    lineHeight: "18px",
    textTransform: "uppercase",
    whiteSpace: "pre-wrap",
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
      letterSpacing: 0.88,
    },
  },
  link: {
    marginTop: 24,
    fontSize: 16,
    letterSpacing: 0,
    textDecoration: "underline",
    cursor: "pointer",
    display: "inline-block",
    [theme.breakpoints.down("xs")]: {
      marginTop: 16,
    },
  },
  modal: {
    padding: 32,
    width: 575,
    maxWidth: "100%",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100vh",
      display: "flex",
      alignItems: "center",
      width: "100%",
    },
  },
  close: {
    position: "absolute",
    right: 22,
    top: 22,
    color: theme.palette.text.secondary,
  },
  paragraph: {
    marginTop: 24,
    fontSize: 16,
    letterSpacing: 0,
    lineHeight: "26px",
    whiteSpace: "pre-wrap",
  },
}));
