import React from "react";
import {
  IconButton,
  makeStyles,
  useMediaQuery,
  Dialog,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

export function SeatingMap({ map_image, onClose }) {
  const classes = styles();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));

  return (
    <Dialog open maxWidth={false} fullScreen={isMobile}>
      <div className={classes.container}>
        <div className={classes.imgWrapper}>
          <img src={map_image} className={classes.img} />
        </div>

        <div className={classes.close}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    </Dialog>
  );
}

const styles = makeStyles(theme => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "40px 24px 32px 24px",
    width: "calc(100vw - 64px)",
    height: "calc(100vh - 64px)",
    maxWidth: "calc(100vw - 64px)",
    maxHeight: "calc(100vh - 64px)",
    overflow: "hidden",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      padding: "40px 16px 16px 16px",
      width: "100vw",
      maxWidth: "100vw",
      height: "100vh",
      maxHeight: "100vh",
    },
  },
  imgWrapper: {
    overflow: "auto",
    width: "fit-content",
    height: "fit-content",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  img: {
    width: "auto",
  },
  close: {
    position: "absolute",
    top: 6,
    right: 6,
  },
}));
