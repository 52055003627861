import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect, useDispatch } from "react-redux";
import { volunteerActions, setToast } from "../../state";
import { bindActionCreators } from "redux";
import { donationFormStyles } from "../donation/DonationForm.styles";

function _AdminVolunteerAutocomplete({
  campaignId,
  setAdminVolunteerId,
  authToken,
  actions,
}) {
  const classes = donationFormStyles();
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const getVolunteers = async () => {
      const { data, error, errorMessage } = await actions.adminGetVolunteerList(
        campaignId,
        authToken,
      );
      if (error) return dispatch(setToast(errorMessage));
      setOptions(data);
    };
    if (campaignId && authToken) {
      getVolunteers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, authToken]);

  const handleSelect = (_e, newValue) => {
    setAdminVolunteerId(newValue ? newValue.id : null);
  };

  return (
    <Autocomplete
      className={classes.input}
      getOptionLabel={option => `${option.first_name} ${option.last_name}`}
      options={options}
      getOptionSelected={(option, value) => option.id === value.id}
      onChange={handleSelect}
      renderInput={params => (
        <TextField {...params} label="Or Select a Student" />
      )}
    />
  );
}

export const AdminVolunteerAutocomplete = connect(
  state => {
    return { campaignId: state.campaign.campaignId };
  },
  dispatch => {
    return { actions: bindActionCreators(volunteerActions, dispatch) };
  },
)(_AdminVolunteerAutocomplete);
