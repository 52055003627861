import { makeStyles } from "@material-ui/core";

export const sponsorsStyles = makeStyles(theme => ({
  wrapper: {
    padding: "40px 0",
    [theme.breakpoints.down("sm")]: {
      padding: "24px 0 40px 0",
    },
  },
  header: {
    fontSize: 32,
    fontWeight: 600,
    letterSpacing: 0.4,
    lineHeight: "35px",
    textAlign: "center",
    marginBottom: 48,
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      letterSpacing: "0.3px",
      marginBottom: 24,
    },
  },
  cardWrapper: {
    outline: "none",
    height: "100%",
    minHeight: 100,
    width: "200px !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 16px",
    [theme.breakpoints.down("sm")]: {
      height: "134px",
      width: "212px !important",
    },
  },
  card: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      height: "114px",
      width: "192px !important",
    },
  },
  name: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "25px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "16px",
    },
  },
  message: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "16px",
    textAlign: "center",
    paddingTop: 8,
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "14px",
    },
  },
  logoWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    maxWidth: "100%",
    maxHeight: 100,
    padding: 0,
    margin: 0,
    lineHeight: 0,
  },
}));
