import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { IconButton, makeStyles } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { useField } from "react-final-form";
import {
  eventTicketTypes,
  formatCurrencyWithSymbol,
  isPlainObjectWithProperties,
} from "../../lib";
import { ReactComponent as TicketSVG } from "../../images/EventTicket.svg";
import { SelectTickets } from "../eventTickets/SelectTickets";

function _EventTicketSummary(props) {
  const {
    formValues: { eventTickets },
  } = props;
  const classes = styles();

  if (!isPlainObjectWithProperties(eventTickets)) return <></>;
  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>Your tickets</div>

      {Object.keys(eventTickets).map(k => (
        <Ticket key={k} ticket={eventTickets[k]} />
      ))}
    </div>
  );
}

export const EventTicketSummary = connect(state => {
  const {
    campaign,
    donation: { paymentType, donationAmount, donationTotal, eventTicketTotal },
  } = state;
  return {
    campaign,
    paymentType,
    donationAmount,
    donationTotal,
    eventTicketTotal,
  };
})(_EventTicketSummary);

function Ticket({ ticket }) {
  const classes = styles();
  const {
    type,
    optionId,
    qty,
    attendees,
    fullDate,
    event_name,
    label,
    price,
    selectedSeats,
  } = ticket;
  const ticketsField = useField("eventTickets").input;
  const ticketsValue = ticketsField.value;
  const [showEdit, setShowEdit] = useState(false);

  const onDelete = () => {
    const { [`${optionId}`]: _rm, ...rest } = ticketsValue;
    ticketsField.onChange(rest);
  };

  return (
    <div className={classes.row}>
      <div className={classes.rowLeft}>
        <div className={classes.svgWrapper}>
          <TicketSVG />
        </div>
        <div>
          <div className={classes.event}>{event_name}</div>
          <div className={classes.text}>
            {generateName(label, selectedSeats, type)}
          </div>
          <div className={classes.text}>{fullDate}</div>
          {Array.isArray(attendees) &&
            attendees.map((a, index) => {
              if (!a) return <Fragment key={index} />;
              return (
                <div key={index} className={classes.text}>
                  <span className={classes.bold}>Name:</span> {a}
                </div>
              );
            })}
        </div>
      </div>

      <div className={classes.rowRight}>
        <div className={classes.rightFigures}>
          <div className={classes.price}>{formatCurrencyWithSymbol(price)}</div>
          <div className={classes.qty}>x{qty}</div>
        </div>
        <div>
          <div className={classes.icons}>
            <IconButton onClick={() => setShowEdit(true)}>
              <EditIcon className={classes.editIcon} fontSize="small" />
            </IconButton>
            <div className={classes.deleteWrapper}>
              <IconButton onClick={onDelete}>
                <RemoveIcon className={classes.deleteIcon} fontSize="small" />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
      {showEdit && (
        <SelectTickets option={ticket} onClose={() => setShowEdit(false)} />
      )}
    </div>
  );
}

function generateName(label, selectedSeats, type) {
  let txt = `${label}`;
  const isSectionOpen = type === eventTicketTypes.SECTION_OPEN;
  if (isSectionOpen) {
    txt = `${label} flex seat`;
  } else if (isPlainObjectWithProperties(selectedSeats)) {
    const seatsTxt = Object.values(selectedSeats).join(", ");
    txt = `${txt} ${seatsTxt}`;
  }
  return txt;
}

const styles = makeStyles(theme => ({
  wrapper: {
    width: "100%",
    maxWidth: "100%",
    paddingBottom: 32,
  },
  header: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.25,
    textAlign: "center",
    paddingBottom: 34,
  },
  row: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.text.secondary}`,
    paddingBottom: 14,
    marginBottom: 24,
  },
  rowLeft: {
    display: "flex",
    paddingRight: 18,
    [theme.breakpoints.down("sm")]: {
      paddingRight: 12,
    },
  },
  svgWrapper: {
    marginRight: 24,
    [theme.breakpoints.down("sm")]: {
      marginRight: 20,
    },
  },
  event: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.2,
    paddingBottom: 10,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  text: {
    fontSize: 16,
    letterSpacing: 0.2,
    paddingBottom: 8,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  bold: {
    fontWeight: 600,
  },
  rowRight: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingBottom: 10,
  },
  rightFigures: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  price: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.2,
    paddingBottom: 8,
  },
  qty: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.2,
    paddingBottom: 16,
  },
  icons: {
    display: "flex",
    alignItems: "center",
    minWidth: "fit-content",
  },
  editIcon: {
    color: theme.palette.text.primary,
  },
  deleteWrapper: {
    marginRight: -6,
    marginLeft: 6,
  },
  deleteIcon: {
    color: theme.palette.text.primary,
  },
}));
