import { makeStyles } from "@material-ui/core";

export const submissionSuccessStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "360px",
  },
  checkIcon: {
    height: "100px",
    width: "100px",
    paddingBottom: "24px",
  },
  message: {
    fontSize: "18px",
    fontWeight: 600,
    letterSpacing: "0.23px",
    lineHeight: "26px",
    textAlign: "center",
    paddingBottom: "24px",
  },
  name: {
    textTransform: "capitalize",
  },
  button: {
    height: "40px",
    width: "120px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));
