import React, { useEffect, useRef, useState } from "react";
import Confetti from "react-confetti";
import { useMediaQuery } from "@material-ui/core";

export function ConfettiFall() {
  const intervalRef = useRef(null);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(document.body.scrollHeight);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setWidth(window.innerWidth);
      setHeight(document.body.scrollHeight);
    }, 2000);

    return () => clearInterval(intervalRef.current);
  }, []);

  return (
    <Confetti
      width={width}
      height={height}
      tweenDuration={5000}
      numberOfPieces={isMobile ? 200 : 500}
      gravity={0.1}
      initialVelocityX={10}
      initialVelocityY={10}
    />
  );
}
