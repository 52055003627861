// cSpell:ignore appversion, appsource
import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;
/** Axios instance to use for API requests. */
const APIClient = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    "ngrok-skip-browser-warning": true, // for testing when using Ngrok
    appversion: process.env.REACT_APP_VERSION,
    appsource: "mainSite",
  },
});

APIClient.interceptors.response.use(
  success => successCB(success),
  e => errorCB(e),
);

export { APIClient };

function successCB(res) {
  return res && res.data ? { data: res.data } : { data: {} };
}

function errorCB(error) {
  if (!error || !error.response) {
    return {
      error: true,
      errorMessage:
        "A connection error occurred, this may be related to your network/internet/device. Please refresh your page or try again later",
      errorData: {},
    };
  }
  const {
    status,
    data: { code = "", message = "", errorData = {} } = {},
  } = error.response;
  return { error: true, code, errorMessage: message, status, errorData };
}
