import React from "react";
import { IconButton } from "@material-ui/core";
import FacebookIcon from "../../images/Facebook.svg";
import { connect } from "react-redux";
import { getUrls } from "../../state";
import { socialMediaStyles } from "./SocialMedia.styles";
import { AFFILIATE_SUB_CODES, timestamp } from "../../lib";

function _Facebook({ large, siteLink }) {
  const classes = socialMediaStyles();
  const link = encodeURIComponent(
    `${siteLink}&as=${AFFILIATE_SUB_CODES.FACEBOOK}&at=${timestamp()}`,
  );
  const href = `https://www.facebook.com/sharer.php?u=${link}`;

  return (
    <IconButton
      href={href}
      target="_blank"
      className={large ? classes.largeButton : classes.button}
    >
      <img
        src={FacebookIcon}
        alt="facebook"
        className={large ? classes.largeIcon : classes.icon}
      ></img>
    </IconButton>
  );
}

export const Facebook = connect(state => {
  const { fullHomeUrlWithAffiliate } = getUrls(state);
  return { siteLink: fullHomeUrlWithAffiliate };
})(_Facebook);
