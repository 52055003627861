import React, { Fragment, useState } from "react";
import { Button, makeStyles, useMediaQuery } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import TodayIcon from "@material-ui/icons/Today";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { nonEmptyArray } from "../../lib/index.js";
import { EventDetails } from "./EventDetails.jsx";

export function Events({ events }) {
  const classes = styles();

  if (!nonEmptyArray(events)) return <></>;
  return (
    <div className={classes.container}>
      {events.map((event, index) => {
        const isSingleEvent = events.length === 1;
        return (
          <Event key={index} event={event} isSingleEvent={isSingleEvent} />
        );
      })}
    </div>
  );
}

function Event({ event, isSingleEvent }) {
  const {
    dateOnly,
    dayOfWeek,
    time,
    event_name,
    location_address,
    location_city,
    location_name,
    location_state,
    location_zip,
    totalSeats,
    totalSeatsSold,
  } = event;
  const classes = styles();
  const [expanded, setExpanded] = useState(Boolean(isSingleEvent));
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));

  return (
    <div className={classes.eventWrapper}>
      <div className={classes.card}>
        <div className={classes.content}>
          <div className={classes.topRow}>
            <div className={classes.name}>{event_name}</div>
            <div className={classes.soldWrapper}>
              <div className={classes.sold}>
                {totalSeatsSold}/{totalSeats} tickets sold
              </div>
            </div>
          </div>

          {!isMobile && (
            <div className={classes.infoWrapper}>
              <div className={classes.infoSection}>
                <TodayIcon className={classes.icon} />
                <div className={classes.info}>
                  <div className={classes.dayOfWeek}>{dayOfWeek}</div>
                  <div className={classes.data}>{dateOnly}</div>
                  <div className={classes.data}>{time}</div>
                </div>
              </div>

              <div className={classes.infoSection}>
                <LocationOnIcon className={classes.icon} />
                <div className={classes.info}>
                  <div className={classes.data}>{location_name}</div>
                  <div className={classes.data}>{location_address}</div>
                  <div className={classes.data}>
                    {location_city}, {location_state} {location_zip}
                  </div>
                </div>
              </div>
            </div>
          )}

          {isMobile && (
            <Fragment>
              <div className={classes.infoSection}>
                <TodayIcon className={classes.icon} />
                <div className={classes.info}>
                  <div className={classes.dayOfWeek}>{dayOfWeek}</div>
                  <div className={classes.separator}>|</div>
                  <div className={classes.data}>{dateOnly}</div>
                  <div className={classes.separator}>|</div>
                  <div className={classes.data}>{time}</div>
                </div>
              </div>

              <div className={classes.infoSection}>
                <LocationOnIcon className={classes.icon} />
                <div>
                  <div className={classes.data}>{location_name}</div>
                  <div className={classes.data}>
                    {location_address}, {location_city} {location_state}{" "}
                    {location_zip}
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </div>

        {!isSingleEvent && (
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            size="large"
            fullWidth={isMobile}
            endIcon={
              expanded ? (
                <ExpandLessIcon className={classes.buttonIcon} />
              ) : (
                <ExpandMoreIcon className={classes.buttonIcon} />
              )
            }
            onClick={() => setExpanded(prev => !prev)}
          >
            {expanded ? "Hide" : "View"} tickets
          </Button>
        )}
      </div>

      {expanded && <EventDetails event={event} />}
    </div>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "0 16px",
    },
  },
  eventWrapper: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: 500,
      flex: 1,
    },
  },
  card: {
    width: "100%",
    maxWidth: "100%",
    marginBottom: 16,
    borderRadius: 8,
    border: "solid 1px rgba(0, 0, 0, 0.31)",
    padding: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: 16,
    },
  },
  content: {
    width: "100%",
    maxWidth: "100%",
  },
  topRow: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginBottom: 24,
    marginRight: 16,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginRight: 0,
    },
  },
  name: {
    fontSize: 20,
    lineHeight: "24px",
    fontWeight: 600,
    letterSpacing: 0.25,
    marginRight: 24,
    overflowWrap: "anywhere",
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      lineHeight: "32px",
      marginBottom: 8,
    },
  },
  soldWrapper: {
    minWidth: "fit-content",
  },
  sold: {
    width: "fit-content",
    minWidth: "fit-content",
    height: 24,
    borderRadius: 14,
    backgroundColor: "#f5f5f5",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.18,
    color: "#5e5e5e",
    padding: "0 8px",
  },
  infoWrapper: {
    display: "flex",
  },
  icon: {
    marginRight: 16,
  },
  infoSection: {
    display: "flex",
    marginRight: 48,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      marginBottom: 20,
    },
  },
  info: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
    },
  },
  separator: {
    marginLeft: 10,
    marginRight: 10,
  },
  dayOfWeek: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.25,
    lineHeight: "24px",
  },
  data: {
    fontSize: 16,
    lineHeight: "24px",
    letterSpacing: 0.2,
  },
  button: {
    height: 56,
    width: 200,
    minWidth: 200,
    borderRadius: 28,
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minWidth: "100%",
      marginTop: 4,
    },
  },
  buttonIcon: {
    fontSize: "26px !important",
  },
}));
