const months = [];
for (let i = 1; i < 13; i++) {
  let str = i.toString();
  if (str.length < 2) str = `0${str}`;
  months.push({ id: str, name: str });
}

const years = [];
const currYear = new Date().getFullYear();
for (let i = currYear; i < currYear + 21; i++) {
  const iStr = i.toString();
  years.push({ id: iStr.slice(2), name: i });
}

const getPaymentTypeOptions = ({
  forAdmin,
  allowAdminPledges,
  allowFrontendPledges,
}) => {
  let paymentTypes;
  if (forAdmin) {
    paymentTypes = [
      { id: "cc", name: "Credit Card" },
      { id: "check", name: "Check" },
      { id: "cash", name: "Cash" },
    ];
    if (allowAdminPledges || allowFrontendPledges) {
      paymentTypes.push({ id: "pledge", name: "Pledge" });
    }
  } else {
    paymentTypes = [
      { id: "cc", name: "Credit Card" },
      { id: "pledge", name: "Pledge" },
    ];
  }

  return paymentTypes;
};

const nameTitles = [
  "Mr. & Mrs.",
  "Mr.",
  "Mrs.",
  "Miss",
  "Ms.",
  "Dr.",
  "Dr. & Mrs.",
  "Mr. & Dr.",
  "Dr. & Dr.",
  "Rabbi",
  "Rabbi & Rebbetzin",
  "Rabbi & Mrs.",
  "N/A",
];

const stateOptions = [
  { name: "Alabama", code: "AL" },
  { name: "Alaska", code: "AK" },
  { name: "Arizona", code: "AZ" },
  { name: "Arkansas", code: "AR" },
  { name: "California", code: "CA" },
  { name: "Colorado", code: "CO" },
  { name: "Connecticut", code: "CT" },
  { name: "Delaware", code: "DE" },
  { name: "District of Columbia", code: "DC" },
  { name: "Florida", code: "FL" },
  { name: "Georgia", code: "GA" },
  { name: "Hawaii", code: "HI" },
  { name: "Idaho", code: "ID" },
  { name: "Illinois", code: "IL" },
  { name: "Indiana", code: "IN" },
  { name: "Iowa", code: "IA" },
  { name: "Kansas", code: "KS" },
  { name: "Kentucky", code: "KY" },
  { name: "Louisiana", code: "LA" },
  { name: "Maine", code: "ME" },
  { name: "Maryland", code: "MD" },
  { name: "Massachusetts", code: "MA" },
  { name: "Michigan", code: "MI" },
  { name: "Minnesota", code: "MN" },
  { name: "Mississippi", code: "MS" },
  { name: "Missouri", code: "MO" },
  { name: "Montana", code: "MT" },
  { name: "Nebraska", code: "NE" },
  { name: "Nevada", code: "NV" },
  { name: "New Hampshire", code: "NH" },
  { name: "New Jersey", code: "NJ" },
  { name: "New Mexico", code: "NM" },
  { name: "New York", code: "NY" },
  { name: "North Carolina", code: "NC" },
  { name: "North Dakota", code: "ND" },
  { name: "Ohio", code: "OH" },
  { name: "Oklahoma", code: "OK" },
  { name: "Oregon", code: "OR" },
  { name: "Pennsylvania", code: "PA" },
  { name: "Rhode Island", code: "RI" },
  { name: "South Carolina", code: "SC" },
  { name: "South Dakota", code: "SD" },
  { name: "Tennessee", code: "TN" },
  { name: "Texas", code: "TX" },
  { name: "Utah", code: "UT" },
  { name: "Vermont", code: "VT" },
  { name: "Virginia", code: "VA" },
  { name: "Washington", code: "WA" },
  { name: "West Virginia", code: "WV" },
  { name: "Wisconsin", code: "WI" },
  { name: "Wyoming", code: "WY" },
];

export const seatQtyOptions = [];

for (let i = 1; i < 21; i++) {
  let seatName = "";
  if (i === 1) seatName = `${i} Ticket`;
  else seatName = `${i} Tickets`;
  seatQtyOptions.push({ name: seatName, value: i });
}

export { months, years, getPaymentTypeOptions, nameTitles, stateOptions };
