import { APIClient } from "../../lib";
import { uiTypes } from "..";

const teamTypes = {
  TEAMS_SUCCESS: "TEAMS_SUCCESS",
  TEAMS_CLEAR: "TEAMS_CLEAR",
};

const teamActions = {
  getTeams(
    campaignId,
    campaign_type_id,
    profit_based,
    offset,
    limit,
    sort,
    search,
  ) {
    return async (dispatch, _getState) => {
      dispatch({ type: uiTypes.UI_LOADING });
      const url = `/campaigns/${campaignId}/teams?campaign_type_id=${campaign_type_id}&profit_based=${profit_based}&offset=${offset}&limit=${limit}&_sort=${sort}&q=${search}`;
      const response = await APIClient.get(url);
      const { data, errorMessage } = response;

      if (data) {
        dispatch({
          type: teamTypes.TEAMS_SUCCESS,
          ...data,
          refresh: offset === 0,
        });
      }
      if (errorMessage) {
        dispatch({ type: uiTypes.UI_DATA_FETCH_ERROR, errorMessage });
      }

      dispatch({ type: uiTypes.UI_LOADING_RESET });
    };
  },
  clearTeams() {
    return { type: teamTypes.TEAMS_CLEAR };
  },
  getTeamAutocompleteList(campaignId) {
    return async () => {
      const response = await APIClient.get(
        `/team_campaigns/autocomplete?campaign_id=${campaignId}`,
      );
      const { data, error, errorMessage } = response;
      if (error) {
        console.error(errorMessage);
        // for now not showing error
        return [];
      }
      return data;
    };
  },
};

export { teamTypes, teamActions };
