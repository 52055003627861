import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Container, useMediaQuery } from "@material-ui/core";
import {
  Facebook,
  Twitter,
  Linkedin,
  Whatsapp,
  IMessage,
  Email,
  ThankyouSVG,
  ThankyouMobileSVG,
  ProductCart,
  OrderSummaryAndActions,
} from "..";
import { formatCurrencyDisplay, isProductCampaign } from "../../lib";
import { donationConfirmationStyles } from "./DonationConfirmation.styles";

export function _DonationConfirmation(props) {
  const { donationTotal, campaign_type_id, hasEvents } = props;
  const isProduct = isProductCampaign(campaign_type_id);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const isLgScreen = useMediaQuery(theme => theme.breakpoints.up("lg"));
  const [twoColLayout, setTwoColLayout] = useState(null);
  const classes = donationConfirmationStyles({ twoColLayout });

  useEffect(() => {
    if (isLgScreen && isProduct) setTwoColLayout(true);
    else setTwoColLayout(false);
  }, [isLgScreen, isProduct]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      // // scrolling is disabled on payment form because of iFrames; sometimes we need this timeout
      // window.onscroll = function() {};
      window.scrollTo(0, 0);
    }, 500);
  }, []);

  if (twoColLayout === null) return <></>; // To avoid flickering
  if (twoColLayout) {
    return (
      <div className={classes.wrapper}>
        <Container>
          <div className={classes.twoColContainer}>
            <div className={classes.thankyouCol}>
              <ThankyouSVG />
              <div id="donation-success" className={classes.title}>
                Thank you for your order
              </div>
              <div className={classes.message}>
                A confirmation email will be sent to you as your receipt.
              </div>
              <SpreadTheWord />
            </div>
            {isProduct && (
              <div>
                <ProductCart forConfirmation />
                <OrderSummaryAndActions forConfirmation />
              </div>
            )}
          </div>
        </Container>
      </div>
    );
  }
  return (
    <div className={classes.wrapper}>
      {isMobile ? <ThankyouMobileSVG /> : <ThankyouSVG />}
      <div id="donation-success" className={classes.title}>
        Thank you for{" "}
        {isProduct ? "your order" : hasEvents ? "your support" : "donating"}
      </div>
      {!isProduct && !hasEvents && (
        <div className={classes.message}>
          {`Your generous donation of 
          $${formatCurrencyDisplay(donationTotal)} 
          has been submitted.`}
        </div>
      )}

      <div className={classes.message}>
        {hasEvents &&
          "A confirmation receipt email will be sent to you with your tickets included."}
        {!hasEvents &&
          "A confirmation email will be sent to you as your receipt."}
      </div>

      <SpreadTheWord />

      {isProduct && (
        <div className={classes.orderSummary}>
          <ProductCart forConfirmation={true} />
          <OrderSummaryAndActions forConfirmation />
        </div>
      )}
    </div>
  );
}

function SpreadTheWord() {
  const classes = donationConfirmationStyles();
  return (
    <Fragment>
      <div className={classes.spreadThewWord}>Spread the word</div>
      <div>
        <Facebook large={true} />
        <Twitter large={true} />
        <Linkedin large={true} />
        <Whatsapp large={true} />
        <Email large={true} />
        <IMessage large={true} />
      </div>
    </Fragment>
  );
}

export const DonationConfirmation = connect(state => {
  const {
    donation: { donationTotal },
    campaign: { campaign_type_id, hasEvents },
  } = state;
  return { donationTotal, campaign_type_id, hasEvents };
})(_DonationConfirmation);
