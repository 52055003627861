import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import EmptyCart from "../../images/EmptyCart.svg";
import { CartProductCard } from "./CartProductCard";

function _ProductCart(props) {
  const {
    forConfirmation,
    productInfo: { selectedProducts, emptyCart },
  } = props;
  const classes = styles({ forConfirmation });
  const [orderProductIds, setOrderProductIds] = useState([]);

  useEffect(() => {
    const productIds = Object.keys(selectedProducts);
    setOrderProductIds(productIds);
  }, [selectedProducts]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.hashLink} id="cart"></div>
      <div className={classes.container}>
        <div className={classes.header}>
          {forConfirmation ? "Order details" : "Cart"}
        </div>

        {orderProductIds.length > 0 && (
          <div>
            {orderProductIds.map(productId => {
              const quantity = selectedProducts[productId];
              return (
                <CartProductCard
                  key={productId}
                  productInfo={selectedProducts[productId]}
                  quantity={quantity}
                  forConfirmation={forConfirmation}
                />
              );
            })}
          </div>
        )}

        {emptyCart && (
          <div className={classes.emptyCart}>
            <img src={EmptyCart} alt="empty cart" className={classes.cartSvg} />
            <div className={classes.emptyTxt}>
              There are no items in your cart
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export const ProductCart = connect(state => {
  return { productInfo: state.product };
})(_ProductCart);

const styles = makeStyles(theme => ({
  wrapper: {
    width: 360,
    maxWidth: "100%",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100vw",
      width: 732,
      paddingRight: "16px",
      paddingLeft: "16px",
    },
  },
  hashLink: {
    height: 1,
    visibility: "hidden",
    position: "absolute",
    marginTop: -80,
    [theme.breakpoints.down("sm")]: {
      marginTop: -56,
    },
  },
  container: {
    width: "100%",
    maxWidth: "100%",
    paddingTop: 0,
    [theme.breakpoints.down("md")]: {
      paddingTop: 32,
    },
  },
  header: {
    fontSize: 32,
    fontWeight: 600,
    letterSpacing: 0.4,
    paddingBottom: 32,
    textAlign: () => "left",
    [theme.breakpoints.only("md")]: {
      textAlign: ({ forConfirmation }) => (forConfirmation ? "center" : "left"),
    },
    [theme.breakpoints.down("md")]: {
      textAlign: () => "center",
    },
  },
  emptyCart: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  cartSvg: {
    width: "fit-content",
    height: "fit-content",
  },
  emptyTxt: {
    fontSize: 20,
    letterSpacing: 0.25,
    marginTop: 24,
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
      letterSpacing: 0.2,
    },
  },
}));
