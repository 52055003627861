import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import SchoolIcon from "@material-ui/icons/LocationCity";
import { useField } from "react-final-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import classnames from "classnames";
import { productActions } from "../../state";
import { hexToRGBA } from "../../lib";
import { SwitchDeliveryWarningDialog } from "./SwitchDeliveryWarningDialog";

/*
- The default shipping method is set with the `initialValues` on the form
- It is stored in redux (and updated) with this component
*/

function _ProductDeliveryOptions(props) {
  const {
    shippingOptions,
    selectedProducts,
    ship_to_school_label = "",
    ship_to_school_delivery_message = "",
    formValues: { ship_to_school },
    actions: { setIsShipToSchool },
  } = props;
  const classes = styles();
  const [hasConflicts, setHasConflicts] = useState(false);
  const [showConflictWarning, seShowConflictWarning] = useState(false);
  const stsInput = useField("ship_to_school").input;
  const shippingSABInput = useField("shipping_same_as_billing").input;

  useEffect(() => {
    if (shippingOptions === "school") {
      setIsShipToSchool(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingOptions]);

  useEffect(() => {
    const isSTS = Boolean(ship_to_school);
    setIsShipToSchool(isSTS);
    if (isSTS) shippingSABInput.onChange(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ship_to_school]);

  useEffect(() => {
    const changeToSTS = !ship_to_school;
    const _hasConflicts = Object.keys(selectedProducts).some(id => {
      const {
        ship_to_school_only: STS,
        ship_to_home_only: STH,
      } = selectedProducts[id].product;
      if ((changeToSTS && STH) || (!changeToSTS && STS)) {
        return true;
      }
    });
    setHasConflicts(_hasConflicts);
  }, [selectedProducts, ship_to_school]);

  const handleChange = isShipToSchool => {
    if (hasConflicts) return seShowConflictWarning(true);
    stsInput.onChange(isShipToSchool);
    setIsShipToSchool(isShipToSchool);
  };

  const closeDeliverySwitchWarning = () => seShowConflictWarning(false);

  if (shippingOptions !== "both") return <></>;
  return (
    <div className={classes.container}>
      <div className={classes.title}>
        How would you like to ship your order?
      </div>
      <div className={classes.subTitle}>
        Your selection may affect the availability of some items
      </div>
      <div className={classes.options}>
        <div
          className={classnames(
            classes.option,
            classes.leftOption,
            !ship_to_school && classes.selectedOption,
          )}
          onClick={!ship_to_school ? undefined : () => handleChange(false)}
        >
          <div className={classes.optionTopRow}>
            <div>
              <HomeIcon className={classes.icon} />
            </div>
            <div>Ship to home</div>
          </div>
          <div className={classes.optionTxt}>
            Shipping fees will be applied at checkout
          </div>
        </div>

        <div
          className={classnames(
            classes.option,
            classes.rightOption,
            ship_to_school && classes.selectedOption,
          )}
          onClick={ship_to_school ? undefined : () => handleChange(true)}
        >
          <div className={classes.optionTopRow}>
            <div>
              <SchoolIcon className={classes.icon} />
            </div>
            <div>Ship to {ship_to_school_label}</div>
          </div>
          <div className={classes.optionTxt}>
            {ship_to_school_delivery_message}
          </div>
        </div>
      </div>

      {showConflictWarning && (
        <SwitchDeliveryWarningDialog closeDialog={closeDeliverySwitchWarning} />
      )}
    </div>
  );
}

export const ProductDeliveryOptions = connect(
  state => {
    const {
      campaign: { ship_to_school_label, ship_to_school_delivery_message },
      product: { shipping_options, selectedProducts },
    } = state;
    return {
      shippingOptions: shipping_options,
      selectedProducts,
      ship_to_school_label,
      ship_to_school_delivery_message,
    };
  },
  dispatch => {
    return { actions: bindActionCreators(productActions, dispatch) };
  },
)(_ProductDeliveryOptions);

const styles = makeStyles(theme => ({
  container: {
    width: 860,
    maxWidth: "100%",
    border: `1px solid ${hexToRGBA(theme.palette.text.primary, 0.31)}`,
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 24,
    padding: "24px 16px",
    [theme.breakpoints.down("sm")]: {
      padding: "18px 16px",
      maxWidth: "calc(100% - 32px)",
      marginLeft: 16,
      marginRight: 16,
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: 0,
    paddingBottom: 8,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  subTitle: {
    fontSize: 16,
    paddingBottom: 24,
    letterSpacing: 0.2,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      letterSpacing: 0,
    },
  },
  options: {
    display: "flex",
    justifyContent: "center",
  },
  option: {
    padding: 24,
    minHeight: 120,
    width: "49%",
    border: `1px solid ${hexToRGBA(theme.palette.text.primary, 0.31)}`,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: hexToRGBA(theme.palette.primary.main, 0.06),
    },
    [theme.breakpoints.down("sm")]: {
      padding: 16,
    },
  },
  leftOption: {
    borderRadius: "4px 0 0 4px",
  },
  rightOption: {
    borderRadius: "0 4px 4px 0",
  },
  selectedOption: {
    border: `3px solid ${theme.palette.primary.main}`,
  },
  optionTopRow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.25,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      fontSize: 16,
      letterSpacing: 0.2,
    },
  },
  icon: {
    marginRight: 4,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  optionTxt: {
    paddingTop: 8,
    fontSize: 14,
    lineHeight: "18px",
    letterSpacing: 0.17,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
      lineHeight: "15px",
      letterSpacing: 0.13,
    },
  },
}));
