import React, { useState, useEffect, Fragment } from "react";
import { makeStyles, Button, useMediaQuery, Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import OpenIcon from "@material-ui/icons/ShuffleRounded";
import ReservedIcon from "@material-ui/icons/EventSeatRounded";
import { useField } from "react-final-form";
import {
  eventTicketTypes,
  formatCurrencyWithSymbol,
  hexToRGBA,
  isPlainObjectWithProperties,
} from "../../lib/index.js";
import { SelectTickets } from "./SelectTickets.jsx";
import classNames from "classnames";
import { SeatingMap } from "./SeatingMap.jsx";

export function EventDetails({ event }) {
  const classes = styles();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const [showMap, setShowMap] = useState(false);
  const {
    ticketOptions = [],
    event_name,
    map_image,
    fullDate,
    totalAssignedSeatSold,
    totalOpenSeatsSold,
    eventSoldOut,
    max_tickets_per_sale,
    require_name_on_ticket,
    ticket_purchase_message,
  } = event;
  const eventDetails = {
    event_name,
    fullDate,
    eventSoldOut,
    max_tickets_per_sale,
    require_name_on_ticket,
  };

  return (
    <Fragment>
      <div className={classes.container}>
        <div className={classes.infoWrapper}>
          {!isMobile && (
            <Fragment>
              <div className={classes.info}>
                <div className={classes.leftInfo}>
                  <div className={classes.subheader}>
                    Select a ticket option
                  </div>
                  <div className={classes.reserved}>
                    {totalAssignedSeatSold} Reserved seats sold
                  </div>
                  <div className={classes.flex}>
                    {totalOpenSeatsSold} Open seats sold
                  </div>
                </div>
                {map_image && (
                  <div
                    className={classes.viewMap}
                    onClick={() => setShowMap(true)}
                  >
                    View seating map
                  </div>
                )}
              </div>

              {ticket_purchase_message && (
                <div className={classes.msg}>{ticket_purchase_message}</div>
              )}
            </Fragment>
          )}

          {isMobile && (
            <Fragment>
              <div className={classes.subheader}>Select a ticket option</div>
              <div className={classes.leftInfo}>
                <div className={classes.reserved}>
                  {totalAssignedSeatSold} Reserved seats sold
                </div>
                <div className={classes.flex}>
                  {totalOpenSeatsSold} Open seats sold
                </div>
              </div>

              {ticket_purchase_message && (
                <div className={classes.msg}>{ticket_purchase_message}</div>
              )}
              {map_image && (
                <div
                  className={classes.viewMap}
                  onClick={() => setShowMap(true)}
                >
                  View seating map
                </div>
              )}
            </Fragment>
          )}
        </div>

        <div className={classes.cardContainer}>
          {ticketOptions.map((option, index) => {
            return (
              <Card key={index} option={option} eventDetails={eventDetails} />
            );
          })}
        </div>
      </div>

      {showMap && map_image && (
        <SeatingMap map_image={map_image} onClose={() => setShowMap(false)} />
      )}
    </Fragment>
  );
}

function Card({ option, eventDetails }) {
  const { eventSoldOut } = eventDetails;
  const classes = styles();
  const ticketsField = useField("eventTickets").input;
  const ticketsValue = ticketsField.value;
  const [showDialog, setShowDialog] = useState(false);
  const [tooltip, setTooltip] = useState("");
  const { optionId, label, type, price, seatCount, soldSeats } = option;
  const selected = Boolean(ticketsValue[optionId]);
  const { qty = 0, selectedSeats } = ticketsValue[optionId] || {};
  const isOpenVenue = type === eventTicketTypes.VENUE_OPEN;
  const isOpenSection = type === eventTicketTypes.SECTION_OPEN;
  const isAssign = type === eventTicketTypes.ASSIGNED;
  const soldOut = Boolean(eventSoldOut || soldSeats >= seatCount);

  useEffect(() => {
    let _tooltip = "";
    if (type === eventTicketTypes.VENUE_OPEN) {
      _tooltip =
        "Enjoy the freedom to sit anywhere in the stadium that is not reserved. Seating is first-come, first-serve across all sections.";
    } else if (type === eventTicketTypes.SECTION_OPEN) {
      _tooltip =
        "Choose any seat within your designated section that is not reserved. Seating is first-come, first-serve.";
    } else
      _tooltip =
        "Select and reserve your exact seat during booking for a guaranteed spot.";

    setTooltip(_tooltip);
  }, [type]);

  return (
    <div
      className={classNames(
        classes.card,
        selected && classes.selectedCard,
        soldOut && classes.soldOutCard,
      )}
    >
      <div>
        <div className={classes.soldChip}>
          {soldSeats}/{seatCount} sold
        </div>
        <div className={classes.cardName}>{label}</div>

        <div className={classes.tagWrapper}>
          <div
            className={classNames(
              classes.tag,
              isAssign && classes.assignBGColor,
            )}
          >
            {!isAssign && <OpenIcon className={classes.typeIcon} />}
            {isAssign && (
              <ReservedIcon
                className={classNames(classes.typeIcon, classes.assignColor)}
              />
            )}
            <div
              className={classNames(
                classes.tagText,
                isAssign && classes.assignColor,
              )}
            >
              {isOpenVenue
                ? "Open seat (anywhere)"
                : isOpenSection
                ? "Open seat (section)"
                : "Reserved seat"}
            </div>

            <Tooltip
              title={tooltip}
              disableFocusListener
              classes={{
                tooltip: classes.tooltipContainer,
                arrow: classes.tooltipArrow,
                tooltipPlacementBottom: classes.tooltip,
              }}
            >
              <InfoIcon
                className={classNames(
                  classes.tagIcon,
                  isAssign && classes.assignColor,
                )}
              />
            </Tooltip>
          </div>
        </div>
      </div>

      <div className={classes.bottom}>
        <div className={classes.price}>{formatCurrencyWithSymbol(price)}</div>
        {selected && (
          <div className={classes.added}>
            {generateAddedTxt(qty, selectedSeats)}
          </div>
        )}
        {!soldOut && (
          <Button
            color="secondary"
            variant="contained"
            fullWidth
            onClick={() => setShowDialog(true)}
            className={selected ? classes.selectedButton : ""}
          >
            {selected ? "Manage tickets" : "Select"}
          </Button>
        )}
        {soldOut && <div className={classes.soldOut}>SOLD OUT</div>}
      </div>
      {showDialog && (
        <SelectTickets
          option={{ ...option, ...eventDetails }}
          onClose={() => setShowDialog(false)}
        />
      )}
    </div>
  );
}

function generateAddedTxt(qty, selectedSeats) {
  let txt = `${qty} added`;
  if (isPlainObjectWithProperties(selectedSeats)) {
    const seatsTxt = Object.values(selectedSeats).join(", ");
    txt = `${txt} ${seatsTxt}`;
  }
  return txt;
}

const styles = makeStyles(theme => ({
  container: {
    backgroundColor: "#f5f5f5",
    borderRadius: 8,
    padding: 24,
    paddingBottom: 0,
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  infoWrapper: {
    [theme.breakpoints.down("sm")]: {
      padding: "16px 12px 0 12px",
    },
  },
  info: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  leftInfo: {
    display: "flex",
  },
  subheader: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.25,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 12,
    },
  },
  msg: {
    fontSize: 16,
    lineHeight: "20px",
    letterSpacing: 0.2,
    paddingTop: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      lineHeight: "18px",
    },
  },
  reserved: {
    fontSize: 13,
    letterSpacing: 0.15,
    color: "#466e50",
    padding: "3px 12px",
    borderRadius: 16,
    backgroundColor: "#effcf0",
    width: "fit-content",
    height: "fit-content",
    marginLeft: 16,
    marginRight: 8,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      fontSize: 11,
    },
  },
  flex: {
    fontSize: 13,
    letterSpacing: 0.15,
    color: "#40687f",
    padding: "3px 12px",
    borderRadius: 16,
    backgroundColor: "#e5f5ff",
    width: "fit-content",
    height: "fit-content",
    [theme.breakpoints.down("sm")]: {
      fontSize: 11,
    },
  },
  viewMap: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.secondary.main,
    textDecoration: "underline",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      marginTop: 16,
    },
  },
  cardContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    margin: "32px 0 0 -24px",
    width: "calc(100% + 24px)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: 0,
      padding: "32px 6px 0 6px",
    },
  },
  card: {
    width: 254,
    marginLeft: 24,
    marginBottom: 24,
    borderRadius: 8,
    border: "solid 1px #979797",
    padding: "8px 24px 24px 24px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      width: 152,
      marginLeft: 6,
      marginBottom: 16,
      marginRight: 6,
      padding: "6px 12px 12px 12px",
    },
  },
  selectedCard: {
    border: `3px solid ${theme.palette.secondary.main}`,
    backgroundColor: hexToRGBA(theme.palette.secondary.main, 0.1),
  },
  soldOutCard: {
    opacity: 0.5,
  },
  soldChip: {
    padding: "6px 8px",
    borderRadius: 14,
    backgroundColor: "#e3e3e3",
    color: "#5e5e5e",
    fontSize: 14,
    letterSpacing: 0.18,
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    marginLeft: -16,
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      marginLeft: -5,
    },
  },
  cardName: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.25,
    textAlign: "center",
    color: theme.palette.primary.main,
    marginTop: 16,
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      lineHeight: "19px",
      letterSpacing: 0.2,
    },
  },
  price: {
    textAlign: "center",
    fontSize: 32,
    fontWeight: 600,
    letterSpacing: 0.4,
    marginBottom: 8,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 6,
    },
  },
  tagWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: -9,
    marginRight: -9,
  },
  tag: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "3px 8px",
    borderRadius: 16,
    backgroundColor: "#e5f5ff",
    width: "fit-content",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "3px 4px",
    },
  },
  typeIcon: {
    fontSize: 17,
    marginRight: 6,
    color: "#40687f",
    [theme.breakpoints.down("sm")]: {
      marginRight: 3,
      fontSize: 14,
    },
  },
  tagIcon: {
    fontSize: 17,
    marginLeft: 5,
    color: "#40687f",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 3,
      fontSize: 14,
    },
  },
  tagText: {
    fontSize: 13,
    fontWeight: 500,
    color: "#40687f",
    [theme.breakpoints.down("sm")]: {
      fontSize: 9,
    },
  },
  assignColor: {
    color: "#466e50",
  },
  assignBGColor: {
    backgroundColor: "#effcf0",
  },
  tooltipArrow: {
    display: "none",
  },
  tooltip: {
    marginTop: "4px !important",
  },
  tooltipContainer: {
    backgroundColor: "#B0B0B0",
    width: 180,
    color: "#FFFFFF",
    fontSize: 12,
    lineHeight: 1.25,
    letterSpacing: 0.15,

    borderRadius: 4,
    padding: 6,
  },
  selectedButton: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  soldOut: {
    width: "100%",
    textAlign: "center",
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 2,
    height: 36.5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  bottom: {
    minHeight: 96,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginTop: 26,
    [theme.breakpoints.down("sm")]: {
      marginTop: 18,
      minHeight: 86,
    },
  },
  added: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.29,
    letterSpacing: 0.18,
    textAlign: "center",
    color: theme.palette.secondary.main,
    marginBottom: 6,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
    letterSpacing: 0.15,
    marginBottom: 4,
  },
  // soldOutRibbon: {
  //   position: "absolute",
  //   top: 23,
  //   left: -50,
  //   width: 202,
  //   transform: "rotate(-35deg)",
  //   backgroundColor: "#000000",
  //   color: "#FFFFFF",
  //   height: 35,
  //   display: "flex",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   fontWeight: 600,
  //   textTransform: "uppercase",
  //   fontSize: 16,
  //   letterSpacing: 2,
  //   textAlign: "center",
  //   lineHeight: 1,
  //   zIndex: 1,
  //   [theme.breakpoints.down("sm")]: {
  //     fontSize: 12,
  //     letterSpacing: 1.5,
  //     right: -55,
  //     top: 12,
  //     transform: "rotate(30deg)",
  //     width: 188,
  //     left: "unset",
  //     height: 30,
  //   },
  // },
}));
