import React, { useEffect, useState, Fragment, useRef } from "react";
import {
  makeStyles,
  Button,
  Dialog,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import { AuthorizeDotNet, Cardknox, Stripe, UsaEpay, Banquest } from "..";
import { gatewayProviderIds, isProductCampaign } from "../../lib";

function _PaymentDialog(props) {
  const {
    showPaymentForm,
    setShowPaymentForm,
    gatewayProviderId,
    campaign_type_id,
    disableRetry,
    // donationSuccess,
    submitting,
  } = props;
  const classes = styles();
  const [isStripe, setIsStripe] = useState(false);
  const [isAuthorizeDN, setIsAuthorizeDN] = useState(false);
  const [isUsaEpay, setIsUsaEpay] = useState(false);
  const [isCardknox, setIsCardknox] = useState(false);
  const [isBanquest, setIsBanquest] = useState(false);
  const [fetchingTokens, setFetchingTokens] = useState(false);
  const onSubmitRef = useRef(() => null);
  const isProduct = isProductCampaign(campaign_type_id);

  useEffect(() => {
    switch (gatewayProviderId) {
      case gatewayProviderIds.AUTHORIZE_DOT_NET:
        setIsAuthorizeDN(true);
        break;
      case gatewayProviderIds.STRIPE:
        setIsStripe(true);
        break;
      case gatewayProviderIds.USA_EPAY:
        setIsUsaEpay(true);
        break;
      case gatewayProviderIds.CARDKNOX:
        setIsCardknox(true);
        break;
      case gatewayProviderIds.BANQUEST:
        setIsBanquest(true);
        break;
      default:
    }
  }, [gatewayProviderId]);

  useEffect(() => {
    if (disableRetry) closePaymentDialog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableRetry]);

  const closePaymentDialog = () => {
    setShowPaymentForm(false);
  };

  return (
    <Dialog
      disableEnforceFocus
      className={classes.modal}
      keepMounted
      open={showPaymentForm}
      PaperProps={{ classes: { root: classes.paper } }}
      maxWidth={false}
    >
      <Fragment>
        {isAuthorizeDN && (
          <AuthorizeDotNet
            closePaymentDialog={closePaymentDialog}
            showPaymentForm={showPaymentForm}
          />
        )}

        {(isCardknox || isStripe || isUsaEpay || isBanquest) && (
          <div className={classes.content}>
            <div className={classes.closeWrapper}>
              <IconButton key="close" onClick={closePaymentDialog} edge="end">
                <CloseIcon />
              </IconButton>
            </div>

            <div className={classes.header}>Enter your payment information</div>

            {isCardknox && (
              <Cardknox
                onSubmitRef={onSubmitRef}
                setFetchingTokens={setFetchingTokens}
                showPaymentForm={showPaymentForm}
              />
            )}

            {isStripe && (
              <Stripe
                onSubmitRef={onSubmitRef}
                setFetchingTokens={setFetchingTokens}
              />
            )}

            {isUsaEpay && (
              <UsaEpay
                onSubmitRef={onSubmitRef}
                setFetchingTokens={setFetchingTokens}
              />
            )}

            {isBanquest && (
              <Banquest
                onSubmitRef={onSubmitRef}
                setFetchingTokens={setFetchingTokens}
                showPaymentForm={showPaymentForm}
                closePaymentDialog={closePaymentDialog}
              />
            )}

            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => onSubmitRef.current()}
              className={classes.submit}
              classes={{ disabled: classes.disabledBtn }}
              disabled={fetchingTokens || submitting}
            >
              <Fragment>
                {fetchingTokens || submitting
                  ? "Submitting..."
                  : isProduct
                  ? "Place order"
                  : "Submit"}
                {(fetchingTokens || submitting) && (
                  <CircularProgress size={40} className={classes.loader} />
                )}
              </Fragment>
            </Button>
          </div>
        )}
      </Fragment>
    </Dialog>
  );
}

export const PaymentDialog = connect(state => {
  const {
    campaign: { gatewayProviderId, campaign_type_id },
    donation: { disableRetry, donationSuccess },
  } = state;
  return {
    gatewayProviderId,
    campaign_type_id,
    donationSuccess,
    disableRetry,
  };
})(_PaymentDialog);

const styles = makeStyles(theme => ({
  modal: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden",
  },
  paper: { margin: 0, maxWidth: "unset" },
  content: {
    width: 600,
    maxWidth: "100%",
    padding: 32,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      padding: "32px 16px",
    },
  },
  closeWrapper: {
    position: "absolute",
    top: 22,
    right: 22,
  },
  header: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0,
    marginBottom: 32,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  submit: {
    height: 56,
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.25,
    borderRadius: 28,
  },
  disabledBtn: {
    opacity: "0.5 !important",
    color: `${theme.palette.primary.contrastText} !important`,
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  loader: {
    position: "absolute",
    color: theme.palette.secondary.contrastText,
  },
}));
