import React, { useEffect, useState, Fragment } from "react";
import { Header } from "./Header";
import { Footer } from "./Footer";
import queryString from "query-string";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { makeStyles } from "@material-ui/core";
import { campaignActions, productActions } from "../state";
import { Toast, MetaTags, SubmissionDialog } from "../components";
import { isProductCampaign } from "../lib";

function _Layout(props) {
  const {
    noLayout,
    campaignId,
    campaign_type_id,
    showProfitProgress,
    showVolunteerItemProgress,
    hide_stats_component,
    volunteerId,
    teamId,
    campaign_donation_group_id,
    affiliateContactId,
    has_showcase,
    children,
    notFound,
    errorMessage,
    location,
    match: {
      params: {
        orgSlug,
        campaignSlug,
        volunteerSlug,
        teamSlug,
        volunteerCampaignId,
        hashedVolunteer,
      },
    },
    productActions: { getCampaignProducts },
    campaignActions: {
      getWebpageInfo,
      getProgressStats,
      getCampaignDonationGroupDisplayData,
      getShowcaseDisplayData,
    },
    history,
  } = props;
  const { iframe } = queryString.parse(location.search);
  const [toast, setToast] = useState(null);
  const [showLayout, setShowLayout] = useState(null);
  const isProduct = isProductCampaign(campaign_type_id);

  useEffect(() => {
    if ((orgSlug && campaignSlug) || volunteerCampaignId || hashedVolunteer) {
      getWebpageInfo(
        orgSlug,
        campaignSlug,
        volunteerSlug,
        teamSlug,
        volunteerCampaignId,
        hashedVolunteer,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (campaignId && isProduct) {
      getCampaignProducts(campaignId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, isProduct]);

  useEffect(() => {
    if (campaignId && !hide_stats_component && campaign_type_id) {
      const _volunteerId = Boolean(
        volunteerSlug || volunteerCampaignId || hashedVolunteer,
      )
        ? volunteerId
        : undefined;
      const _teamId = Boolean(teamSlug) ? teamId : undefined;
      getProgressStats(
        campaignId,
        campaign_type_id,
        Boolean(showProfitProgress),
        _volunteerId,
        _teamId,
        showVolunteerItemProgress,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    campaignId,
    campaign_type_id,
    hide_stats_component,
    showProfitProgress,
    showVolunteerItemProgress,
    hide_stats_component,
  ]);

  useEffect(() => {
    if (campaignId && campaign_donation_group_id) {
      getCampaignDonationGroupDisplayData(
        campaign_donation_group_id,
        volunteerId,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, campaign_donation_group_id, volunteerId]);

  useEffect(() => {
    if (campaignId && has_showcase) {
      getShowcaseDisplayData(campaignId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, has_showcase]);

  useEffect(() => {
    if (errorMessage) setToast(errorMessage);
  }, [errorMessage]);

  useEffect(() => {
    if (notFound) history.replace("/not-found");
  }, [history, notFound]);

  useEffect(() => {
    setShowLayout(!noLayout && iframe !== "true");
  }, [iframe, noLayout]);

  useEffect(() => {
    if (affiliateContactId) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set("ct", affiliateContactId);
      history.replace({
        pathname: location.pathname,
        search: `?${searchParams.toString()}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [affiliateContactId]);

  const classes = styles({ showLayout });

  return (
    <Fragment>
      <MetaTags />
      <div id="top" className={classes.page}>
        {showLayout === true && <Header />}
        <div className={classes.mainContent}>{children}</div>
        {showLayout === true && <Footer />}
        <Toast toast={toast} setToast={setToast} />
      </div>
      <SubmissionDialog />
    </Fragment>
  );
}

export const Layout = connect(
  state => {
    const {
      ui: { errorMessage },
      campaign,
      donation: { donationSuccess },
    } = state;
    return {
      ...campaign,
      donationSuccess,
      errorMessage,
    };
  },
  dispatch => {
    return {
      productActions: bindActionCreators(productActions, dispatch),
      campaignActions: bindActionCreators(campaignActions, dispatch),
    };
  },
)(_Layout);

const styles = makeStyles(theme => ({
  page: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  mainContent: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    paddingTop: ({ showLayout }) => (showLayout ? 80 : 0),
    backgroundColor: "#ffffff",
    [theme.breakpoints.down("sm")]: {
      paddingTop: ({ showLayout }) => (showLayout ? 56 : 0),
    },
  },
}));
