import React, { useEffect, useRef, useState, Fragment } from "react";
import { connect, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { useForm } from "react-final-form";
import { ccEntryModes, PROCESSOR_ERROR_MSG } from "../../lib";
import { setToast } from "../../state";

function _Banquest({
  setFetchingTokens,
  gatewayPublicKey,
  onSubmitRef,
  ccEntryMode,
  showPaymentForm,
  closePaymentDialog,
}) {
  const [displayError, setDisplayError] = useState(false);
  const [isSwipe, setIsSwipe] = useState(false);
  const [showSwipeMsg, setShowSwipeMsg] = useState(false);
  const classes = styles({ displayError, isSwipe });
  const interval = useRef(null);
  const cardForm = useRef(null);
  const swipeCardForm = useRef(null);
  const isSwipeRef = useRef(false);
  const swipeToken = useRef(null);
  const swipeExpMonth = useRef(null);
  const swipeExpYear = useRef(null);
  const form = useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    onSubmitRef.current = onSubmit;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!gatewayPublicKey) return;
    interval.current = setInterval(() => {
      const { HostedTokenization } = window;
      if (HostedTokenization) {
        const hostedTokenization = new HostedTokenization(gatewayPublicKey);
        // MANUAL
        cardForm.current = hostedTokenization.create("card-form");
        cardForm.current
          .mount("#banquest-iframe")
          .setStyles({
            container:
              "width: 100%; height: 56px; display: flex; justify-content: space-between; align-items: center",
            avsZip: "display: none",
            card: "max-width: 156px",
            expiryContainer: "min-width: 70px",
            cvv2: "min-width: 40px; width: 40px;",
            expiryMonth: "width: 26px;",
          })
          .on("input", () => setDisplayError(false));

        // SWIPE
        swipeCardForm.current = hostedTokenization.create("swipe");

        clearInterval(interval.current);
      }
    }, 100);

    return () => {
      clearInterval(interval.current);
    };
  }, [gatewayPublicKey]);

  useEffect(() => {
    const _isSwipe = ccEntryMode === ccEntryModes.SWIPE;
    setIsSwipe(_isSwipe);
    isSwipeRef.current = _isSwipe;
  }, [ccEntryMode]);

  useEffect(() => {
    setShowSwipeMsg(false);
    if (showPaymentForm && isSwipe) {
      window.scrollTo(0, 0);
      setTimeout(() => openSwipeModal(), 10);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSwipe, showPaymentForm]);

  const openSwipeModal = async () => {
    if (!swipeCardForm.current) {
      closePaymentDialog();
      return dispatch(setToast(PROCESSOR_ERROR_MSG));
    }
    try {
      const result = await swipeCardForm.current.getSwipe();
      const { nonce, expiryMonth, expiryYear } = result;
      const month = expiryMonth < 10 ? `0${expiryMonth}` : `${expiryMonth}`;
      swipeToken.current = nonce;
      swipeExpMonth.current = month;
      swipeExpYear.current = `${expiryYear}`.slice(-2);
      setShowSwipeMsg(true);
    } catch (error) {
      if (error !== "cancelled") {
        let msg = PROCESSOR_ERROR_MSG;
        if (error?.message) msg = error.message;
        dispatch(setToast(msg));
      }
      closePaymentDialog();
    }
  };

  const onSubmit = async () => {
    if (isSwipeRef.current) {
      setFetchingTokens(true);
      form.change("ccToken", swipeToken.current);
      form.change("exp_month", swipeExpMonth.current);
      form.change("exp_year", swipeExpYear.current);
      form.submit();
    } else {
      if (!cardForm.current) {
        return dispatch(setToast(PROCESSOR_ERROR_MSG));
      }
      try {
        setFetchingTokens(true);
        const {
          nonce,
          expiryMonth,
          expiryYear,
        } = await cardForm.current.getNonceToken();
        const month = expiryMonth < 10 ? `0${expiryMonth}` : `${expiryMonth}`;
        form.change("ccToken", nonce);
        form.change("exp_month", month);
        form.change("exp_year", `${expiryYear}`.slice(-2));
        form.submit();
      } catch (e) {
        let msg = PROCESSOR_ERROR_MSG;
        if (e?.message) msg = e.message;
        dispatch(setToast(msg));
        setDisplayError(true);
      }
    }

    setTimeout(() => setFetchingTokens(false), 1000);
  };

  return (
    <Fragment>
      <div id="banquest-iframe" className={classes.container} />
      {showSwipeMsg && (
        <div className={classes.swipeMsg}>
          <div className={classes.swipeSuccess}>Card read successfully!</div>
          <div>Submit payment below</div>
        </div>
      )}
    </Fragment>
  );
}

export const Banquest = connect(state => {
  const {
    campaign: { gatewayPublicKey },
    donation: { ccEntryMode },
  } = state;
  return { ccEntryMode, gatewayPublicKey };
})(_Banquest);

const styles = makeStyles(theme => ({
  container: {
    height: 56,
    maxHeight: 56,
    marginBottom: 32,
    display: ({ isSwipe }) => (isSwipe ? "none" : "flex"),
    justifyContent: "space-between",
    width: "100%",
    borderRadius: 4,
    border: ({ displayError }) =>
      displayError
        ? "1px solid #f44336"
        : `1px solid ${theme.palette.text.secondary}`,
    padding: "0 18.5px",
    boxSizing: "border-box",
    outline: "none",
    [theme.breakpoints.down("sm")]: {
      padding: "0 3px",
    },
  },
  swipeMsg: {
    marginBottom: 36,
    textAlign: "center",
    fontSize: 18,
  },
  swipeSuccess: {
    marginBottom: 8,
    fontSize: 20,
    fontWeight: "bold",
    color: "#34eb56",
  },
}));
