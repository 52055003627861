import { makeStyles } from "@material-ui/core";

export const submissionFormStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: 536,
  },
  top: {
    display: "flex",
    marginBottom: 32,
  },
  logo: {
    maxWidth: 100,
    maxHeight: 32,
    marginRight: 16,
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      top: 24,
      left: 24,
    },
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "22px",
    paddingRight: 20,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      marginBottom: 32,
    },
  },
  inputGroup: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  halfInput: {
    width: 256,
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "100%",
    },
  },
  msg: {
    width: "100%",
    marginBottom: 32,
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-between",
    },
  },
  button: {
    width: 140,
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.2,
    [theme.breakpoints.down("xs")]: {
      width: "calc(50% - 8px)",
      minWidth: "calc(50% - 8px)",
    },
  },
  cancelButton: {
    marginRight: "16px",
    color: "#FFFFFF",
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
    },
  },
}));
