import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Dialog,
  useMediaQuery,
  IconButton,
  Button,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import { useField } from "react-final-form";
import { useDispatch } from "react-redux";
import { calendarTypes, hebrewDates, hexToRGBA } from "../../lib";
import { donationActions } from "../../state";
const DAYS_OF_WEEK = ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Shab"];
const MOBILE_DAYS_OF_WEEK = ["S", "M", "T", "W", "T", "F", "SH"];

export function DonationGroupItemDayPicker({
  item,
  isOpen,
  toggle,
  setDayPickerSelectedDays,
}) {
  const {
    label,
    amount,
    campaign_donation_group_item_days: itemDays,
    monthName,
    secondaryMonthStr,
  } = item;
  const classes = styles();
  const isSmallDevice = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const [startBlanks, setStartBlanks] = useState([]);
  const [endBlanks, setEndBlanks] = useState([]);
  const [days, setDays] = useState([]);
  const [daysIdNameObj, setDaysIdNameObj] = useState({});

  useEffect(() => {
    if (!Array.isArray(itemDays) || !itemDays.length) return;
    const daysInMonth = itemDays.length;

    const sBlanks = itemDays[0].day_of_week - 1;
    const eBlanks = 7 - ((sBlanks + daysInMonth) % 7);
    setStartBlanks(Array(sBlanks).fill(""));
    setEndBlanks(Array(eBlanks < 7 ? eBlanks : 0).fill(""));
    setDays(itemDays);
    const daysIdName = {};
    itemDays.forEach(({ id, day }) => (daysIdName[id] = day));
    setDaysIdNameObj(daysIdName);
  }, [itemDays]);

  const closeDialog = () => {
    toggle(false);
  };

  return (
    <Dialog
      open={isOpen}
      maxWidth={false}
      fullScreen={isSmallDevice}
      onClose={closeDialog}
    >
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <IconButton
            key="close"
            color="inherit"
            onClick={closeDialog}
            edge="end"
            className={classes.closeIcon}
          >
            <CloseIcon fontSize="medium" />
          </IconButton>
          <div className={classes.topSection}>
            <div className={classes.label}>{label || ""}</div>
            <div className={classes.amount}>${amount}</div>
          </div>

          <div>
            <div className={classes.calendarHeader}>
              <div className={classes.selectDay}>{"Select day(s)"}</div>
              <div className={classes.monthWrapper}>
                <span className={classes.month}>{monthName}</span>
                {secondaryMonthStr && (
                  <span
                    className={classes.secondaryMonth}
                  >{`(${secondaryMonthStr})`}</span>
                )}
              </div>
            </div>

            <div className={classes.calendar}>
              {(isSmallDevice ? MOBILE_DAYS_OF_WEEK : DAYS_OF_WEEK).map(
                (d, i) => (
                  <div key={i} className={classes.dayOfWeek}>
                    {d}
                  </div>
                ),
              )}
              {startBlanks.map((_, i) => (
                <div key={i} className={classes.blank} />
              ))}
              {days.map((itemDay, i) => (
                <Day
                  key={i}
                  itemDay={itemDay}
                  item={item}
                  setDayPickerSelectedDays={setDayPickerSelectedDays}
                  daysIdNameObj={daysIdNameObj}
                />
              ))}
              {endBlanks.map((_, i) => (
                <div key={i} className={classes.blank} />
              ))}
            </div>
          </div>

          <div className={classes.bottomSection}>
            <Button
              color="primary"
              variant="contained"
              onClick={closeDialog}
              className={classes.continueButton}
              size="large"
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

function Day({ item, itemDay, setDayPickerSelectedDays, daysIdNameObj }) {
  const {
    id: itemId,
    amount,
    calendar_type,
    monthName,
    day_picker_display_english_and_hebrew: displayBoth,
  } = item;
  const isHebrew = calendar_type === calendarTypes.HEBREW;
  const { id: dayId, day, disable, taken, gregorian_day, hebrew_day } = itemDay;
  const selectedDGItemsField = useField("selectedDonationGroupItems");
  const {
    [itemId]: selectedItem,
    ...otherSelectedItems
  } = selectedDGItemsField.input.value;
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const theme = useTheme();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(false);
  const classes = styles({ isHebrew, selected, taken, disable });

  useEffect(() => {
    if (
      Array.isArray(selectedItem?.dayIds) &&
      selectedItem.dayIds.includes(dayId)
    ) {
      setSelected(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  const handleClick = () => {
    if (disable || taken) return;
    setSelected(prev => {
      setTimeout(() => {
        updateInfo(!prev);
      }, 50);
      return !prev;
    });
  };

  const updateInfo = selected => {
    let newIds;
    let update;
    if (selected) {
      if (!selectedItem) {
        newIds = [dayId];
        update = {
          ...otherSelectedItems,
          [itemId]: { amount, dayIds: newIds },
        };
      } else {
        newIds = [...selectedItem.dayIds, dayId];
        update = {
          ...otherSelectedItems,
          [itemId]: { amount: newIds.length * amount, dayIds: newIds },
        };
      }
    } else {
      if (!selectedItem || selectedItem.dayIds.length < 2) {
        newIds = null;
        update = otherSelectedItems;
      } else {
        newIds = selectedItem.dayIds.filter(id => id !== dayId);
        update = {
          ...otherSelectedItems,
          [itemId]: { amount: newIds.length * amount, dayIds: newIds },
        };
      }
    }
    selectedDGItemsField.input.onChange(update);
    updateSelectedDays(selected);
    if (newIds) {
      const days = newIds.map(id => {
        const day = daysIdNameObj[id];
        if (isHebrew) return `${hebrewDates[day]} ${monthName}`;
        else return `${monthName} ${day}`;
      });
      setDayPickerSelectedDays(days.join(", "));
    } else setDayPickerSelectedDays(null);
  };

  const updateSelectedDays = selected => {
    let date;
    if (isHebrew) {
      date = `${hebrewDates[day]} ${monthName}`;
    } else {
      date = `${monthName} ${day}`;
    }
    if (selected) {
      dispatch(
        donationActions.addSelectedDonationItemDays({ [`${dayId}`]: date }),
      );
    } else {
      dispatch(donationActions.removeSelectedDonationItemDays(dayId));
    }
  };

  return (
    <div
      className={classes.dayCard}
      onClick={handleClick}
      style={{
        border: selected
          ? `${isMobile ? 2 : 3}px solid ${theme.palette.primary.main}`
          : `1px solid ${theme.palette.text.secondary}`,
      }}
    >
      {taken > 0 && <div className={classes.taken}>Taken</div>}
      <div className={classes.day}>{isHebrew ? hebrewDates[day] : day}</div>
      {displayBoth && (
        <div className={classes.subDay}>
          {isHebrew ? gregorian_day : hebrewDates[hebrew_day]}
        </div>
      )}
      {selected && (
        <div className={classes.selected}>
          <CheckIcon
            fontSize="small"
            color="primary"
            className={classes.selectedIcon}
          />
        </div>
      )}
      {Boolean(taken || disable) && <div className={classes.disabled}></div>}
    </div>
  );
}

const styles = makeStyles(theme => ({
  wrapper: {
    width: 876,
    padding: 40,
    display: "flex",
    justifyContent: "center",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minWidth: 360,
      padding: "24px 8px 64px 8px",
      minHeight: "100vh",
    },
  },
  container: {
    backgroundColor: "#FFFFFF",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      width: 330,
      minWidth: 330,
      minHeight: "100%",
    },
  },
  closeIcon: {
    position: "absolute",
    top: 22,
    right: 22,
    color: theme.palette.text.secondary,
  },
  label: {
    width: "100%",
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "22px",
    whiteSpace: "pre-wrap",
    textAlign: "start",
    paddingRight: 24,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  amount: {
    width: "100%",
    textAlign: "start",
    fontSize: 20,
    fontWeight: 700,
    letterSpacing: 0.25,
    lineHeight: "22px",
    color: theme.palette.primary.main,
    margin: "8px 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
  },
  calendarHeader: {
    width: "100%",
    paddingBottom: 46,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  selectDay: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  monthWrapper: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: 8,
    },
  },
  month: {
    fontSize: 32,
    fontWeight: 600,
    letterSpacing: 0,
    color: theme.palette.secondary.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: ({ isHebrew }) => (isHebrew ? 28 : 26),
    },
  },
  secondaryMonth: {
    marginLeft: 12,
    fontSize: ({ isHebrew }) => (isHebrew ? 24 : 22),
    [theme.breakpoints.down("sm")]: {
      fontSize: ({ isHebrew }) => (isHebrew ? 14 : 16),
      marginLeft: 8,
    },
  },
  calendar: {
    display: "flex",
    width: "100%",
    maxWidth: "100%",
    minWidth: "100%",
    flexWrap: "wrap",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      minWidth: 330,
      width: 330,
    },
  },
  dayOfWeek: {
    height: 26,
    width: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 8,
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0,
    [theme.breakpoints.down("sm")]: {
      width: 40,
      marginRight: 1,
      marginLeft: 1,
    },
  },
  dayCard: {
    position: "relative",
    height: 100,
    width: 100,
    borderRadius: 8,
    cursor: ({ taken, disable }) => (taken || disable ? "unset" : "pointer"),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 15,
    backgroundColor: "#FFFFFF",
    opacity: ({ taken, disable }) => (taken || disable ? 0.5 : 1),
    "&:hover": {
      backgroundColor: ({ taken, disable }) =>
        taken || disable
          ? "#FFFFFF"
          : hexToRGBA(theme.palette.primary.main, 0.09),
    },
    [theme.breakpoints.down("sm")]: {
      width: 40,
      height: 40,
      marginRight: 1,
      marginLeft: 1,
      marginBottom: 8,
    },
  },
  disabled: {
    borderRadius: 8,
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
    background: `linear-gradient(to bottom left, transparent calc(50% - 1px),  ${theme.palette.text.secondary} calc(50% - 1px), ${theme.palette.text.secondary} 50%, transparent 50%)`,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  selected: {
    position: "absolute",
    top: 5,
    left: 5,
    [theme.breakpoints.down("sm")]: {
      top: -1,
      left: -2,
    },
  },
  selectedIcon: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  day: {
    fontSize: ({ isHebrew }) => (isHebrew ? 28 : 22),
    color: theme.palette.secondary.main,
    fontWeight: "bold",
    textDecoration: ({ disable }) => (disable ? "line-through" : "unset"),
    [theme.breakpoints.down("sm")]: {
      fontSize: ({ isHebrew }) => (isHebrew ? 18 : 14),
      color: theme.palette.secondary.main,
    },
  },
  subDay: {
    paddingTop: 1,
    fontSize: ({ isHebrew }) => (isHebrew ? 14 : 18),
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
      fontSize: ({ isHebrew }) => (isHebrew ? 10 : 14),
    },
  },
  blank: {
    height: 100,
    width: 100,
    marginBottom: 15,
    [theme.breakpoints.down("sm")]: {
      width: 40,
      height: 40,
      marginRight: 1,
      marginLeft: 1,
      marginBottom: 8,
    },
  },
  taken: {
    position: "absolute",
    top: 0,
    color: theme.palette.primary.main,
    fontSize: 12,
    fontWeight: 700,
    backgroundColor: hexToRGBA(theme.palette.primary.main, 0.25),
    borderRadius: 14,
    padding: "4px 0",
    width: 85,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      borderRadius: "14px 14px 0 0",
      fontSize: 9,
      padding: 0,
    },
  },
  bottomSection: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 12,
  },
  continueButton: {
    width: 140,
    height: 40,
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.2,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: 56,
      borderRadius: 28,
      fontSize: 20,
    },
  },
}));
