import React, { Fragment, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Container, makeStyles } from "@material-ui/core";
import {
  RulesAndDisclaimers,
  CampaignStats,
  DonationForm,
  DonationConfirmation,
  SocialMediaBar,
  PictureAndParagraph,
  MainBanner,
  ParticipantContainer,
  Sponsors,
  Breadcrumbs,
  UnderBannerHeader,
  Showcase,
  VolunteerPagesOnly,
} from "../components";
import { donationActions } from "../state";

function _TeamPage(props) {
  const {
    donationSuccess,
    campaign: {
      teamId,
      volunteer_pages_only,
      team_name,
      picture,
      text_html,
      video_link,
      informational_site_only,
    },
  } = props;
  const classes = styles();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(donationActions.clearDonationForm());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!teamId) return <></>;
  if (volunteer_pages_only) return <VolunteerPagesOnly />;
  if (donationSuccess) return <DonationConfirmation />;
  return (
    <Fragment>
      <MainBanner />
      <UnderBannerHeader />
      <Breadcrumbs />
      <Container>
        <h1 className={classes.title}>{`${team_name}'s team page`}</h1>
      </Container>
      <CampaignStats forTeam={true} />
      <Showcase />
      <Sponsors />
      <PictureAndParagraph
        picture={picture}
        video_link={video_link}
        text_html={text_html}
      />
      <ParticipantContainer forTeam={true} />
      {!informational_site_only && <DonationForm />}
      <RulesAndDisclaimers />
      <SocialMediaBar />
    </Fragment>
  );
}

export const TeamPage = connect(state => {
  const {
    campaign,
    donation: { donationSuccess },
  } = state;
  return { donationSuccess, campaign };
})(_TeamPage);

function styles() {
  return makeStyles(theme => ({
    title: {
      color: theme.palette.primary.main,
      padding: "32px 0 24px 0",
      fontSize: 32,
      fontWeight: 600,
      letterSpacing: 0.4,
      lineHeight: "35px",
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        padding: "32px 16px 24px 16px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "22px",
        lineHeight: "26px",
        letterSpacing: 0.3,
      },
    },
  }))();
}
