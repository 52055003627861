import React, { useState, useEffect, Fragment } from "react";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { useField, useFormState } from "react-final-form";
import { connect, useDispatch } from "react-redux";
import {
  CC_PAYMENT,
  calculatePercentOfNum,
  formatCurrencyDisplay,
  isProductCampaign,
} from "../../lib";
import { productActions } from "../../state";
import { donationFormStyles } from "./DonationForm.styles";

function _ProcessingFee({
  campaign,
  donationAmount,
  eventTicketTotal,
  productDonation,
}) {
  const {
    processing_fee_title = "",
    processing_fee_paragraph = "",
    processing_fee_percent,
    processing_fee_mandatory: isMandatory,
    campaign_type_id,
  } = campaign;
  const { values = {} } = useFormState();
  const isCC = values.pmt_type === CC_PAYMENT;
  const isProduct = isProductCampaign(campaign_type_id);
  const classes = donationFormStyles({ isProduct });
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [percent, setPercent] = useState(0);
  const [addFee, setAddFee] = useState(true);
  const [feeDisplay, setFeeDisplay] = useState("");
  const processingFeeField = useField("processing_fee");
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isCC || isMandatory || (isProduct && productDonation <= 0)) {
      setShowCheckbox(false);
    } else setShowCheckbox(true);
  }, [isProduct, isCC, isMandatory, productDonation]);

  useEffect(() => {
    setPercent(processing_fee_percent ? Number(processing_fee_percent) : 0);
  }, [processing_fee_percent]);

  useEffect(() => {
    if (!isCC) {
      processingFeeField.input.onChange(0);
      if (isProduct) {
        dispatch(
          productActions.updateOrderFigures({
            productDonationProcessingFee: 0,
          }),
        );
      }
      return;
    }

    const amount = isProduct
      ? productDonation
      : Number((Number(donationAmount) + Number(eventTicketTotal)).toFixed(2));
    const fee = calculatePercentOfNum(amount, percent);

    const label = processing_fee_title
      ? processing_fee_title
      : "Processing fee";
    setFeeDisplay(
      `I would like to cover the ${percent}% ($${formatCurrencyDisplay(
        Number(fee),
      )}) ${label}`,
    );
    const updatedFee = !addFee ? 0 : fee;
    processingFeeField.input.onChange(updatedFee);
    if (isProduct) {
      dispatch(
        productActions.updateOrderFigures({
          productDonationProcessingFee: updatedFee,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    donationAmount,
    eventTicketTotal,
    productDonation,
    isProduct,
    addFee,
    percent,
    isCC,
  ]);

  // always run this component (even when we are not displaying) so the useEffect will set the form value
  if (!showCheckbox) return <></>;
  return (
    <Fragment>
      <div className={classes.formSubHeader}>{processing_fee_title}</div>

      <div className={classes.processingFeeCheckbox}>
        <FormControlLabel
          control={
            <Checkbox
              checked={addFee}
              onChange={({ target }) => setAddFee(target.checked)}
            />
          }
          classes={{ label: classes.processingFeeCheckboxLabel }}
          label={feeDisplay}
        />
      </div>

      <div className={classes.processingFeeParagraph}>
        {processing_fee_paragraph}
      </div>
    </Fragment>
  );
}

export const ProcessingFee = connect(state => {
  const {
    campaign,
    donation: { donationAmount, eventTicketTotal },
    product: { productDonation },
  } = state;
  return { campaign, donationAmount, eventTicketTotal, productDonation };
})(_ProcessingFee);
