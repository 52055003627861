import React, { Fragment } from "react";
import TimeAgo from "react-timeago";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { hexToRGBA } from "../../lib";
import { participantContainerStyles } from "./ParticipantContainer.styles";

export function _DonationList({ donationList }) {
  const parentClasses = participantContainerStyles();
  const classes = styles();

  return (
    <Fragment>
      {donationList.map((d, index) => {
        const { name, amount, comments, created_at, withName } = d;
        return (
          <div key={index} className={parentClasses.card}>
            <div className={classes.top}>
              <div className={classes.amount}>${amount}</div>
              <TimeAgo
                date={new Date(created_at)}
                className={classes.timeAgo}
              />
            </div>

            <div className={classes.name}>{name}</div>
            {withName && (
              <div className={classes.withName}>{`With ${withName}`}</div>
            )}
            {comments && <div className={classes.comments}>{comments}</div>}
          </div>
        );
      })}
    </Fragment>
  );
}

export const DonationList = connect(state => {
  return { donationList: state.donation.donationList };
})(_DonationList);

const styles = makeStyles(theme => ({
  top: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  amount: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    letterSpacing: 0.25,
    fontSize: 20,
  },
  timeAgo: {
    color: hexToRGBA(theme.palette.text.primary, 0.31),
    fontSize: 14,
    letterSpacing: 0.17,
  },
  name: {
    maxWidth: "100%",
    fontSize: "16px",
    lineHeight: "19px",
    fontWeight: 600,
    letterSpacing: 0,
    paddingBottom: 8,
    textTransform: "capitalize",
  },
  withName: {
    color: hexToRGBA(theme.palette.text.primary, 0.31),
    maxWidth: "100%",
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0,
    paddingBottom: 8,
    lineHeight: "16px",
  },
  comments: {
    maxWidth: "100%",
    fontSize: 14,
    letterSpacing: 0.17,
    lineHeight: "19px",
  },
}));
