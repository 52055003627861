import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

export function NotFound() {
  const classes = styles();
  return (
    <div className={classes.container}>
      <div className={classes.header}>Oops…</div>
      <div className={classes.msg}>
        We're sorry but the page you are looking for cannot be found
      </div>
      <Button
        className={classes.home}
        variant="contained"
        component={Link}
        to="/"
      >
        Go to home page
      </Button>
    </div>
  );
}

const styles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    padding: "160px 16px",
  },
  header: {
    color: "#E31936",
    fontSize: "200px",
    letterSpacing: "2.5px",
    marginBottom: "60px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "88px",
      marginBottom: "40px",
    },
  },
  msg: {
    color: "#2222223",
    fontSize: "24px",
    fontWeight: 600,
    letterSpacing: "0.3px",
    textAlign: "center",
    lineHeight: "22px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  home: {
    marginTop: 36,
    backgroundColor: "#E31936",
    color: "#FFFFFF",
  },
}));
