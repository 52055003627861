import React from "react";
import { makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

export function MatchingCircle() {
  const theme = useTheme();
  const classes = styles();

  return (
    <svg
      className={classes.svg}
      width="115.93px"
      height="55.4472691px"
      viewBox="0 0 115.93 55.4472691"
    >
      <title>match</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-622.080000, -456.302731)"
          fill={theme.palette.primary.main}
          fillRule="nonzero"
        >
          <g transform="translate(611.000000, 445.000000)">
            <g transform="translate(11.080000, 11.302731)">
              <polygon
                id="Path"
                points="-7.10542736e-15 51.1372691 1.09 44.9672691 2.54 45.2272691 1.77 49.5572691 4.48 50.0372691 5.09 46.5572691 6.47 46.8072691 5.85 50.2772691 8.74 50.7872691 9.54 46.2272691 10.99 46.4772691 9.86 52.8772691"
              ></polygon>
              <polygon
                id="Path"
                points="4.26 43.3172691 4.86 41.5972691 10.77 41.6572691 6.14 38.0072691 6.7 36.4372691 12.72 41.3872691 12.06 43.2472691"
              ></polygon>
              <path
                d="M10.76,36.5672691 L10.66,36.5172691 C8.68,35.4172691 8.1,33.3272691 9.11,31.4972691 C9.99,29.9072691 11.73,28.9872691 14.02,30.2572691 L14.45,30.4972691 L11.85,35.1872691 C13.09,35.8072691 14.12,35.5772691 14.73,34.4772691 C15.23,33.5772691 15.13,32.9172691 14.56,32.4672691 L15.35,31.0372691 C16.58,31.9872691 16.72,33.4872691 15.81,35.1272691 C14.77,37.0172691 12.78,37.6872691 10.75,36.5672691 L10.75,36.5672691 L10.76,36.5672691 Z M12.65,31.4172691 C11.51,30.8772691 10.7,31.1772691 10.2,32.0972691 C9.7,32.9872691 9.97,33.9272691 10.88,34.6172691 L12.65,31.4172691 Z"
                id="Shape"
              ></path>
              <path
                d="M12.45,26.2572691 L13.48,24.9072691 L14.57,25.7372691 C14.11,24.8072691 14.1,23.9272691 14.84,22.9472691 L16.1,23.9072691 C15.21,25.0972691 15.05,26.0972691 16.28,27.0372691 L19.31,29.3472691 L18.28,30.6972691 L12.46,26.2572691 L12.45,26.2572691 L12.45,26.2572691 Z"
                id="Path"
              ></path>
              <polygon
                id="Path"
                points="22.91 24.1672691 16.23 21.4972691 17.54 20.2072691 22.23 22.2172691 20.21 17.5872691 21.39 16.4372691 25.42 26.2472691 24.23 27.4172691"
              ></polygon>
              <path
                d="M28.29,15.8972691 L28.23,15.7972691 C27.06,13.7772691 27.52,11.7972691 29.16,10.8372691 C30.19,10.2372691 31.11,10.3472691 31.82,10.7672691 L29.56,6.86726914 L31.03,6.01726914 L36.4,15.2972691 L34.93,16.1472691 L34.31,15.0772691 C34.34,15.9072691 33.85,16.9272691 32.91,17.4672691 C31.32,18.3872691 29.47,17.9572691 28.28,15.8972691 L28.29,15.8972691 L28.29,15.8972691 Z M33.17,12.9772691 L33.11,12.8772691 C32.3,11.4872691 31.26,11.1672691 30.19,11.7772691 C29.11,12.3972691 28.92,13.5172691 29.71,14.8872691 L29.77,14.9872691 C30.58,16.3772691 31.66,16.6372691 32.61,16.0972691 C33.63,15.5072691 33.99,14.3972691 33.17,12.9772691 L33.17,12.9772691 Z"
                id="Shape"
              ></path>
              <path
                d="M36.71,11.1572691 L36.67,11.0472691 C35.91,8.90726914 36.93,6.94726914 38.96,6.22726914 C40.98,5.50726914 43,6.38726914 43.75,8.49726914 L43.79,8.60726914 C44.55,10.7572691 43.53,12.6972691 41.5,13.4172691 C39.47,14.1372691 37.46,13.2572691 36.72,11.1572691 L36.71,11.1572691 Z M42.15,9.19726914 L42.12,9.10726914 C41.62,7.67726914 40.59,7.05726914 39.41,7.48726914 C38.22,7.90726914 37.8,9.02726914 38.3,10.4472691 L38.34,10.5572691 C38.84,11.9672691 39.85,12.6072691 41.07,12.1772691 C42.26,11.7572691 42.66,10.6172691 42.16,9.20726914 L42.15,9.20726914 L42.15,9.19726914 Z"
                id="Shape"
              ></path>
              <rect
                id="Rectangle"
                transform="translate(46.383984, 6.160742) rotate(-11.940000) translate(-46.383984, -6.160742) "
                x="45.5389842"
                y="0.800741772"
                width="1.69"
                height="10.72"
              ></rect>
              <rect
                id="Rectangle"
                transform="translate(50.717215, 5.424416) rotate(-7.250000) translate(-50.717215, -5.424416) "
                x="49.8672146"
                y="0.064415654"
                width="1.7"
                height="10.72"
              ></rect>
              <path
                d="M53.92,8.44726914 C53.92,6.73726914 55.57,6.11726914 57.67,6.11726914 L58.58,6.11726914 L58.58,5.73726914 C58.58,4.75726914 58.24,4.26726914 57.25,4.26726914 C56.37,4.26726914 55.93,4.68726914 55.85,5.41726914 L54.21,5.41726914 C54.35,3.70726914 55.7,2.99726914 57.34,2.98726914 C58.98,2.98726914 60.27,3.65726914 60.27,5.65726914 L60.27,10.4572691 L58.61,10.4572691 L58.61,9.55726914 C58.13,10.1872691 57.49,10.5972691 56.37,10.5972691 C55.03,10.5972691 53.92,9.95726914 53.91,8.42726914 L53.91,8.44726914 L53.92,8.44726914 Z M58.58,7.90726914 L58.58,7.17726914 L57.71,7.17726914 C56.42,7.17726914 55.59,7.47726914 55.6,8.36726914 C55.6,8.98726914 55.94,9.38726914 56.78,9.38726914 C57.82,9.38726914 58.58,8.83726914 58.58,7.89726914 L58.58,7.89726914 L58.58,7.90726914 Z"
                id="Shape"
              ></path>
              <path
                d="M63.41,3.35726914 L65.09,3.59726914 L64.9,4.95726914 C65.49,4.10726914 66.24,3.66726914 67.47,3.81726914 L67.25,5.38726914 C65.78,5.19726914 64.82,5.53726914 64.61,7.07726914 L64.07,10.8472691 L62.39,10.6072691 L63.42,3.35726914 L63.42,3.35726914 L63.41,3.35726914 Z"
                id="Path"
              ></path>
              <path
                d="M74.31,5.77726914 L75.86,6.45726914 L75.41,7.48726914 C76,6.99726914 76.97,6.77726914 77.96,7.20726914 C78.83,7.58726914 79.37,8.25726914 79.34,9.24726914 C80.22,8.63726914 81.3,8.66726914 82.14,9.02726914 C83.37,9.56726914 84.05,10.7572691 83.24,12.6072691 L81.39,16.8572691 L79.84,16.1772691 L81.64,12.0272691 C82.07,11.0372691 81.83,10.4272691 81.06,10.0972691 C80.29,9.76726914 79.36,9.96726914 78.91,10.9972691 L77.16,15.0172691 L75.61,14.3372691 L77.41,10.1872691 C77.84,9.19726914 77.59,8.58726914 76.83,8.25726914 C76.06,7.92726914 75.13,8.12726914 74.68,9.15726914 L72.93,13.1772691 L71.38,12.4972691 L74.3,5.77726914 L74.31,5.77726914 L74.31,5.77726914 Z"
                id="Path"
              ></path>
              <path
                d="M83.94,15.9072691 C84.94,14.5272691 86.64,14.9972691 88.35,16.2272691 L89.09,16.7572691 L89.31,16.4472691 C89.89,15.6572691 89.9,15.0572691 89.1,14.4772691 C88.39,13.9572691 87.79,14.0472691 87.29,14.5872691 L85.96,13.6272691 C87.08,12.3272691 88.59,12.5472691 89.92,13.5072691 C91.25,14.4672691 91.9,15.7672691 90.72,17.3872691 L87.9,21.2772691 L86.55,20.2972691 L87.08,19.5672691 C86.32,19.7972691 85.56,19.7472691 84.66,19.0972691 C83.57,18.3072691 83.05,17.1372691 83.95,15.8972691 L83.94,15.8972691 L83.94,15.9072691 Z M88.03,18.2172691 L88.46,17.6272691 L87.76,17.1172691 C86.72,16.3572691 85.87,16.1172691 85.35,16.8372691 C84.99,17.3372691 85.02,17.8672691 85.7,18.3572691 C86.54,18.9672691 87.49,18.9772691 88.04,18.2172691 L88.04,18.2172691 L88.03,18.2172691 Z"
                id="Shape"
              ></path>
              <path
                d="M91.14,21.3472691 L93.92,18.4572691 L93.21,17.7772691 L94.09,16.8572691 L94.8,17.5372691 L95.92,16.3772691 L97.14,17.5472691 L96.02,18.7072691 L97.18,19.8272691 L96.3,20.7472691 L95.14,19.6272691 L92.47,22.4072691 C92.02,22.8672691 92.04,23.2972691 92.43,23.6672691 C92.67,23.8972691 92.88,24.0472691 93.12,24.1372691 L92.19,25.1072691 C91.93,24.9972691 91.61,24.7972691 91.24,24.4372691 C90.19,23.4272691 90.19,22.3372691 91.15,21.3372691 L91.14,21.3372691 L91.14,21.3472691 Z"
                id="Path"
              ></path>
              <path
                d="M95.79,23.8472691 L95.88,23.7772691 C97.71,22.3472691 99.89,22.7472691 101.15,24.3572691 C102.15,25.6372691 102.51,27.2872691 101.04,28.6872691 L100.03,27.3972691 C100.72,26.6872691 100.64,25.9272691 100.09,25.2172691 C99.38,24.2972691 98.16,24.1972691 96.97,25.1272691 L96.88,25.1972691 C95.62,26.1772691 95.44,27.3172691 96.21,28.2972691 C96.76,29.0072691 97.65,29.2872691 98.53,28.7272691 L99.49,29.9572691 C98.09,30.8872691 96.37,30.6172691 95.14,29.0472691 C93.85,27.3872691 93.95,25.2872691 95.79,23.8472691 L95.79,23.8472691 Z"
                id="Path"
              ></path>
              <path
                d="M107.65,27.9972691 L108.44,29.4972691 L104.41,31.6272691 C105.21,31.6072691 106.12,32.0372691 106.68,33.1072691 C107.37,34.4072691 107.1,35.7272691 105.31,36.6672691 L101.24,38.8172691 L100.45,37.3172691 L104.39,35.2372691 C105.37,34.7172691 105.61,34.0872691 105.13,33.1972691 C104.68,32.3572691 103.8,31.9472691 102.78,32.4972691 L98.97,34.5172691 L98.18,33.0172691 L107.65,28.0072691 L107.65,28.0072691 L107.65,27.9972691 Z"
                id="Path"
              ></path>
              <path
                d="M105.33,39.1772691 L105.44,39.1472691 C107.6,38.4572691 109.52,39.4772691 110.15,41.4572691 C110.71,43.1972691 110.18,45.0772691 107.68,45.8772691 L107.21,46.0272691 L105.57,40.9172691 C104.27,41.3972691 103.73,42.2972691 104.12,43.4972691 C104.43,44.4672691 104.98,44.8572691 105.7,44.7572691 L106.2,46.3172691 C104.66,46.5572691 103.48,45.6272691 102.91,43.8472691 C102.25,41.7972691 103.12,39.8772691 105.33,39.1772691 L105.33,39.1772691 Z M107.79,44.0872691 C108.96,43.6272691 109.3,42.8372691 108.98,41.8372691 C108.67,40.8672691 107.81,40.4172691 106.67,40.6072691 C106.67,40.6072691 107.79,44.0872691 107.79,44.0872691 Z"
                id="Shape"
              ></path>
              <path
                d="M107.87,47.7372691 L107.98,47.7372691 C110.3,47.4172691 111.95,48.5972691 112.2,50.4872691 C112.36,51.6672691 111.91,52.4772691 111.24,52.9672691 L115.71,52.3772691 L115.93,54.0572691 L105.31,55.4472691 L105.09,53.7672691 L106.31,53.6072691 C105.54,53.3172691 104.78,52.4672691 104.64,51.3972691 C104.4,49.5772691 105.51,48.0372691 107.87,47.7272691 L107.87,47.7272691 L107.87,47.7372691 Z M108.69,53.3672691 L108.8,53.3672691 C110.4,53.1472691 111.09,52.3072691 110.93,51.0772691 C110.77,49.8372691 109.81,49.2372691 108.24,49.4372691 L108.13,49.4572691 C106.53,49.6672691 105.87,50.5772691 106.02,51.6572691 C106.17,52.8272691 107.06,53.5872691 108.69,53.3672691 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

const styles = makeStyles(_theme => ({
  svg: {
    zIndex: 9,
  },
}));
