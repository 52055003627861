import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  useMediaQuery,
  MenuItem,
  TextField,
  makeStyles,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Field, useField } from "react-final-form";
import classnames from "classnames";
import { connect } from "react-redux";
import { donationFormStyles } from "./DonationForm.styles";
import { formatMaxChars, isProductCampaign } from "../../lib";

function _DonationMessage({
  formValues,
  dedication_options,
  has_journal,
  selectedDonationItemDays,
  campaign_type_id,
}) {
  const { comments } = formValues;
  const isProduct = isProductCampaign(campaign_type_id);
  const DFClasses = donationFormStyles({ isProduct });
  const classes = styles();
  const commentsRef = useRef();
  const smallDevice = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const [dedicationType, setDedicationType] = useState("");
  const [subOptions, setSubOptions] = useState([]);
  const [selectedSubOption, setSelectedSubOption] = useState([]);
  const commentsField = useField("comments");

  useEffect(() => {
    let msg = getDonationItemDayTxt(selectedDonationItemDays);
    if (dedicationType) msg = `${msg}${dedicationType} `;
    if (Array.isArray(selectedSubOption) && selectedSubOption.length) {
      msg = `${msg}${selectedSubOption.join(", ")} `;
    }
    commentsField.input.onChange(msg.slice(0, 85));
    if (dedicationType) {
      commentsRef.current && commentsRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDonationItemDays, dedicationType, selectedSubOption]);

  useEffect(() => {
    clearSubOptions();
    if (!dedicationType) return;
    const option = dedication_options.find(
      ({ label }) => label === dedicationType,
    );
    if (!option) return;
    const { sub_options } = option;
    if (Array.isArray(sub_options) && sub_options.length) {
      setSubOptions(sub_options);
    }
  }, [dedicationType, dedication_options]);

  const clearSubOptions = () => {
    setSelectedSubOption([]);
    setSubOptions([]);
  };

  return (
    <Fragment>
      <div className={DFClasses.formSubHeader}>Donation message</div>
      <div className={DFClasses.inputGroup}>
        {Array.isArray(dedication_options) && dedication_options.length > 0 && (
          <TextField
            value={dedicationType}
            label="Select message type or enter custom below"
            select
            className={DFClasses.input}
            onChange={({ target }) => setDedicationType(target.value)}
            InputLabelProps={{
              className: classes.select,
              classes: { shrink: classes.shrink },
            }}
            InputProps={{
              startAdornment: dedicationType ? (
                <InputAdornment position="start">
                  <IconButton
                    size="small"
                    onClick={() => setDedicationType("")}
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
          >
            {dedication_options.map(({ label }, i) => (
              <MenuItem key={i} value={label}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        )}
        {Array.isArray(subOptions) && subOptions.length > 0 && (
          <Autocomplete
            className={DFClasses.input}
            getOptionLabel={option => option}
            options={subOptions}
            multiple
            value={selectedSubOption}
            onChange={(_, v) => setSelectedSubOption(v)}
            disableClearable
            renderInput={params => (
              <TextField
                {...params}
                label="Select option(s) or complete your message below"
                InputLabelProps={{
                  className: classes.select,
                  classes: { shrink: classes.shrink },
                }}
              />
            )}
          />
        )}
        {dedicationType &&
          (!Array.isArray(subOptions) || subOptions.length < 1) && (
            <div className={classnames(DFClasses.input, classes.completeMsg)}>
              Complete your message below
            </div>
          )}
      </div>

      <Field
        name="comments"
        fullWidth={true}
        multiline={true}
        minRows={smallDevice ? 3 : 1}
        label={`Message to display ${has_journal ? " (not for journal)" : ""}`}
        className={classnames(DFClasses.input, DFClasses.fullWidthInput)}
        type="textarea"
        render={({ input, ...rest }) => {
          return (
            <TextField
              {...rest}
              {...input}
              inputRef={commentsRef}
              helperText={`${
                comments ? comments.length : 0
              } out of 85 characters`}
              value={input.value}
              onChange={e => {
                input.onChange(formatMaxChars(e.target.value, 85));
              }}
            />
          );
        }}
      />
    </Fragment>
  );
}

export const DonationMessage = connect(state => {
  const {
    campaign: { dedication_options, has_journal, campaign_type_id },
    donation: { selectedDonationItemDays },
  } = state;
  return {
    dedication_options,
    has_journal,
    selectedDonationItemDays,
    campaign_type_id,
  };
})(_DonationMessage);

function getDonationItemDayTxt(selectedDonationItemDays) {
  if (
    !selectedDonationItemDays ||
    !Object.keys(selectedDonationItemDays).length
  ) {
    return "";
  }
  return `${Object.values(selectedDonationItemDays)[0]} `;
}

const styles = makeStyles(theme => ({
  select: {
    fontSize: 12.5,
  },
  shrink: {
    fontSize: 14,
  },
  completeMsg: {
    fontWeight: "bold",
    fontSize: 18,
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
}));
