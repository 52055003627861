import React, { Fragment } from "react";
import { FormControlLabel, MenuItem } from "@material-ui/core";
import { Field } from "react-final-form";
import { TextField, Checkbox } from "final-form-material-ui";
import classnames from "classnames";
import { composeValidators, required, USZip } from "../../lib/validators";
import { stateOptions } from "../../lib/optionGenerators";
import { donationFormStyles } from "./DonationForm.styles";

export function ProductShippingInfo(props) {
  const {
    formValues: { shipping_same_as_billing, ship_to_school },
  } = props;
  const classes = donationFormStyles({ isProduct: true });

  if (ship_to_school) return <></>;
  return (
    <div>
      <div
        className={classnames(
          classes.formSubHeader,
          classes.subHeaderBottomSpace,
        )}
      >
        Shipping address
      </div>
      <FormControlLabel
        className={classes.sameCheckbox}
        control={
          <Field
            name="shipping_same_as_billing"
            component={Checkbox}
            color="primary"
            type="checkbox"
          />
        }
        label="Same as billing address"
      />
      {!shipping_same_as_billing && (
        <Fragment>
          <Field
            name="ship_to_name"
            label="Ship-to name (if different from billing)"
            component={TextField}
            className={classnames(classes.input, classes.fullWidthInput)}
          />
          <div className={classes.inputGroup}>
            <div className={classes.splitInputSection}>
              <Field
                name="shipping_address"
                label="Address"
                required
                className={classnames(classes.input, classes.addressInput)}
                component={TextField}
                validate={required}
              />
              <Field
                name="shipping_apt"
                label="Apt"
                className={classnames(classes.input, classes.aptInput)}
                component={TextField}
              />
            </div>
            <Field
              name="shipping_city"
              label="City"
              required
              component={TextField}
              validate={required}
              className={classes.input}
            />
          </div>
          <div className={classes.inputGroup}>
            <Field
              name="shipping_state"
              label="State"
              required
              select
              validate={required}
              component={TextField}
              className={classes.input}
            >
              {stateOptions.map(({ name, code }) => (
                <MenuItem key={code} value={code}>
                  {name}
                </MenuItem>
              ))}
            </Field>
            <Field
              name="shipping_zip"
              label="zip"
              required
              component={TextField}
              validate={composeValidators(required, USZip)}
              className={classes.input}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
}
