import { makeStyles } from "@material-ui/core";

export const rulesModalStyles = makeStyles(theme => ({
  container: {
    width: "600px",
    maxWidth: "100%",
    height: "100%",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  content: {
    padding: 32,
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      padding: "24px 16px",
      minHeight: "calc(100vh - 90px)",
      //maxHeight: "unset",
    },
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0,
    marginBottom: "24px",
    [theme.breakpoints.down("sm")]: {},
  },
  close: {
    position: "absolute",
    top: 22,
    right: 22,
  },
}));
