// const ZIP_REGEX = /(^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$)|(^\d{5}$)/i;

const required = value => (value ? undefined : "Required");

const mustBeNumber = value => (isNaN(value) ? "Must be a number" : undefined);

const max2dec = value =>
  value && !/^(?:\d*\.\d{1,2}|\d+)$/.test(value) ? "Invalid Amount" : undefined;

const creditCard = value =>
  value && value.length !== 15 && value.length !== 16
    ? "Invalid Card number"
    : undefined;

const CVV = value =>
  value && (value.length > 4 || value.length < 3)
    ? "CVV must be 3 or 4 digits"
    : undefined;

const minValue = min => value =>
  isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`;

const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : undefined;

const USZip = value =>
  value && !/^\d{5}$/.test(value) ? "Zip must be 5 digits" : undefined;

const integerOnly = value =>
  value && !/^[0-9]+$/.test(value) ? "Must be a valid number" : undefined;

const validUSPhone = value => {
  const msg = "Must be a valid 10 digit number";
  if (!value) return msg;
  if (value.replace(/\D/g, "").replace(/^1/, "").length !== 10) return msg;
  return undefined;
};

const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export {
  required,
  mustBeNumber,
  creditCard,
  minValue,
  email,
  CVV,
  composeValidators,
  max2dec,
  USZip,
  integerOnly,
  validUSPhone,
};
