import React, { Fragment, useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { TextField, makeStyles } from "@material-ui/core";
import classnames from "classnames";
import { useForm } from "react-final-form";
import { ccEntryModes, PROCESSOR_ERROR_MSG } from "../../lib";
import { setToast } from "../../state";

const inputStyle = {
  height: "52px",
  width: "100%",
  outline: "none",
  border: "none",
  borderBottom: "none",
  borderTop: "none",
  borderLeft: "none",
  borderRight: "none",
  fontWeight: "200",
  fontSize: "16px",
  padding: "0px",
  margin: "1px 0px 0px 0px",
  color: "rgba(0, 0, 0, 0.54)",
};

function _Cardknox({
  onSubmitRef,
  gatewayPublicKey,
  setFetchingTokens,
  ccEntryMode,
  showPaymentForm,
}) {
  const [validCC, setValidCC] = useState(false);
  const [validCvv, setValidCvv] = useState(false);
  const [validExp, setValidExp] = useState(false);
  const [exp, setExp] = useState("");
  const [isSwipe, setIsSwipe] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const isSwipeRef = useRef(false);
  const classes = styles({ isSwipe });
  const form = useForm();
  const interval = useRef(null);
  const loadedRef = useRef(false);
  const validCcRef = useRef(false);
  const validCvvRef = useRef(false);
  const validExpRef = useRef(false);
  const ccTokenRef = useRef(null);
  const cvvTokenRef = useRef(null);
  const expRef = useRef("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (showPaymentForm && window.focusIfield) {
      window.focusIfield("card-number");
    }
  }, [showPaymentForm]);

  useEffect(() => {
    onSubmitRef.current = onSubmit;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    interval.current = setInterval(() => {
      const {
        setAccount,
        getTokens,
        setIfieldStyle,
        addIfieldKeyPressCallback,
        enableAutoFormatting,
      } = window;
      if (
        setAccount &&
        getTokens &&
        setIfieldStyle &&
        addIfieldKeyPressCallback &&
        enableAutoFormatting
      ) {
        setAccount(gatewayPublicKey, "Instaraise", "1.0");
        setIfieldStyle("cvv", inputStyle);
        setIfieldStyle("card-number", inputStyle);
        loadedRef.current = true;
        enableAutoFormatting(" ");
        addIfieldKeyPressCallback(keyPressCallback);
        clearInterval(interval.current);
      }
    }, 100);
    return () => {
      clearInterval(interval.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const _isSwipe = ccEntryMode === ccEntryModes.SWIPE;
    setIsSwipe(_isSwipe);
    isSwipeRef.current = _isSwipe;
  }, [ccEntryMode]);

  const keyPressCallback = data => {
    if (isSwipeRef.current) return;
    const { cardNumberIsValid, cvvIsValid } = data;
    setValidCC(Boolean(cardNumberIsValid));
    setValidCvv(Boolean(cvvIsValid));
    validCcRef.current = Boolean(cardNumberIsValid);
    validCvvRef.current = Boolean(cvvIsValid);
  };

  const validateCKForm = () => {
    if (!loadedRef.current) dispatch(setToast(PROCESSOR_ERROR_MSG));
    if (isSwipeRef.current) return true;
    else if (
      !validCcRef.current ||
      !validCvvRef.current ||
      !validExpRef.current
    ) {
      setFormSubmitted(true);
      let msg = "";
      if (!validCcRef.current) msg = "Invalid Credit Card";
      else if (!validCvvRef.current) msg = "Invalid CVV";
      else msg = "Invalid Exp.";
      dispatch(setToast(msg));
    } else return true;
  };

  const fetchTokens = () => {
    return new Promise((resolve, reject) => {
      window.getTokens(
        resolve,
        reject,
        30000, // timeout
      );
    });
  };

  const onSubmit = async () => {
    if (!validateCKForm()) return;
    try {
      setFetchingTokens(true);
      await fetchTokens();
      form.change("applePayData", null); // VERY IMPORTANT: we need to remove apple pay data if they used it on an earlier try
      form.change("ccToken", ccTokenRef.current.value);
      if (!isSwipeRef.current) {
        form.change("cvvToken", cvvTokenRef.current.value);
        form.change("exp_month", expRef.current.slice(0, 2));
        form.change("exp_year", expRef.current.slice(3, 5));
      }
      form.submit();
    } catch (error) {
      dispatch(setToast(PROCESSOR_ERROR_MSG));
    }
    setTimeout(() => setFetchingTokens(false), 1000);
  };

  const onExpChange = e => {
    const val = e.target.value;
    let str = val.replace(/\D/g, "").slice(0, 4);
    if (str.length > 1) {
      str = `${str.substr(0, 2)}/${str.substr(2)}`;
    }
    setExp(str);
    expRef.current = str;
    if (str.length === 5) {
      setValidExp(true);
      validExpRef.current = true;
    } else {
      setValidExp(false);
      validExpRef.current = false;
    }
  };

  return (
    <Fragment>
      <iframe
        className={classnames(
          classes.input,
          classes.cc,
          formSubmitted && !validCC && classes.invalid,
        )}
        title="cc"
        data-ifields-id="card-number"
        data-ifields-placeholder="Card number"
        src="https://cdn.cardknox.com/ifields/2.15.2302.0801/ifield.htm"
      />

      {!isSwipe && (
        <div className={classes.expCvvWrapper}>
          <TextField
            name="exp"
            placeholder="MM/YY"
            value={exp}
            onChange={onExpChange}
            className={classes.expAndCvv}
            InputProps={{ classes: { input: classes.exp } }}
            error={formSubmitted && !validExp}
          />
          <iframe
            className={classnames(
              classes.input,
              classes.expAndCvv,
              formSubmitted && !validCvv && classes.invalid,
            )}
            title="cvv"
            data-ifields-id="cvv"
            data-ifields-placeholder="CVV"
            src="https://cdn.cardknox.com/ifields/2.15.2302.0801/ifield.htm"
          />
        </div>
      )}

      {isSwipe && (
        <div className={classes.swipeMsg}>
          Do not press submit until card data is finished loading above
        </div>
      )}

      <input
        data-ifields-id="card-number-token"
        name="xCardNum"
        type="hidden"
        ref={ccTokenRef}
      />
      {/* This has to be here even for a swipe */}
      <input
        data-ifields-id="cvv-token"
        name="xCVV"
        type="hidden"
        ref={cvvTokenRef}
      />
    </Fragment>
  );
}

export const Cardknox = connect(state => {
  const {
    campaign: { gatewayPublicKey },
    donation: { ccEntryMode },
  } = state;
  return { ccEntryMode, gatewayPublicKey };
})(_Cardknox);

const styles = makeStyles(theme => ({
  inputs: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  cc: {
    width: "100% !important",
    maxWidth: "100% !important",
    marginBottom: 24,
  },
  expCvvWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 32,
  },
  expAndCvv: {
    width: "calc(50% - 12px)",
  },
  exp: {
    fontFamily: "arial",
    fontWeight: 200,
    color: "rgba(0, 0, 0, 0.54)",
    "&::placeholder": {
      opacity: 1,
    },
  },
  input: {
    height: 56,
    border: `1px solid ${theme.palette.text.secondary} !important`,
    padding: "0 18.5px",
    boxSizing: "border-box",
    outline: "none",
    borderRadius: 4,
  },
  invalid: {
    border: "1px solid #f44336 !important",
  },
  swipeMsg: {
    textAlign: "center",
    color: "#f44336",
    marginBottom: 24,
    fontSize: 16,
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      lineHeight: "14px",
    },
  },
}));
