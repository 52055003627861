import React, { useState } from "react";
import ImageUploading from "react-images-uploading";
import { IconButton, makeStyles } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Delete";
const DATA_URL = "data_url";

export function ImageUpload({ imageUploadCallback }) {
  const classes = styles();
  const [images, setImages] = useState([]);

  const onChange = imageList => {
    setImages(imageList);

    if (imageUploadCallback) {
      let data = null;
      if (imageList.length) {
        const {
          file: { name: title },
          [DATA_URL]: base64,
        } = imageList[0];
        data = { title, base64 };
      }
      imageUploadCallback(data);
    }
  };

  return (
    <ImageUploading
      value={images}
      onChange={onChange}
      dataURLKey={DATA_URL}
      acceptType={["jpg", "gif", "png", "pdf"]}
      allowNonImageType
      // multiple
      // maxNumber={2}
    >
      {({ imageList, onImageUpload, onImageRemove, dragProps }) => (
        <div>
          <div {...dragProps} className={classes.drop} onClick={onImageUpload}>
            Drop Image here or click to browse
          </div>

          {imageList.map((image, index) => {
            const { [DATA_URL]: imgSrc, file: { name, type } = {} } = image;
            const isPdf = type === "application/pdf";
            return (
              <div key={index} className={classes.imageContainer}>
                {isPdf && <div>{name}</div>}
                {!isPdf && (
                  <img
                    src={imgSrc}
                    alt="uploaded-img"
                    className={classes.image}
                  />
                )}
                <IconButton
                  className={classes.remove}
                  onClick={() => onImageRemove(index)}
                >
                  <RemoveIcon />
                </IconButton>
              </div>
            );
          })}
        </div>
      )}
    </ImageUploading>
  );
}

const styles = makeStyles(theme => ({
  drop: {
    height: 80,
    width: "100%",
    maxWidth: "100%",
    border: `1px dashed ${theme.palette.text.primary}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: 16,
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  imageContainer: {
    marginTop: 16,
    display: "flex",
    alignItems: "center",
  },
  image: {
    maxWidth: "100%",
    maxHeight: 100,
  },
  remove: {
    marginLeft: 10,
  },
}));
