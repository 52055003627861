import React, { Fragment, useEffect, useState } from "react";
import { makeStyles, Avatar, Button } from "@material-ui/core";
import { useField } from "react-final-form";
import { DonationGroupItemDayPicker } from "./DonationGroupItemDayPicker";
import { calendarTypes, formatMoneyUnPadded, hexToRGBA } from "../../lib";

export function DonationGroupItemCard(props) {
  const {
    item,
    item: {
      id,
      taken,
      taken_name,
      label,
      amount,
      avatar,
      is_day_picker,
      calendar_type,
    },
    taken_label,
    displayAvatar,
    tier1,
    tier2,
    tier3,
  } = props;
  const isHebrew = calendar_type === calendarTypes.HEBREW;
  const amountField = useField("amount");
  const selectedDGItemsField = useField("selectedDonationGroupItems");
  const [selected, setSelected] = useState(false);
  const [showDayPicker, setShowDayPicker] = useState(false);
  const [dayPickerSelectedDays, setDayPickerSelectedDays] = useState(null);
  const classes = styles({
    tier1,
    tier2,
    tier3,
    taken,
    is_day_picker,
    displayAvatar,
    dayPickerSelectedDays,
    selected,
    isHebrew,
  });

  // NON day_picker listener
  useEffect(() => {
    if (is_day_picker) return;
    const curr = selectedDGItemsField.input.value;
    if (selected) {
      selectedDGItemsField.input.onChange({ ...curr, [id]: { amount } });
    } else {
      const { [id]: _rm, ...rest } = curr;
      selectedDGItemsField.input.onChange(rest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is_day_picker, selected]);

  // day_picker listener
  useEffect(() => {
    if (!is_day_picker) return;

    setSelected(Boolean(selectedDGItemsField.input.value[id]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is_day_picker, selectedDGItemsField.input.value[id], id]);

  useEffect(() => {
    let ttlAmount = 0;
    Object.values(selectedDGItemsField.input.value).forEach(({ amount }) => {
      ttlAmount = ttlAmount + Number(amount);
    });
    amountField.input.onChange(ttlAmount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDGItemsField.input.value[id]]);

  return (
    <Fragment>
      <div
        className={classes.card}
        onClick={
          taken || is_day_picker ? null : () => setSelected(prev => !prev)
        }
      >
        {displayAvatar && (
          <Avatar color="primary" className={classes.avatar} src={avatar}>
            {/* KEEP THIS to remove default */}{" "}
          </Avatar>
        )}

        <div className={classes.amount}>{formatMoneyUnPadded(amount)}</div>
        <div className={classes.label}>{label}</div>

        {taken && (
          <div className={classes.takenWrapper}>
            <div className={classes.taken}>
              {taken_label} {taken_name}
            </div>
          </div>
        )}

        {dayPickerSelectedDays && (
          <div className={classes.selectedDays}>{dayPickerSelectedDays}</div>
        )}

        {is_day_picker && (
          <Button
            variant="contained"
            color="primary"
            className={classes.pickerButton}
            onClick={() => setShowDayPicker(true)}
          >
            Select a day
          </Button>
        )}
      </div>
      {is_day_picker && (
        <DonationGroupItemDayPicker
          isOpen={showDayPicker}
          toggle={setShowDayPicker}
          item={item}
          setDayPickerSelectedDays={setDayPickerSelectedDays}
        />
      )}
    </Fragment>
  );
}

const styles = makeStyles(theme => ({
  card: {
    width: ({ tier1, tier2 }) => (tier1 ? 266 : tier2 ? 208 : 170),
    minHeight: 170,
    border: ({ selected }) =>
      selected
        ? `3px solid ${theme.palette.primary.main}`
        : `1px solid ${theme.palette.text.secondary}`,
    backgroundColor: ({ selected }) =>
      selected ? hexToRGBA(theme.palette.primary.main, 0.09) : "#FFFFFF",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    marginLeft: 24,
    marginBottom: 16,
    opacity: ({ taken }) => (taken ? 0.7 : 1),
    cursor: ({ taken }) => (taken ? "inherit" : "pointer"),
    paddingBottom: ({ tier1 }) => (tier1 ? 24 : 16),
    paddingRight: ({ tier1 }) => (tier1 ? 24 : 16),
    paddingLeft: ({ tier1 }) => (tier1 ? 24 : 16),
    paddingTop: ({ displayAvatar, tier1, tier2 }) =>
      displayAvatar && tier1
        ? 66
        : displayAvatar && tier2
        ? 56
        : displayAvatar
        ? 44
        : 16,
    marginTop: ({ displayAvatar, tier1, tier2 }) =>
      !displayAvatar ? 0 : tier1 ? 50 : tier2 ? 40 : 28,
    [theme.breakpoints.down("xs")]: {
      width: () => 156,
      maxWidth: "48%",
      marginLeft: 0,
      marginBottom: 15,
      minHeight: 80,
      marginTop: ({ displayAvatar, tier1, tier2 }) =>
        !displayAvatar ? 0 : tier1 ? 40 : tier2 ? 34 : 27,
      paddingBottom: () => 12,
      paddingRight: () => 12,
      paddingLeft: () => 12,
      paddingTop: ({ displayAvatar, tier1, tier2 }) =>
        displayAvatar && tier1
          ? 52
          : displayAvatar && tier2
          ? 46
          : displayAvatar
          ? 39
          : 12,
    },
  },
  avatar: {
    position: "absolute",
    backgroundColor: "#FFFFFF",
    border: ({ selected }) =>
      selected
        ? `3px solid ${theme.palette.primary.main}`
        : `1px solid ${theme.palette.text.secondary}`,
    width: ({ tier1, tier2 }) => (tier1 ? 100 : tier2 ? 80 : 56),
    height: ({ tier1, tier2 }) => (tier1 ? 100 : tier2 ? 80 : 56),
    top: ({ tier1, tier2 }) => (tier1 ? -50 : tier2 ? -40 : -28),
    [theme.breakpoints.down("xs")]: {
      width: ({ tier1, tier2 }) => (tier1 ? 80 : tier2 ? 68 : 54),
      height: ({ tier1, tier2 }) => (tier1 ? 80 : tier2 ? 68 : 54),
      top: ({ tier1, tier2 }) => (tier1 ? -40 : tier2 ? -34 : -27),
    },
  },
  label: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "15px",
    textAlign: "center",
    whiteSpace: "pre-wrap",
    [theme.breakpoints.down("xs")]: {
      fontSize: ({ tier1, tier2 }) => (tier1 ? 14 : tier2 ? 13 : 12),
      lineHeight: ({ tier1, tier2 }) =>
        tier1 ? "15px" : tier2 ? "14px" : "13px",
    },
  },
  amount: {
    color: theme.palette.secondary.main,
    fontSize: 32,
    fontWeight: 600,
    letterSpacing: 0.4,
    paddingBottom: 12,
    [theme.breakpoints.down("xs")]: {
      fontSize: ({ tier1, tier2 }) => (tier1 ? 24 : tier2 ? 20 : 16),
      letterSpacing: ({ tier1, tier2 }) => (tier1 ? 0.3 : tier2 ? 0.25 : 0.2),
    },
  },
  takenWrapper: {
    width: "100%",
    padding: 6,
    marginTop: 16,
    borderRadius: 8,
    backgroundColor: hexToRGBA(theme.palette.primary.main, 0.25),
  },
  taken: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.17,
    lineHeight: "18px",
    textAlign: "center",
    color: theme.palette.primary.main,
    [theme.breakpoints.down("xs")]: {
      fontSize: ({ tier1, tier2 }) => (tier1 ? 14 : tier2 ? 13 : 12),
      lineHeight: ({ tier1, tier2 }) =>
        tier1 ? "16px" : tier2 ? "15px" : "13px",
    },
  },
  pickerButton: {
    marginTop: ({ selected }) => (selected ? 8 : 22),
  },
  selectedDays: {
    textAlign: "center",
    color: theme.palette.primary.main,
    fontSize: ({ isHebrew }) => (isHebrew ? 14 : 12),
    marginTop: 8,
  },
}));
