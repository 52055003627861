import React, { Fragment } from "react";
import { connect, useDispatch } from "react-redux";
import { OnChange } from "react-final-form-listeners";
import { productActions } from "../../state";

const observerFields = [
  "pmt_type",
  "shipping_same_as_billing",
  "ship_to_school",
  "address",
  "city",
  "state",
  "zip",
  "shipping_address",
  "shipping_city",
  "shipping_state",
  "shipping_zip",
  "giftCards",
];
const Observer = ({ fieldName }) => {
  const dispatch = useDispatch();
  return (
    <OnChange name={fieldName}>
      {() => {
        dispatch(productActions.toggleOrderFeesResolved(false));
      }}
    </OnChange>
  );
};

function _TaxChangeObserver({ checkoutTriggered }) {
  if (!checkoutTriggered) return <Fragment />;
  return (
    <Fragment>
      {observerFields.map(of => (
        <Observer key={of} fieldName={of} />
      ))}
    </Fragment>
  );
}

export const TaxChangeObserver = connect(state => {
  return { checkoutTriggered: state.product.checkoutTriggered };
})(_TaxChangeObserver);
