import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import { useForm } from "react-final-form";
import classNames from "classnames";
import { CC_PAYMENT, formatCurrencyWithSymbol } from "../../lib";
import { EventTicketSummary } from "./EventTicketSummary";

function _DonationSummaryAndSubmit(props) {
  const {
    campaign: {
      isActive,
      tip_title,
      processing_fee_mandatory,
      processing_fee_title,
      processing_fee_paragraph,
      hasEvents,
    },
    submitting,
    disableSubmit,
    paymentType,
    validateFormAndOpenPayment,
    donationTotal,
    donationAmount,
    eventTicketTotal,
    forOrgIframe,
    orgEmail,
    formValues,
    formValues: { tip, processing_fee, hasInstallments, installments },
  } = props;
  const form = useForm();
  const [isCC, setIsCC] = useState(true);
  const [showFigures, setShowFigures] = useState(false);
  const classes = styles({ showFigures });

  useEffect(() => {
    setIsCC(paymentType === CC_PAYMENT);
  }, [paymentType]);

  useEffect(() => {
    if (tip || processing_fee || installments || hasEvents) {
      setShowFigures(true);
    } else setShowFigures(false);
  }, [tip, processing_fee, installments, hasEvents]);

  const handleAdminButton = () => {
    const {
      title,
      first_name,
      last_name,
      email,
      address,
      city,
      state,
      zip,
    } = form.getState().values;
    if (!title) form.change("title", "N/A");
    if (!first_name) form.change("first_name", " ");
    if (!last_name) form.change("last_name", " ");
    if (!address) form.change("address", " ");
    if (!city) form.change("city", " ");
    if (!state) form.change("state", " ");
    if (!zip) form.change("zip", " ");
    if (!email && orgEmail) form.change("email", orgEmail);

    if (!isCC) form.submit();
    else validateFormAndOpenPayment(form);
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        {showFigures && (
          <div className={classes.figures}>
            <EventTicketSummary formValues={formValues} />
            {hasEvents && (
              <div className={classes.figureSection}>
                <div className={classes.figureName}>Tickets subtotal</div>
                <div>
                  <div className={classes.figure}>
                    {formatCurrencyWithSymbol(eventTicketTotal)}
                  </div>
                </div>
              </div>
            )}
            {(!hasEvents || donationAmount > 0) && (
              <div className={classes.figureSection}>
                <div className={classes.figureName}>
                  {hasEvents && "Donation "}Subtotal
                </div>
                <div>
                  <div className={classes.figure}>
                    {formatCurrencyWithSymbol(donationAmount)}
                  </div>
                  {installments > 0 && (
                    <div className={classes.breakdown}>
                      {perMonthBreakdown(donationAmount, installments)}
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* TIP */}
            {tip > 0 && (
              <div className={classes.figureSection}>
                <div className={classes.figureName}>
                  {tip_title ? tip_title : "Tip"}
                </div>
                <div>
                  <div className={classes.figure}>
                    {formatCurrencyWithSymbol(tip)}
                  </div>
                  {installments > 0 && (
                    <div className={classes.breakdown}>
                      {perMonthBreakdown(tip, installments)}
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* PROCESSING FEE */}
            {processing_fee > 0 && (
              <div className={classes.processingFeeWrapper}>
                <div className={classes.processingFeeFigureSection}>
                  <div className={classes.figureName}>
                    {processing_fee_title
                      ? processing_fee_title
                      : "Processing fee"}
                  </div>

                  <div>
                    <div className={classes.figure}>
                      {formatCurrencyWithSymbol(processing_fee)}
                    </div>
                    {installments > 0 && (
                      <div className={classes.breakdown}>
                        {perMonthBreakdown(processing_fee, installments)}
                      </div>
                    )}
                  </div>
                </div>

                {processing_fee_mandatory && processing_fee_paragraph && (
                  <div className={classes.processingFeeTxt}>
                    {processing_fee_paragraph}
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        <div className={classes.chargeNowSection}>
          <div className={classes.chargeNowLabel}>
            Total{hasInstallments ? " to be charged now" : ""}
          </div>
          <div className={classes.chargeNow}>
            {chargeNow(donationTotal, hasInstallments, installments)}
          </div>
        </div>

        <div className={classes.submitWrapper}>
          <Button
            className={classes.submit}
            type={isCC ? "button" : "submit"}
            disabled={disableSubmit || !isActive}
            onClick={isCC ? () => validateFormAndOpenPayment(form) : null}
            color="secondary"
            variant="contained"
          >
            {submitting ? (
              <Fragment>
                <CircularProgress size={40} className={classes.loader} />
                Submitting...
              </Fragment>
            ) : (
              `${isCC ? "Enter payment info" : "Submit"}`
            )}
          </Button>
          {forOrgIframe && (
            <Button
              className={classNames(classes.submit, classes.adminButton)}
              type="button"
              disabled={disableSubmit || !isActive}
              onClick={handleAdminButton}
              color="secondary"
              variant="contained"
            >
              {submitting ? (
                <Fragment>
                  <CircularProgress size={40} className={classes.loader} />
                  Submitting...
                </Fragment>
              ) : (
                `Ignore empty fields & ${
                  isCC ? "Enter payment info" : "Submit"
                }`
              )}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export const DonationSummaryAndSubmit = connect(state => {
  const {
    campaign,
    donation: { paymentType, donationAmount, donationTotal, eventTicketTotal },
  } = state;
  return {
    campaign,
    paymentType,
    donationAmount,
    donationTotal,
    eventTicketTotal,
  };
})(_DonationSummaryAndSubmit);

function chargeNow(total, hasInstallments, installments) {
  if (!total || !hasInstallments || !installments) {
    return formatCurrencyWithSymbol(total);
  }
  const intValue = total * 100;
  const evenSplit = Math.floor(intValue / installments);
  const remainingCents = intValue - evenSplit * installments;
  const perMonth = evenSplit / 100;
  const firstPayment = perMonth + remainingCents / 100;
  return formatCurrencyWithSymbol(firstPayment);
}

function perMonthBreakdown(num, installments) {
  let perMonth = 0;
  if (num) {
    const intValue = num * 100;
    const evenSplit = Math.floor(intValue / installments);
    perMonth = evenSplit / 100;
  }
  return `(${formatCurrencyWithSymbol(perMonth)} x ${installments})`;
}

const styles = makeStyles(theme => ({
  wrapper: {
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    justifyContent: "center",
  },
  container: {
    width: "700px",
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: 732,
      padding: "0 16px",
    },
  },
  figures: {
    width: "100%",
    marginTop: 56,
    [theme.breakpoints.down("sm")]: {
      marginTop: 40,
    },
  },
  figureSection: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 24,
  },
  processingFeeWrapper: {
    marginBottom: 24,
  },
  processingFeeFigureSection: {
    display: "flex",
    justifyContent: "space-between",
  },
  figureName: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.25,
  },
  figure: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.25,
    textAlign: "right",
  },
  breakdown: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.2,
    textAlign: "right",
    paddingTop: 8,
    whiteSpace: "nowrap",
  },
  processingFeeTxt: {
    fontSize: 14,
    letterSpacing: 0.17,
    lineHeight: "18px",
    marginTop: 8,
    maxWidth: 450,
  },
  chargeNowSection: {
    borderTop: `1px solid ${theme.palette.text.secondary}`,
    paddingTop: 24,
    marginTop: ({ showFigures }) => (showFigures ? 0 : 56),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      marginTop: ({ showFigures }) => (showFigures ? 0 : 40),
    },
  },
  chargeNowLabel: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.25,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      letterSpacing: 0.2,
    },
  },
  chargeNow: {
    color: theme.palette.primary.main,
    fontSize: 32,
    fontWeight: 600,
    letterSpacing: 0.4,
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
      letterSpacing: 0.3,
    },
  },
  submitWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    marginTop: 48,
    [theme.breakpoints.down("sm")]: {
      marginTop: 32,
      width: "100%",
    },
  },
  submit: {
    height: 56,
    width: "100%",
    borderRadius: 28,
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.25,
  },
  loader: {
    position: "absolute",
    color: theme.palette.secondary.contrastText,
  },
  adminButton: {
    fontSize: 18,
    marginTop: 24,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
}));
