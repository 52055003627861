import { makeStyles } from "@material-ui/core";

export const toastStyles = makeStyles(_theme => ({
  root: {
    zIndex: 9999999,
  },
  toast: {
    backgroundColor: "red",
  },
}));
