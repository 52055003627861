export function socialMediaTextPrefill({
  link = "",
  orgName = "",
  withUrl = false,
  includeGreeting = false,
}) {
  let text = includeGreeting ? `Hello,\n\n` : "";
  text = `${text}${orgName} is raising funds.\nPlease take a moment to check out their campaign page.\n`;
  if (withUrl) text = `${text}${link}\n\n`;
  text = `${text}Your support will be greatly appreciated.`;
  return encodeURIComponent(text);
}
