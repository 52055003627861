import React, { Fragment } from "react";
import {
  Container,
  makeStyles,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { connect } from "react-redux";
import { useField } from "react-final-form";
import { formatMoneyInput } from "../../lib";
import { DonationGroupItemCard } from "./DonationGroupItemCard";
import { PaymentSchedule } from "./PaymentSchedule";

export function _RegDonationInfoWithGroupItems(props) {
  const {
    campaign: {
      allow_custom_amt,
      donationGroup: {
        header,
        display_tier_1_avatars,
        display_tier_2_avatars,
        display_tier_3_avatars,
        tier_1_subheader,
        tier_2_subheader,
        tier_3_subheader,
        taken_label,
        tier1 = [],
        tier2 = [],
        tier3 = [],
      } = {},
    },
    formValues,
    formValues: { customAmount },
  } = props;
  const classes = styles();
  const customAmountInput = useField("customAmount").input;

  const handleCustomDonationChange = e => {
    const formattedVal = formatMoneyInput(e.target.value);
    customAmountInput.onChange(formattedVal);
  };

  return (
    <div className={classes.wrapper}>
      <Container>
        <div className={classes.container}>
          <div className={classes.header}>{header}</div>

          <div className={classes.tiers}>
            {tier1.length > 0 && (
              <Fragment>
                {tier_1_subheader && (
                  <div className={classes.tierSubheader}>
                    {tier_1_subheader}
                  </div>
                )}
                <div className={classes.tierSection}>
                  {tier1.map(item => (
                    <DonationGroupItemCard
                      key={item.id}
                      tier1
                      item={item}
                      displayAvatar={display_tier_1_avatars}
                      taken_label={taken_label}
                    />
                  ))}
                </div>
              </Fragment>
            )}

            {tier2.length > 0 && (
              <Fragment>
                {tier_2_subheader && (
                  <div className={classes.tierSubheader}>
                    {tier_2_subheader}
                  </div>
                )}
                <div className={classes.tierSection}>
                  {tier2.map(item => (
                    <DonationGroupItemCard
                      key={item.id}
                      tier2
                      item={item}
                      displayAvatar={display_tier_2_avatars}
                      taken_label={taken_label}
                    />
                  ))}
                </div>
              </Fragment>
            )}

            {tier3.length > 0 && (
              <Fragment>
                {tier_3_subheader && (
                  <div className={classes.tierSubheader}>
                    {tier_3_subheader}
                  </div>
                )}
                <div className={classes.tierSection}>
                  {tier3.map(item => (
                    <DonationGroupItemCard
                      key={item.id}
                      tier3
                      item={item}
                      displayAvatar={display_tier_3_avatars}
                      taken_label={taken_label}
                    />
                  ))}
                </div>
              </Fragment>
            )}
          </div>

          {allow_custom_amt && (
            <div className={classes.customInputWrapper}>
              <TextField
                label="Enter custom amount"
                className={classes.customInput}
                value={customAmount || ""}
                onChange={handleCustomDonationChange}
                InputProps={{
                  autoComplete: "off",
                  startAdornment: customAmount ? (
                    <InputAdornment position="start">$</InputAdornment>
                  ) : null,
                }}
                type="number"
              />
            </div>
          )}

          <PaymentSchedule formValues={formValues} />
        </div>
      </Container>
    </div>
  );
}

export const RegDonationInfoWithGroupItems = connect(state => {
  return { campaign: state.campaign };
})(_RegDonationInfoWithGroupItems);

const styles = makeStyles(theme => ({
  wrapper: {
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "#FFFFFF",
    padding: "48px 0",
    [theme.breakpoints.only("md")]: {
      padding: "48px 16px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "24px 16px",
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  header: {
    fontWeight: 600,
    fontSize: 32,
    letterSpacing: 0.4,
    textAlign: "center",
    whiteSpace: "pre-wrap",
    lineHeight: "35px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
      letterSpacing: 0.3,
      lineHeight: "28px",
    },
  },
  tiers: {
    paddingTop: "36px",
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "24px",
    },
  },
  tierSection: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    margin: "0 0 0 -24px",
    width: "calc(100% + 24px)",
    paddingBottom: 12,
    [theme.breakpoints.down("xs")]: {
      margin: "0 0 0 0",
      maxWidth: "100%",
      width: 328,
      justifyContent: "space-between",
    },
  },
  tierSubheader: {
    margin: "10px 0 16px 0",
    textAlign: "center",
    fontSize: 20,
    lineHeight: "22px",
    fontWeight: 600,
    letterSpacing: 0.25,
    whiteSpace: "pre-wrap",
    [theme.breakpoints.down("xs")]: {
      textAlign: "start",
    },
  },
  customInputWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 16,
  },
  customInput: {
    width: 280,
  },
}));
