import { makeStyles } from "@material-ui/core";

export const rulesAndDisclaimersStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    margin: "24px 0",
    [theme.breakpoints.down("md")]: {
      padding: "0 16px",
    },
  },
  disabled: {
    opacity: 0.4,
  },
  rulesLinkWrapper: {
    textAlign: "center",
    marginTop: 16,
  },
  rulesLink: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0,
    textAlign: "center",
    textDecoration: "underline",
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  disabledLink: {
    cursor: "unset",
  },
}));
