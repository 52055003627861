import React, { useState, useEffect } from "react";
import {
  Container,
  makeStyles,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import { useField } from "react-final-form";
import { donationActions } from "../../state/donation/actions";
import {
  formatMoneyInput,
  formatImpact,
  formatCurrencyWithSymbol,
} from "../../lib";
import { donationStyles } from "./Donation.styles";
import { PaymentSchedule } from "./PaymentSchedule";

export function _MatchingInfo(props) {
  const {
    campaign: {
      suggested_amounts,
      matching_times,
      isBonusRound,
      bonus_matching_times,
      isBonusMatching,
    },
    formValues,
    matchingBarAmount,
    donationAmount,
  } = props;
  const classes = styles();
  const donationClasses = donationStyles();
  const amountInput = useField("amount").input;
  const customAmountInput = useField("customAmount").input;
  const [hasSuggestions, setHasSuggestions] = useState(false);
  const [suggestionsObj, setSuggestionsObj] = useState({});
  const matchingX = isBonusRound ? bonus_matching_times : matching_times;
  const isMatching = !isBonusRound || isBonusMatching ? true : false;

  useEffect(() => {
    if (Array.isArray(suggested_amounts) && suggested_amounts.length) {
      setHasSuggestions(true);
      const amounts = {};
      suggested_amounts.forEach(({ amount }) => (amounts[amount] = amount));
      setSuggestionsObj(amounts);
    }
  }, [suggested_amounts]);

  useEffect(() => {
    if (!matchingBarAmount) {
      amountInput.onChange("");
      customAmountInput.onChange("");
    } else if (suggestionsObj[matchingBarAmount]) {
      amountInput.onChange(matchingBarAmount);
      customAmountInput.onChange("");
    } else {
      amountInput.onChange("");
      customAmountInput.onChange(matchingBarAmount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchingBarAmount, suggestionsObj]);

  return (
    <div className={donationClasses.donationSectionWrapper}>
      <Container>
        <div className={donationClasses.donationSectionContent}>
          <div className={donationClasses.donationSectionHeader}>
            Donate now
          </div>

          {hasSuggestions && (
            <div className={donationClasses.cardContainer}>
              {suggested_amounts.map(({ amount, text }, index) => {
                return <Card key={index} amount={amount} text={text} />;
              })}
              <CustomCard />
            </div>
          )}

          {!hasSuggestions && <CustomCard noSuggestions />}

          {donationAmount > 0 && isMatching && (
            <div className={classes.ttlImpact}>
              {`Your total impact is $${formatImpact(
                donationAmount,
                matchingX,
              )}`}
            </div>
          )}

          <PaymentSchedule formValues={formValues} />
        </div>
      </Container>
    </div>
  );
}

export const MatchingInfo = connect(state => {
  const {
    campaign,
    donation: { matchingBarAmount, donationAmount },
  } = state;
  return { campaign, matchingBarAmount, donationAmount };
})(_MatchingInfo);

const styles = makeStyles(theme => ({
  ttlImpact: {
    paddingTop: 16,
    fontSize: 32,
    fontWeight: 600,
    letterSpacing: 0.4,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: 22,
      letterSpacing: 0.3,
      lineHeight: "24px",
    },
  },
}));

function Card({ amount, text }) {
  const amountInput = useField("amount").input;
  const customAmountInput = useField("customAmount").input;
  const activeAmount = amountInput.value;
  const isSelected = Number(amount) === Number(activeAmount);
  const classes = donationStyles({ isSelected });
  const dispatch = useDispatch();

  function handleClick() {
    const newVal = isSelected ? null : amount;
    amountInput.onChange(newVal);
    customAmountInput.onChange("");
    dispatch(donationActions.setMatchingDonationSyncAmount(newVal));
  }

  return (
    <div className={classes.card} onClick={handleClick}>
      <div className={classes.amount}>{formatCurrencyWithSymbol(amount)}</div>
      {text && <div className={classes.txt}>{text}</div>}
    </div>
  );
}

function CustomCard({ noSuggestions }) {
  const customAmountField = useField("customAmount").input;
  const customAmount = customAmountField.value;
  const amountInput = useField("amount").input;
  const isSelected = Boolean(customAmount);
  const classes = donationStyles({ isSelected });
  const dispatch = useDispatch();

  const onChange = e => {
    const formattedVal = formatMoneyInput(e.target.value);
    customAmountField.onChange(formattedVal);
    amountInput.onChange(null);
    dispatch(donationActions.setMatchingDonationSyncAmount(formattedVal));
  };

  if (noSuggestions) {
    return (
      <div className={classes.bigInputWrapper}>
        <TextField
          label="Enter amount"
          className={classes.bigInput}
          value={customAmount || ""}
          onChange={onChange}
          InputProps={{
            autoComplete: "off",
            startAdornment: customAmount ? (
              <InputAdornment position="start">$</InputAdornment>
            ) : null,
          }}
          type="number"
        />
      </div>
    );
  }

  return (
    <div className={classes.card}>
      <TextField
        size="small"
        className={classes.input}
        value={customAmount || ""}
        onChange={onChange}
        InputProps={{
          autoComplete: "off",
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        type="number"
      />
      <div className={classes.txt}>Custom amount</div>
    </div>
  );
}
