import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { campaignReducers } from "./campaign/reducers";
import { donationReducers } from "./donation/reducers";
import { volunteerReducers } from "./volunteer/reducers";
import { submissionReducers } from "./submission/reducers";
import { productReducers } from "./product/reducers";
import { uiReducers } from "./ui/reducers";
import { teamReducers } from "./team/reducers";
import { composeWithDevTools } from "redux-devtools-extension";

const rootReducer = combineReducers({
  campaign: campaignReducers,
  donation: donationReducers,
  volunteer: volunteerReducers,
  submission: submissionReducers,
  product: productReducers,
  ui: uiReducers,
  team: teamReducers,
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk)),
);

export { store };
