import React from "react";
import { IconButton } from "@material-ui/core";
import WhatsappIcon from "../../images/Whatsapp.svg";
import { connect } from "react-redux";
import { getUrls } from "../../state";
import { socialMediaStyles } from "./SocialMedia.styles";
import { socialMediaTextPrefill } from "./socialMediaTextPrefill";
import { AFFILIATE_SUB_CODES, timestamp } from "../../lib";

function _Whatsapp({ large, orgName, siteLink }) {
  const classes = socialMediaStyles();
  const link = `${siteLink}&as=${
    AFFILIATE_SUB_CODES.WHATSAPP
  }&at=${timestamp()}`;
  const text = socialMediaTextPrefill({
    link,
    orgName,
    withUrl: true,
  });

  return (
    <IconButton
      href={`https://wa.me/?text=${text}`}
      target="_blank"
      className={large ? classes.largeButton : classes.button}
    >
      <img
        src={WhatsappIcon}
        alt="whatsapp"
        className={large ? classes.largeIcon : classes.icon}
      ></img>
    </IconButton>
  );
}

export const Whatsapp = connect(state => {
  const { fullHomeUrlWithAffiliate } = getUrls(state);
  return {
    orgName: state.campaign.name,
    siteLink: fullHomeUrlWithAffiliate,
  };
})(_Whatsapp);
