import React, { useEffect, useState } from "react";
import {
  Dialog,
  Button,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import { useForm } from "react-final-form";
const recaptchaElId = "recaptcha-v2-el";

export function RecaptchaV2Challenge({ isOpen }) {
  const [token, setToken] = useState(null);
  const [disable, setDisable] = useState(false);
  const classes = styles();
  const form = useForm();
  const submitting = form.getState().submitting;

  useEffect(() => {
    if (isOpen) {
      window.grecaptcha.enterprise.ready(() => {
        window.grecaptcha.enterprise.render(recaptchaElId, {
          sitekey: process.env.REACT_APP_RECAPTCHA_V2_SITE_KEY,
          callback: _token => setToken(_token),
          "expired-callback": () => setToken(null),
        });
      });
    }
  }, [isOpen]);

  const onSubmit = () => {
    setDisable(true);
    form.change("recaptchaToken", token);
    form.change("v2RecaptchaValidation", true);
    form.submit();
  };

  return (
    <Dialog
      open={isOpen}
      maxWidth={false}
      PaperProps={{ classes: { root: classes.paper } }}
    >
      <div className={classes.modal}>
        <div className={classes.text1}>For added security,</div>
        <div className={classes.text2}>
          please complete the challenge below and press SUBMIT
        </div>
        <div className={classes.challengeContainer}>
          <div id={recaptchaElId} />
        </div>

        <Button
          variant="contained"
          color="secondary"
          disabled={!Boolean(token) || disable}
          onClick={onSubmit}
        >
          SUBMIT
          {submitting && (
            <CircularProgress
              className={classes.spinner}
              color="primary"
              size={30}
            />
          )}
        </Button>
      </div>
    </Dialog>
  );
}

const styles = makeStyles(theme => ({
  modal: {
    width: 460,
    maxWidth: "100%",
    padding: 24,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "24px 6px",
    },
  },
  paper: { margin: 0, maxWidth: "unset" },
  text1: {
    textAlign: "center",
    fontSize: 18,
    paddingBottom: 12,
  },
  text2: {
    textAlign: "center",
    fontSize: 14,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  challengeContainer: {
    margin: "24px 0",
  },
  spinner: {
    position: "absolute",
  },
}));
