import React from "react";
import { connect } from "react-redux";
import { getUrls } from "../../state";
import { Helmet } from "react-helmet";
import { hostTitle } from "../../lib";

function _MetaTags({ campaign, siteUrl }) {
  const {
    name: orgName = "",
    first_name,
    last_name,
    banner,
    social_media_banner,
    no_volunteer_pictures,
    volunteerPicture,
    volunteerId,
  } = campaign;
  let title = hostTitle;
  if (orgName) title = orgName;
  if (volunteerId) title = `${title} - ${first_name} ${last_name}`;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={`Support ${orgName}`} />
      <meta property="og:url" content={siteUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={`Support ${orgName}`} />
      <meta
        property="og:image"
        content={
          volunteerPicture && !no_volunteer_pictures
            ? volunteerPicture
            : social_media_banner
            ? social_media_banner
            : banner
        }
      />
      <meta name="twitter:card" content="summary"></meta>
    </Helmet>
  );
}

export const MetaTags = connect(state => {
  const { campaign } = state;
  const { fullHomeUrl } = getUrls(state);
  return {
    campaign,
    siteUrl: fullHomeUrl,
  };
})(_MetaTags);
