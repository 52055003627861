import React, { Fragment, useEffect, useState } from "react";
import {
  MenuItem,
  TextField as MuiTextField,
  useMediaQuery,
} from "@material-ui/core";
import { Field, useForm } from "react-final-form";
import { TextField } from "final-form-material-ui";
import { OnChange } from "react-final-form-listeners";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import classnames from "classnames";
import { donationActions } from "../../state";
import { required } from "../../lib/validators";
import { getPaymentTypeOptions } from "../../lib/optionGenerators";
import { AdminVolunteerAutocomplete } from "..";
import { donationFormStyles } from "./DonationForm.styles";
import { formatMaxChars } from "../../lib";

function _AdditionalInfo(props) {
  const {
    campaign: {
      volunteer_label = "",
      add_solicited_by_class,
      remove_solicited_by,
      class_options,
      allow_frontend_pledges,
      name,
      no_cash_donations,
    },
    actions,
    isDisplayingDonations,
    isGivver,
    isProduct,
    forVolunteer,
    iframeCanSubmitPledges,
    adminIframe,
    forOrgIframe,
    isShipToSchool,
    setAdminVolunteerId,
    authToken,
    formValues,
  } = props;
  const { comments } = formValues;
  const form = useForm();
  const smallDevice = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const classes = donationFormStyles({ isProduct });
  const [solicitedRequired, setSolicitedRequired] = useState(false);
  const [solicitedLabel, setSolicitedLabel] = useState("");

  useEffect(() => {
    setSolicitedRequired(Boolean(isProduct && isShipToSchool && !adminIframe));
    setSolicitedLabel(
      isGivver ? (
        "Solicited by"
      ) : (
        <span>
          {`First & Last Name of `}
          <span className={classes.volLabel}>{volunteer_label}</span>
        </span>
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProduct, isShipToSchool, adminIframe, isGivver, volunteer_label]);

  useEffect(() => {
    if (form.getFieldState("solicited_by")) {
      form.resetFieldState("solicited_by");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [solicitedRequired]);

  // if it's not a Givver campaign && is a Volunteer page & not displaying donations & !allow_frontend_pledges this section will be empty
  if (
    !isGivver &&
    (forVolunteer || remove_solicited_by) &&
    !isDisplayingDonations &&
    !allow_frontend_pledges
  )
    return <></>;
  return (
    <Fragment>
      <div className={classes.formSubHeader}>Additional info</div>

      {!no_cash_donations &&
        (forOrgIframe || (!isProduct && allow_frontend_pledges)) && (
          <Fragment>
            <Field
              name="pmt_type"
              label="Payment Method"
              select
              component={TextField}
              validate={required}
              className={classnames(classes.input, classes.fullWidthInput)}
            >
              {getPaymentTypeOptions({
                forAdmin: forOrgIframe,
                allowAdminPledges: iframeCanSubmitPledges,
                allowFrontendPledges: allow_frontend_pledges,
              }).map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </Field>
            <OnChange name="pmt_type">
              {value => actions.setPaymentType(value)}
            </OnChange>
          </Fragment>
        )}

      {isDisplayingDonations && !isGivver && (
        <Field
          name="comments"
          fullWidth={true}
          multiline={true}
          minRows={smallDevice ? 3 : 1}
          label="Message to display (optional)"
          className={classnames(classes.input, classes.fullWidthInput)}
          type="textarea"
          render={({ input, ...rest }) => {
            return (
              <MuiTextField
                {...rest}
                {...input}
                helperText={`${
                  comments ? comments.length : 0
                } out of 85 characters`}
                value={input.value}
                onChange={e => {
                  input.onChange(formatMaxChars(e.target.value, 85));
                }}
              />
            );
          }}
        />
      )}

      {isGivver && (
        <Field
          name="comments_to_org"
          label={`Comments for ${name}`}
          type="textarea"
          fullWidth={true}
          multiline={true}
          component={TextField}
          className={classnames(
            classes.input,
            classes.fullWidthInput,
            classes.longLabel,
          )}
        />
      )}

      {!forVolunteer && !remove_solicited_by && (
        <Fragment>
          <Field
            name="solicited_by"
            label={solicitedLabel}
            component={TextField}
            className={classnames(classes.input, classes.fullWidthInput)}
            required={solicitedRequired}
            validate={solicitedRequired ? required : undefined}
          />

          {add_solicited_by_class &&
            Array.isArray(class_options) &&
            class_options.length > 0 && (
              <Field
                name="solicited_by_class"
                label={
                  <span className={classes.volLabel}>
                    {isGivver ? "" : `${volunteer_label}'s `}
                    class
                  </span>
                }
                select
                required
                component={TextField}
                validate={required}
                className={classnames(classes.input, classes.fullWidthInput)}
                type="text"
              >
                {class_options.map(option => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Field>
            )}
        </Fragment>
      )}

      {forOrgIframe && (
        <AdminVolunteerAutocomplete
          setAdminVolunteerId={setAdminVolunteerId}
          authToken={authToken}
        />
      )}
    </Fragment>
  );
}

export const AdditionalInfo = connect(
  state => {
    const {
      campaign,
      product: { isShipToSchool },
    } = state;
    return { campaign, isShipToSchool };
  },
  dispatch => {
    return { actions: bindActionCreators(donationActions, dispatch) };
  },
)(_AdditionalInfo);
