import React, { useState, useEffect, Fragment } from "react";
import { useMediaQuery, MenuItem } from "@material-ui/core";
import { Field, useField } from "react-final-form";
import { TextField } from "final-form-material-ui";
import classnames from "classnames";
import { connect } from "react-redux";
import { ImageUpload } from "..";
import { donationFormStyles } from "./DonationForm.styles";

function _DinnerInfo({ campaign, donationAmount }) {
  const {
    has_journal,
    journal_instructions,
    json_list: honorees,
    has_reservations,
    one_reservation_minimum,
    two_reservations_minimum,
  } = campaign;

  const classes = donationFormStyles();
  const smallDevice = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const [isReservationEligible, setIsReservationEligible] = useState(false);
  const [reservationNums, setReservationNums] = useState([1]);
  const gender1Field = useField("dinner_reservation_gender1");
  const title1Field = useField("dinner_reservation_title1");
  const name1Field = useField("dinner_reservation_name1");
  const gender2Field = useField("dinner_reservation_gender2");
  const title2Field = useField("dinner_reservation_title2");
  const name2Field = useField("dinner_reservation_name2");
  const imageField = useField("imageB64");

  useEffect(() => {
    if (!has_reservations) return;
    const oneMin = Number(one_reservation_minimum || 0);
    const twoMin = Number(two_reservations_minimum || 0);
    const eligible = donationAmount >= oneMin;
    const twoEligible = donationAmount >= twoMin;
    setIsReservationEligible(eligible);
    setReservationNums(twoEligible ? [1, 2] : [1]);
    if (!eligible) {
      gender1Field.input.onChange("");
      name1Field.input.onChange("");
      title1Field.input.onChange("");
    }
    if (!twoEligible) {
      gender2Field.input.onChange("");
      name2Field.input.onChange("");
      title2Field.input.onChange("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    one_reservation_minimum,
    two_reservations_minimum,
    donationAmount,
    has_reservations,
  ]);

  const imageUploadCallback = imageData => {
    imageField.input.onChange(imageData);
  };

  if (!has_journal && !has_reservations) return <Fragment />;
  return (
    <Fragment>
      {has_journal && (
        <Fragment>
          <div className={classes.formSubHeader}>Journal ad</div>
          {journal_instructions && (
            <div className={classes.journalInstructions}>
              {journal_instructions}
            </div>
          )}
          {Array.isArray(honorees) && honorees.length && (
            <Field
              name="additional_msg"
              label="In honor of"
              select
              component={TextField}
              className={classnames(classes.input, classes.fullWidthInput)}
            >
              <MenuItem value="">
                <em>No Honoree</em>
              </MenuItem>
              {honorees.map((option, i) => (
                <MenuItem key={i} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Field>
          )}
          <Field
            name="ad_text"
            label="Enter message here (not displayed online)"
            fullWidth={true}
            multiline={true}
            component={TextField}
            className={classnames(classes.input, classes.fullWidthInput)}
            InputLabelProps={{ className: classes.wideLabel }}
            InputProps={{
              classes: {
                inputMultiline: classes.textarea,
              },
            }}
            type="textarea"
            minRows={smallDevice ? 5 : 3}
          />
          <div className={classes.journalLogoLabel}>
            Logo for journal ad (optional)
          </div>
          <ImageUpload imageUploadCallback={imageUploadCallback} />
        </Fragment>
      )}

      {has_reservations && isReservationEligible && (
        <Fragment>
          <div className={classes.formSubHeader}>Dinner reservations</div>
          {reservationNums.map(num => {
            return (
              <div key={num} className={classes.inputGroup}>
                <div key={num} className={classes.splitInputSection}>
                  <Field
                    name={`dinner_reservation_gender${num}`}
                    label="Male/Female"
                    select
                    component={TextField}
                    className={classnames(
                      classes.input,
                      classes.genderAndTitle,
                    )}
                  >
                    {["", "Male", "Female"].map(option => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Field>
                  <Field
                    name={`dinner_reservation_title${num}`}
                    label="Title"
                    component={TextField}
                    className={classnames(
                      classes.input,
                      classes.genderAndTitle,
                    )}
                  />
                </div>
                <Field
                  name={`dinner_reservation_name${num}`}
                  label="Name"
                  component={TextField}
                  className={classes.input}
                />
              </div>
            );
          })}
        </Fragment>
      )}
    </Fragment>
  );
}

export const DinnerInfo = connect(state => {
  const {
    campaign,
    donation: { donationAmount },
  } = state;
  return { campaign, donationAmount };
})(_DinnerInfo);
