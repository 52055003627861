import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { connect } from "react-redux";
import {
  Tabs,
  Tab,
  Container,
  useMediaQuery,
  CircularProgress,
  Button,
  TextField,
  MenuItem,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import RefreshIcon from "@material-ui/icons/Refresh";
import NoDonationsImg from "../../images/NoDonations.svg";
import NoVolunteersImg from "../../images/NoVolunteers.svg";
import NoTeamsImg from "../../images/NoTeams.svg";
import { DonationList } from "./DonationList";
import { DonationSlider } from "./DonationSlider";
import { MemberList } from "./MemberList";
import { bindActionCreators } from "redux";
import debounce from "lodash.debounce";
import { donationActions, teamActions, volunteerActions } from "../../state";
import { participantContainerStyles } from "./ParticipantContainer.styles";
import { isProductCampaign } from "../../lib";

const LIMIT = 6;
const DONATIONS = "donations";
const TEAMS = "teams";
const VOLUNTEERS = "fundraisers";
const RECENT_SORT = { label: "Most recent", value: "recent" };
const HIGHEST_SORT = { label: "Highest to lowest", value: "highest" };
const ALPHA_SORT = { label: "Alphabetical", value: "alphabetical" };

function _ParticipantContainer(props) {
  const {
    isHomePage,
    forVolunteer,
    forTeam,
    loading,
    campaign: {
      campaignId,
      campaign_type_id,
      showProfitProgress,
      showVolunteerItemProgress,
      volunteerId,
      teamId,
      hide_donations,
      show_volunteer_donations,
      hide_teams,
      hide_volunteers,
      volunteer_label = "student",
    },
    donationActions: { getDonationList, clearDonationList },
    teamActions: { getTeams, clearTeams },
    volunteerActions: { getVolunteers, clearVolunteers },
    donationList,
    donationListCount,
    teams,
    teamCount,
    volunteers,
    volunteerCount,
  } = props;

  const classes = participantContainerStyles();
  const [activeTab, setActiveTab] = useState(null);
  const [tabs, setTabs] = useState(null);
  const [currCount, setCurrCount] = useState(0);
  const [currDataLength, setCurrDataLength] = useState(0);
  const [emptyImg, setEmptyImg] = useState();
  const [hasMore, setHasMore] = useState(false);
  const [donationSort, setDonationSort] = useState(RECENT_SORT.value);
  const [volunteerSort, setVolunteerSort] = useState(HIGHEST_SORT.value);
  const [teamSort, setTeamSort] = useState(HIGHEST_SORT.value);
  const [activeSort, setActiveSort] = useState("");
  const [sortOptions, setSortOptions] = useState([]);
  const [donationSearch, setDonationSearch] = useState("");
  const [volunteerSearch, setVolunteerSearch] = useState("");
  const [teamSearch, setTeamSearch] = useState("");
  const [activeSearch, setActiveSearch] = useState("");
  const [showAllDonations, setShowAllDonations] = useState(false);
  const [hasRecentDonations, setHasRecentDonations] = useState(false);
  const [fetchDataTrigger, setFetchDataTrigger] = useState(0);
  const refreshRef = useRef(false);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));
  const isWideScreen = useMediaQuery(theme => theme.breakpoints.up("md"));
  const isProduct = isProductCampaign(campaign_type_id);

  const debounceSearch = useMemo(
    () =>
      debounce(currFetchDataTrigger => {
        setCurrCount(0);
        setHasMore(false);
        setFetchDataTrigger(currFetchDataTrigger + 1);
      }, 300),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const fetchDonations = useCallback(() => {
    const offset =
      activeTab === DONATIONS && !refreshRef.current ? currCount : 0;
    const useTeamId = forTeam ? teamId : undefined;
    getDonationList(
      campaignId,
      campaign_type_id,
      Boolean(showProfitProgress),
      volunteerId,
      useTeamId,
      offset,
      LIMIT,
      donationListCount,
      donationSort,
      donationSearch,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    campaignId,
    volunteerId,
    teamId,
    currCount,
    activeTab,
    donationSort,
    donationSearch,
    forTeam,
  ]);

  const fetchVolunteers = useCallback(() => {
    const offset =
      activeTab === VOLUNTEERS && !refreshRef.current ? currCount : 0;
    const useTeamId = forTeam ? teamId : undefined;
    getVolunteers(
      campaignId,
      campaign_type_id,
      Boolean(showProfitProgress),
      showVolunteerItemProgress,
      useTeamId,
      offset,
      LIMIT,
      volunteerSort,
      volunteerSearch,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeTab,
    campaignId,
    teamId,
    currCount,
    volunteerSort,
    volunteerSearch,
  ]);

  const fetchTeams = useCallback(() => {
    const offset = activeTab === TEAMS && !refreshRef.current ? currCount : 0;
    getTeams(
      campaignId,
      campaign_type_id,
      Boolean(showProfitProgress),
      offset,
      LIMIT,
      teamSort,
      teamSearch,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, campaignId, currCount, teamSort, teamSearch]);

  const clearLists = () => {
    clearDonationList();
    clearTeams();
    clearVolunteers();
  };

  // INITIAL DATA LOAD
  useEffect(() => {
    // if (
    //   campaignId &&
    //   (!hide_donations || (forVolunteer && show_volunteer_donations))
    // )
    //   fetchDonations();
    if (isHomePage && campaignId && !hide_teams) fetchTeams();
    if (!forVolunteer && campaignId && !hide_volunteers) fetchVolunteers();
    return clearLists;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, hide_donations, hide_volunteers, hide_teams]);

  // TAB SETUP
  useEffect(() => {
    const tempTabs = [];
    if (!hide_donations || (forVolunteer && show_volunteer_donations)) {
      tempTabs.push(DONATIONS);
    }
    if (isHomePage && !hide_teams) tempTabs.push(TEAMS);
    if (!forVolunteer && !hide_volunteers) tempTabs.push(VOLUNTEERS);
    if (tempTabs.length) {
      setActiveTab(tempTabs[0]);
      setTabs(tempTabs);
      sortOptionsSetup(tempTabs[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hide_donations, hide_teams, hide_volunteers]);

  // SET ACTIVE-TAB INFO
  useEffect(() => {
    let length;
    switch (activeTab) {
      case DONATIONS:
        length = donationList.length;
        setCurrCount(length);
        setHasMore(length !== donationListCount);
        setEmptyImg(NoDonationsImg);
        setActiveSort(donationSort);
        setActiveSearch(donationSearch);
        setCurrDataLength(length);
        break;
      case TEAMS:
        length = teams.length;
        setCurrCount(length);
        setHasMore(length !== teamCount);
        setEmptyImg(NoTeamsImg);
        setActiveSort(teamSort);
        setActiveSearch(teamSearch);
        setCurrDataLength(length);
        break;
      case VOLUNTEERS:
        length = volunteers.length;
        setCurrCount(length);
        setHasMore(length !== volunteerCount);
        setEmptyImg(NoVolunteersImg);
        setActiveSort(volunteerSort);
        setActiveSearch(volunteerSearch);
        setCurrDataLength(length);
        break;
      default:
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeTab,
    donationList,
    donationListCount,
    teams,
    teamCount,
    volunteers,
    volunteerCount,
  ]);

  useEffect(() => {
    if (fetchDataTrigger) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDataTrigger]);

  const sortOptionsSetup = activeTab => {
    const _sortOptions = [HIGHEST_SORT, ALPHA_SORT];
    if (activeTab === DONATIONS) _sortOptions.unshift(RECENT_SORT);
    setSortOptions(_sortOptions);
  };

  const handleTabChange = (_e, newTab) => {
    // seems like a bug in MUI - Tab onChange is being triggered even when the curr tab is pressed again - so check if there was a change
    if (newTab !== activeTab) {
      setActiveTab(newTab);
      setCurrCount(0);
      setHasMore(false);
      sortOptionsSetup(newTab);
    }
  };

  const handleSortChange = e => {
    const newSort = e.target.value;
    switch (activeTab) {
      case DONATIONS:
        setDonationSort(newSort);
        break;
      case VOLUNTEERS:
        setVolunteerSort(newSort);
        break;
      case TEAMS:
        setTeamSort(newSort);
        break;
      default:
    }
    setCurrCount(0);
    setHasMore(false);
    setFetchDataTrigger(fetchDataTrigger + 1);
  };

  const handleSearchChange = e => {
    const value = e.target.value;
    switch (activeTab) {
      case DONATIONS:
        setDonationSearch(value);
        break;
      case VOLUNTEERS:
        setVolunteerSearch(value);
        break;
      case TEAMS:
        setTeamSearch(value);
        break;
      default:
    }
    setActiveSearch(value);
    debounceSearch(fetchDataTrigger);
  };

  const fetchData = (_, refresh = false) => {
    refreshRef.current = refresh;
    if (!campaignId || loading) return;
    if (activeTab === DONATIONS) fetchDonations();
    if (activeTab === TEAMS) fetchTeams();
    if (activeTab === VOLUNTEERS) fetchVolunteers();
  };

  const activeLabel = currTab => {
    let label = "";
    switch (currTab) {
      case VOLUNTEERS:
        label = `${volunteer_label}s`;
        break;
      case DONATIONS:
        label = isProduct ? "sales" : "donations";
        break;
      default:
        label = currTab;
        break;
    }
    return label;
  };

  const handleShowAllDonations = () => {
    fetchData(undefined, true);
    setShowAllDonations(true);
  };

  const Refresh = () => (
    <IconButton size="small" onClick={() => fetchData(undefined, true)}>
      <RefreshIcon className={classes.refresh} />
    </IconButton>
  );

  if (!tabs) {
    return <Fragment></Fragment>;
  }

  return (
    <div className={classes.container}>
      <Container>
        {tabs.length > 1 ? (
          <Tabs
            classes={{ root: classes.tabs }}
            value={activeTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            variant="standard"
            // scrollButtons="auto"
            centered={isMobile ? true : false}
          >
            {tabs.map(t => (
              <Tab
                key={t}
                className={classes.tab}
                value={t}
                label={activeLabel(t)}
              />
            ))}
          </Tabs>
        ) : (
          <div className={classes.singleTab}>{activeLabel(tabs[0])}</div>
        )}

        {activeTab === DONATIONS && (
          <DonationSlider
            showAllDonations={showAllDonations}
            handleShowAllDonations={handleShowAllDonations}
            setHasRecentDonations={setHasRecentDonations}
            forVolunteer={forVolunteer}
            forTeam={forTeam}
          />
        )}

        {(activeTab !== DONATIONS || showAllDonations) && (
          <div className={classes.sortAndFilter}>
            <div className={classes.searchAndRefresh}>
              <TextField
                className={classes.search}
                size="small"
                placeholder="Search"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                value={activeSearch}
                onChange={handleSearchChange}
              />
              {!isMobile && <Refresh />}
            </div>

            <div className={classes.rightActions}>
              {isMobile && <Refresh />}
              <div className={classes.sortWrapper}>
                <div className={classes.sortBy}>Sort by</div>
                <TextField
                  select
                  value={activeSort}
                  onChange={handleSortChange}
                  className={classes.sort}
                  size="small"
                >
                  {sortOptions.map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
          </div>
        )}

        {currDataLength > 0 && (
          <Fragment>
            <div className={classes.cardContainer}>
              {activeTab === DONATIONS && showAllDonations && <DonationList />}
              {(activeTab === TEAMS || activeTab === VOLUNTEERS) && (
                <MemberList type={activeTab} />
              )}
              {/* add a filler Card when the last row has 2 so they wont display "space-between" */}
              {isWideScreen && currDataLength % 3 === 2 && (
                <div className={classes.placeholderCard} />
              )}
            </div>
            {hasMore && (activeTab !== DONATIONS || showAllDonations) && (
              <div className={classes.seeMore}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.seeMoreBtn}
                  onClick={fetchData}
                >
                  {loading ? (
                    <CircularProgress size={20} className={classes.loader} />
                  ) : (
                    "View more"
                  )}
                </Button>
              </div>
            )}
          </Fragment>
        )}
        {currDataLength < 1 &&
          (activeTab !== DONATIONS ||
            showAllDonations ||
            !hasRecentDonations) && (
            <div className={classes.emptyState}>
              <img src={emptyImg} alt="empty" />
              <div className={classes.emptyStateTxt}>
                {activeSearch
                  ? "No results found"
                  : `No ${activeLabel(activeTab)} yet!`}
              </div>
            </div>
          )}
      </Container>
    </div>
  );
}

export const ParticipantContainer = connect(
  state => {
    const {
      campaign,
      ui: { loading },
      donation: { donationList, donationListCount },
      team: { teams, teamCount },
      volunteer: { volunteers, volunteerCount },
    } = state;
    return {
      campaign,
      loading,
      donationList,
      donationListCount,
      teams,
      teamCount,
      volunteers,
      volunteerCount,
    };
  },
  dispatch => {
    return {
      donationActions: bindActionCreators(donationActions, dispatch),
      teamActions: bindActionCreators(teamActions, dispatch),
      volunteerActions: bindActionCreators(volunteerActions, dispatch),
    };
  },
)(_ParticipantContainer);
