import React from "react";
import { Container, TextField, InputAdornment } from "@material-ui/core";
import { connect } from "react-redux";
import { useField } from "react-final-form";
import { formatCurrencyWithSymbol, formatMoneyInput } from "../../lib";
import { PaymentSchedule } from "./PaymentSchedule";
import { donationStyles } from "./Donation.styles";

export function _Tickets(props) {
  const { ticketPackages, ticket_limit, formValues } = props;
  const classes = donationStyles();

  return (
    <div className={classes.donationSectionWrapper}>
      <Container>
        <div className={classes.donationSectionContent}>
          <div className={classes.donationSectionHeader}>Ticket packages</div>

          {ticket_limit && (
            <div className={classes.ticketLimit}>
              Limited to approximately {Number(ticket_limit).toLocaleString()}{" "}
              tickets
            </div>
          )}

          <div className={classes.cardContainer}>
            {Array.isArray(ticketPackages) &&
              ticketPackages.map((ticket, index) => (
                <Card key={index} ticket={ticket} />
              ))}
            <CustomCard />
          </div>

          <PaymentSchedule formValues={formValues} />
        </div>
      </Container>
    </div>
  );
}

export const Tickets = connect(state => {
  const { campaign_ticket_packages, ticket_limit } = state.campaign;
  return { ticketPackages: campaign_ticket_packages, ticket_limit };
})(_Tickets);

function Card({ ticket }) {
  const { price, num_of_tickets: num, id, label } = ticket;
  const packageIdInput = useField("ticket_package_id").input;
  const amountInput = useField("amount").input;
  const activeId = packageIdInput.value;
  const isSelected = id === activeId;
  const classes = donationStyles({ isSelected });

  function handleClick() {
    const newVal = isSelected ? null : id;
    packageIdInput.onChange(newVal);
    amountInput.onChange("");
  }

  return (
    <div className={classes.card} onClick={handleClick}>
      <div className={classes.amount}>{formatCurrencyWithSymbol(price)}</div>
      <div className={classes.tickets}>
        {`${num} ${num > 1 ? "Tickets" : "Ticket"}`}
      </div>
      {label && <div className={classes.txt}>{label}</div>}
    </div>
  );
}

function CustomCard() {
  const packageIdInput = useField("ticket_package_id").input;
  const amountInput = useField("amount").input;
  const customAmount = amountInput.value;
  const isSelected = Boolean(customAmount);
  const classes = donationStyles({ isSelected });

  const onChange = e => {
    const formattedVal = formatMoneyInput(e.target.value);
    amountInput.onChange(formattedVal);
    packageIdInput.onChange(null);
  };

  return (
    <div className={classes.card}>
      <TextField
        size="small"
        className={classes.input}
        value={customAmount || ""}
        onChange={onChange}
        InputProps={{
          autoComplete: "off",
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        type="number"
      />
      <div className={classes.txt}>Custom amount</div>
    </div>
  );
}
