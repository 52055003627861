import React, { useEffect, useState, Fragment } from "react";
import { makeStyles, CircularProgress } from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import { useForm } from "react-final-form";
import { AUTHORIZE_DON_NET_EL_ID, PROCESSOR_ERROR_MSG } from "../../lib";
import { setToast } from "../../state";

function _AuthorizeDotNet(props) {
  const {
    donationError,
    disableRetry,
    closePaymentDialog,
    showPaymentForm,
  } = props;
  const classes = styles();
  const [submitting, setSubmitting] = useState(false);
  const form = useForm();
  const dispatch = useDispatch();
  const { applePayData } = form.getState().values;

  useEffect(() => {
    if (showPaymentForm) {
      // disableScroll();
      openPaymentForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPaymentForm]);

  useEffect(() => {
    if (donationError && !disableRetry) {
      setSubmitting(false);
      if (!applePayData) openPaymentForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [donationError, applePayData, disableRetry]);

  useEffect(() => {
    const handler = event => {
      const { data: { type, pktData = {} } = {}, origin } = event;
      if (origin === process.env.REACT_APP_AUTHORIZEDOTNET_IFRAME_BASE_URL) {
        if (type === "CLOSE_IFRAME") {
          closePaymentDialog();
        }
        if (type === "RESPONSE") {
          const {
            messages: { resultCode, message } = {},
            opaqueData: { dataValue } = {},
          } = pktData;
          if (resultCode === "Ok") {
            setSubmitting(true);
            form.change("applePayData", null); // VERY IMPORTANT: we need to remove apple pay data if they used it on an earlier try
            form.change("ccToken", dataValue);
            form.submit();
          } else {
            openPaymentForm();
            let errorMsg = PROCESSOR_ERROR_MSG;
            if (Array.isArray(message) && message.length) {
              errorMsg = message[0].text;
              dispatch(setToast(errorMsg));
            }
          }
        }
      }
    };

    window.addEventListener("message", handler);
    return () => {
      window.removeEventListener("message", handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openPaymentForm = () => {
    const ADNContainer = document.getElementById(AUTHORIZE_DON_NET_EL_ID);
    const AcceptUIContainer = document.getElementById("AcceptUIContainer");
    const AcceptUIBackground = document.getElementById("AcceptUIBackground");

    if (ADNContainer && AcceptUIContainer) {
      ADNContainer.click();
      AcceptUIContainer.style.position = "fixed";
      if (AcceptUIBackground) {
        AcceptUIBackground.style.display = "none";
      }
    } else {
      dispatch(setToast(PROCESSOR_ERROR_MSG));
    }
  };

  return (
    <Fragment>
      {submitting && (
        <div className={classes.submittingDialog}>
          <div className={classes.submitting}>
            <div>Submitting...</div>
            <CircularProgress
              className={classes.spinner}
              color="secondary"
              size={50}
            />
          </div>
        </div>
      )}
    </Fragment>
  );
}

export const AuthorizeDotNet = connect(state => {
  const {
    donation: { disableRetry, donationError },
  } = state;
  return {
    disableRetry,
    donationError,
  };
})(_AuthorizeDotNet);

const styles = makeStyles(theme => ({
  submittingDialog: {
    backgroundColor: "#FFFFFF",
    width: 550,
    maxWidth: "100%",
    height: 288,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      height: 344,
    },
  },
  submitting: {
    fontSize: 22,
    fontWeight: "bold",
    width: 50,
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  spinner: {
    position: "absolute",
  },
}));
