import React from "react";
import { Field, Form } from "react-final-form";
import {
  InputAdornment,
  IconButton,
  TextField,
  makeStyles,
  ClickAwayListener,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { APIClient } from "../../lib";
import {
  required,
  integerOnly,
  email,
  composeValidators,
} from "../../lib/validators";

export function LinkSearchCard({ label, url, placeholder, text, emailSearch }) {
  const classes = styles();

  const onSubmit = async ({ inputVal }) => {
    const response = await APIClient.get(
      `${url}${encodeURIComponent(inputVal)}`,
    );
    const { error, code, data, errorMessage } = response;
    if (error) {
      let msg = "Please try again later";
      if (code === 404) {
        if (emailSearch) msg = "Email not found, please check and try again";
        else msg = "Invalid ID, please check and try again";
      }
      // multiple accounts error
      else if (code === 109 && emailSearch) msg = errorMessage;
      return { inputVal: msg };
    }

    const a = document.createElement("a");
    a.setAttribute("href", data.link);
    // a.setAttribute("target", "_blank");
    a.click();
  };

  return (
    <Form
      onSubmit={onSubmit}
      validateOnBlur={false}
      render={props => {
        const { handleSubmit, submitting, form } = props;
        return (
          <ClickAwayListener
            onClickAway={() => form.resetFieldState("inputVal")}
          >
            <div className={classes.card}>
              <div className={classes.header}>{label}</div>
              <div className={classes.text}>{text}</div>
              <form onSubmit={handleSubmit} className={classes.form}>
                <Field
                  name="inputVal"
                  type={emailSearch ? "email" : "number"}
                  validate={composeValidators(
                    required,
                    emailSearch ? email : integerOnly,
                  )}
                  placeholder={placeholder}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton type="submit" disabled={submitting}>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  render={({ input, meta, ...rest }) => {
                    const showError =
                      ((meta.submitError && !meta.dirtySinceLastSubmit) ||
                        meta.error) &&
                      meta.touched;
                    return (
                      <TextField
                        {...rest}
                        {...input}
                        onBlur={null}
                        helperText={
                          showError ? meta.error || meta.submitError : undefined
                        }
                        error={showError}
                        className={classes.input}
                      />
                    );
                  }}
                />
              </form>
            </div>
          </ClickAwayListener>
        );
      }}
    />
  );
}

const styles = makeStyles(theme => ({
  card: {
    backgroundColor: "#ffffff",
    width: "300px",
    minHeight: "142px",
    margin: "24px",
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      margin: "14px",
    },
  },
  header: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 18,
  },
  form: { width: "100%" },
  input: { width: "100%" },
  text: {
    textAlign: "center",
    fontSize: 14,
    marginBottom: 12,
    marginTop: 6,
    lineHeight: "16px",
    color: "#5A5A5A",
  },
}));
