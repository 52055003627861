import React from "react";
import { Dialog, IconButton, useMediaQuery } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import parse from "html-react-parser";
import { rulesModalStyles } from "./RulesModal.styles";

function _RulesModal({ isOpen, toggleModal, rulesHtml }) {
  const isSmallDevice = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const classes = rulesModalStyles();
  return (
    <Dialog
      onClose={() => toggleModal(false)}
      open={isOpen}
      maxWidth={false}
      fullScreen={isSmallDevice}
    >
      <div className={classes.container}>
        <div className={classes.close}>
          <IconButton key="close" onClick={() => toggleModal(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={classes.content}>
          <div className={classes.title}>Rules and regulations</div>
          {rulesHtml && (
            <div /* needed for quill css */ className="quill">
              {parse(`${rulesHtml}`)}
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
}

export const RulesModal = connect(state => {
  return { rulesHtml: state.campaign.rules_and_regulations_html };
})(_RulesModal);
