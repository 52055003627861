import React, { useState, useEffect, Fragment } from "react";
import {
  TextField,
  InputAdornment,
  makeStyles,
  MenuItem,
} from "@material-ui/core";
import { useField } from "react-final-form";
import { connect, useDispatch } from "react-redux";
import { donationFormStyles } from "./DonationForm.styles";
import {
  calculatePercentOfNum,
  formatCurrencyDisplay,
  hexToRGBA,
  isProductCampaign,
} from "../../lib";
import classNames from "classnames";
import { productActions } from "../../state";
const MODES = {
  default: "Default",
  other: "Other",
  custom: "Custom",
};

function _Tips({
  isProduct,
  campaign,
  tipAbleAmount,
  hasOrderItems,
  hasProductDonation,
}) {
  const {
    tip_title = "",
    tip_paragraph = "",
    tip_percent_options: TPO,
  } = campaign;

  const parentClasses = donationFormStyles({ isProduct });
  const classes = styles();
  const dispatch = useDispatch();
  const [mode, setMode] = useState(MODES.default);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [dropdownMap, setDropdownMap] = useState({});
  const [dropdownVal, setDropdownVal] = useState("");
  const [defaultPercent, setDefaultPercent] = useState(3);
  const [defaultLabel, setDefaultLabel] = useState("");
  const [customTip, setCustomTip] = useState("0");
  const tipField = useField("tip");

  useEffect(() => {
    let _defaultPercent = 0;
    let _defaultLabel = "";
    const DDMap = {};
    const DDOptions = [];
    if (Array.isArray(TPO) && TPO.length) {
      const _defaultIdx = TPO.findIndex(({ defaultOption }) => defaultOption);
      const defaultIdx = _defaultIdx > -1 ? _defaultIdx : 0;
      TPO.forEach(({ percent }, index) => {
        const amount = formatCurrencyDisplay(
          calculatePercentOfNum(tipAbleAmount, percent),
        );
        const label = `${percent}% ($${amount})`;
        if (index === defaultIdx) {
          _defaultPercent = percent;
          _defaultLabel = label;
        } else {
          DDOptions.push(percent);
          DDMap[percent] = label;
        }
      });
    } else {
      _defaultPercent = 2;
      [2, 3, 5].forEach((percent, i) => {
        const amount = formatCurrencyDisplay(
          calculatePercentOfNum(tipAbleAmount, percent),
        );
        const label = `${percent}% ($${amount})`;
        if (i === 0) _defaultLabel = label;
        else {
          DDOptions.push(percent);
          DDMap[percent] = label;
        }
      });
    }
    setDefaultLabel(_defaultLabel);
    setDefaultPercent(_defaultPercent);
    setDropdownMap(DDMap);
    setDropdownOptions(DDOptions);
  }, [TPO, tipAbleAmount]);

  useEffect(() => {
    if (isProduct && hasOrderItems) {
      tipField.input.onChange(null);
      return;
    }
    switch (mode) {
      case MODES.custom:
        tipField.input.onChange(customTip);
        break;
      case MODES.other:
        setCustomTip("0");
        tipField.input.onChange(
          calculatePercentOfNum(tipAbleAmount, dropdownVal),
        );
        break;
      default:
        setDropdownVal("");
        setCustomTip("0");
        tipField.input.onChange(
          calculatePercentOfNum(tipAbleAmount, defaultPercent),
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mode,
    tipAbleAmount,
    dropdownVal,
    customTip,
    defaultPercent,
    isProduct,
    hasOrderItems,
  ]);

  useEffect(() => {
    if (isProduct) {
      dispatch(
        productActions.updateOrderFigures({
          productDonationTip: Number(tipField.input.value),
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProduct, tipField.input.value]);

  const onDropdownChange = e => {
    const val = e.target.value;
    setDropdownVal(val);
    setMode(val === MODES.custom ? MODES.custom : MODES.other);
  };

  const handleCustomTipChange = e => {
    setCustomTip(formatTipInput(e.target.value));
  };

  if (isProduct && (hasOrderItems || !hasProductDonation)) return <></>;
  return (
    <Fragment>
      <div className={parentClasses.formSubHeader}>{tip_title}</div>
      <div className={classes.paragraph}>{tip_paragraph}</div>
      <div className={classes.content}>
        <div className={classes.left}>
          <div
            className={classNames(
              classes.default,
              mode === MODES.default && classes.activeDefault,
            )}
            onClick={() => {
              setMode(MODES.default);
              setDropdownVal("");
            }}
          >
            {defaultLabel}
          </div>
          <TextField
            select
            value={dropdownVal}
            onChange={onDropdownChange}
            className={classNames(
              classes.select,
              mode !== MODES.default && classes.activeSelect,
            )}
            SelectProps={{
              displayEmpty: true,
              renderValue: selected => {
                if (selected === "") {
                  return <span className={classes.secondaryTxt}>Other</span>;
                }
                if (selected === MODES.custom) return MODES.custom;
                return dropdownMap[selected] || "";
              },
              classes: {
                root: classNames(mode !== MODES.default && classes.active),
                icon: classes.secondaryTxt,
              },
            }}
          >
            {dropdownOptions.map(percent => (
              <MenuItem key={percent} value={percent}>
                {dropdownMap[percent] || ""}
              </MenuItem>
            ))}
            <MenuItem value={MODES.custom}>{MODES.custom}</MenuItem>
          </TextField>
        </div>

        {mode === MODES.custom && (
          <TextField
            className={classes.custom}
            value={customTip}
            onChange={handleCustomTipChange}
            type="number"
            InputProps={{
              autoComplete: "off",
              startAdornment: (
                <InputAdornment position="start">
                  <span className={classes.secondaryTxt}>$</span>
                </InputAdornment>
              ),
            }}
          />
        )}
      </div>
    </Fragment>
  );
}

export const Tips = connect(state => {
  const {
    campaign,
    donation: { donationAmount, eventTicketTotal },
    product: { orderItemCount, productDonation },
  } = state;
  const isProduct = isProductCampaign(campaign.campaign_type_id);
  const tipAbleAmount = isProduct
    ? Number(productDonation)
    : Number((Number(donationAmount) + Number(eventTicketTotal)).toFixed(2));
  return {
    campaign,
    tipAbleAmount,
    hasProductDonation: Number(productDonation) > 0,
    hasOrderItems: Number(orderItemCount) > 0,
  };
})(_Tips);

function formatTipInput(value) {
  if (!value) return "";
  return value.replace(
    /^0*(\d*)(\.\d{0,2})?.*$/,
    (_, dollars, cents) => `${dollars || "0"}${cents || ""}`,
  );
}

const styles = makeStyles(theme => ({
  paragraph: {
    marginTop: 18,
    marginBottom: 16,
    color: hexToRGBA(theme.palette.text.primary, 0.87),
    fontSize: 16,
    letterSpacing: 0.17,
    lineHeight: "20px",
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  left: {
    display: "flex",
    width: "calc(50% - 12px)",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "100%",
    },
  },
  default: {
    height: 56,
    width: "calc(50% - 12px)",
    borderRadius: 4,
    border: `1px solid ${theme.palette.text.secondary}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.text.secondary,
    fontSize: 16,
    letterSpacing: 0,
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      width: "calc(50% - 8px)",
    },
  },
  activeDefault: {
    border: `3px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
    fontWeight: 600,
  },
  select: {
    width: "calc(50% - 12px)",
    [`& fieldset`]: { borderRadius: 4 },
    [theme.breakpoints.down("sm")]: {
      width: "calc(50% - 8px)",
    },
  },
  activeSelect: {
    [`& fieldset`]: {
      border: `3px solid ${theme.palette.secondary.main} !important`,
      color: theme.palette.secondary.main,
    },
  },
  active: { color: theme.palette.secondary.main, fontWeight: 500 },
  custom: {
    width: "calc(50% - 12px)",
    borderRadius: 4,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: 16,
    },
  },
  secondaryTxt: {
    color: theme.palette.text.secondary,
  },
}));
