import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import FullScreenIcon from "@material-ui/icons/Fullscreen";
import FullScreenExitIcon from "@material-ui/icons/FullscreenExit";
import screenfull from "screenfull";
import { EBDonationSlider, EBProgress } from "../components";

function _EventBoard(props) {
  const { orgId, banner } = props;
  const classes = styles();
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = () => {
    screenfull.toggle();
    setTimeout(() => {
      setIsFullScreen(screenfull.isFullscreen);
    }, 500);
  };

  if (!orgId) return <></>;
  return (
    <div className={classes.container}>
      {!isFullScreen && (
        <FullScreenIcon
          className={classes.fullScreen}
          onClick={toggleFullScreen}
        />
      )}
      {isFullScreen && (
        <FullScreenExitIcon
          className={classes.fullScreen}
          onClick={toggleFullScreen}
        />
      )}

      <EBProgress />

      <EBDonationSlider />

      <div className={classes.bannerWrapper}>
        <img className={classes.banner} src={banner} alt="Banner" />
      </div>
    </div>
  );
}

export const EventBoard = connect(state => {
  const {
    campaign: { orgId, banner },
  } = state;
  return { orgId, banner };
})(_EventBoard);

const styles = makeStyles(_theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    minHeight: "calc(100vh - 40px)",
  },
  fullScreen: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  bannerWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  banner: {
    width: "65%",
    maxWidth: "100%",
  },
}));
