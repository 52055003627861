import React, { useState } from "react";
import { Button, makeStyles } from "@material-ui/core";
import { Field, Form } from "react-final-form";
import { TextField } from "final-form-material-ui";
import classnames from "classnames";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { volunteerActions, setToast } from "../../state";
import { required } from "../../lib/validators";

function _ExistingVolunteerRegistrationForm(props) {
  const {
    volunteerUserEmail,
    volunteerUserVolunteerId,
    campaignId: campaign_id,
    allow_associated_volunteer_accounts,
    actions,
  } = props;
  const classes = styles();
  const dispatch = useDispatch();
  const [forgotPwSubmitting, setForgotPwSubmitting] = useState(false);
  const [resetPwSent, setResetPwSent] = useState(false);

  const onSubmit = async ({ password }) => {
    const create = {
      password,
      campaign_id,
      volunteer_id: volunteerUserVolunteerId,
      email: volunteerUserEmail,
    };

    const res = await actions.addExistingVolunteerToCampaignWithLoginAuth(
      create,
    );
    const { errorMessage } = res;
    if (errorMessage) {
      dispatch(setToast(errorMessage));
      return { submitFailed: true };
    }
    return { submitSucceeded: true };
  };

  const handleForgotPassword = async () => {
    setForgotPwSubmitting(true);
    const data = {
      campaign_id,
      volunteer_id: volunteerUserVolunteerId,
      email: volunteerUserEmail,
    };
    const res = await actions.addExistingVolunteerToCampaignAttemptForgotPW(
      data,
    );
    const { errorMessage } = res;
    if (errorMessage) {
      dispatch(setToast(errorMessage));
      setForgotPwSubmitting(false);
    } else {
      setResetPwSent(true);
    }
  };

  if (resetPwSent) {
    return (
      <div className={classes.resetSentMsg}>
        <div>Check your email.</div>
        <div>We just sent you a link to reset your password.</div>
      </div>
    );
  }
  return (
    <div className={classes.containerWrapper}>
      <div className={classes.container}>
        <div className={classes.instructions}>
          We found your existing account.
        </div>
        <div className={classes.instructions}>
          To register for this campaign enter your password below.
        </div>
        {allow_associated_volunteer_accounts && (
          <div className={classnames(classes.instructions, classes.spaceTop)}>
            Additional, NEW users can be added following registration, in your{" "}
            <span className={classes.strong}>Account</span> or{" "}
            <span className={classes.strong}>Profile</span> settings.
          </div>
        )}

        <Form
          onSubmit={onSubmit}
          render={props => {
            const { handleSubmit, submitting, submitSucceeded } = props;
            return (
              <form onSubmit={handleSubmit}>
                <Field
                  name="password"
                  label="Password"
                  fullWidth={true}
                  component={TextField}
                  validate={required}
                  className={classnames(classes.input)}
                  type="password"
                />

                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  type="submit"
                  disabled={submitting || submitSucceeded}
                >
                  Sign up
                </Button>
              </form>
            );
          }}
        />

        <div className={classnames(classes.instructions, classes.forgotPw)}>
          Forgot password? Reset your password below to register for this
          campaign.
        </div>
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={handleForgotPassword}
          disabled={forgotPwSubmitting}
        >
          Reset my password
        </Button>

        {allow_associated_volunteer_accounts && (
          <div className={classes.addUserContainer}>
            <div className={classes.instructions}>
              To only register a NEW user with this email address,
            </div>
            <div className={classes.instructions}>
              login to your existing account.
            </div>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              target="_blank"
              href={`${process.env.REACT_APP_ADMIN_URL}/add_associated_volunteer_account?campaign_id=${campaign_id}&v=${volunteerUserVolunteerId}`}
            >
              Login and add account
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export const ExistingVolunteerRegistrationForm = connect(
  state => {
    const {
      campaign: { campaignId, allow_associated_volunteer_accounts },
      volunteer: { volunteerUserEmail, volunteerUserVolunteerId },
    } = state;
    return {
      campaignId,
      allow_associated_volunteer_accounts,
      volunteerUserEmail,
      volunteerUserVolunteerId,
    };
  },
  dispatch => {
    return {
      actions: bindActionCreators(volunteerActions, dispatch),
    };
  },
)(_ExistingVolunteerRegistrationForm);

const styles = makeStyles(theme => ({
  resetSentMsg: {
    margin: "48px 16px",
    textAlign: "center",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "26px",
  },
  containerWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginTop: 24,
  },
  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: 700,
    maxWidth: "100%",
  },
  instructions: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.2,
    lineHeight: "20px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "18px",
    },
  },
  input: {
    marginTop: "24px",
    width: "100%",
  },
  button: {
    height: 56,
    width: "100%",
    marginTop: 16,
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.25,
    borderRadius: 28,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  forgotPw: {
    marginTop: 42,
  },
  addUserContainer: {
    marginTop: 42,
  },
  strong: {
    fontWeight: 600,
  },
  spaceTop: {
    marginTop: 6,
  },
}));
