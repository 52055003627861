import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import WarningIcon from "@material-ui/icons/Warning";
import { useField } from "react-final-form";
import { Dialog, Button, makeStyles, useMediaQuery } from "@material-ui/core";
import classNames from "classnames";
import { productActions } from "../../state";

const _SwitchDeliveryWarningDialog = ({
  closeDialog,
  selectedProducts,
  isShipToSchool,
  ship_to_school_label,
}) => {
  const classes = styles();
  const [invalidProducts, setInvalidProducts] = useState([]);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));
  const stsInput = useField("ship_to_school").input;
  const dispatch = useDispatch();

  useEffect(() => {
    const changeToSTS = !isShipToSchool;
    const _invalid = [];
    Object.keys(selectedProducts).forEach(id => {
      const {
        thumbnail_image,
        product_price,
        product_name,
        ship_to_school_only: STS,
        ship_to_home_only: STH,
      } = selectedProducts[id].product;
      if ((changeToSTS && STH) || (!changeToSTS && STS)) {
        _invalid.push({ id, thumbnail_image, product_price, product_name });
      }
    });
    setInvalidProducts(_invalid);
  }, [selectedProducts, isShipToSchool]);

  const changeShipping = () => {
    invalidProducts.forEach(({ id }) =>
      dispatch(productActions.removeProduct(id)),
    );
    stsInput.onChange(!isShipToSchool);
    dispatch(productActions.setIsShipToSchool(!isShipToSchool));
    closeDialog();
  };

  return (
    <Dialog
      open={true}
      onClose={null}
      maxWidth={false}
      fullScreen={isMobile ? true : false}
      classes={{
        paperWidthFalse: classes.paperWidthFalse,
        paper: classes.paper,
      }}
    >
      <div className={classes.container}>
        <div className={classes.header}>
          Are you sure you want to change the shipping method for your order?
        </div>

        {invalidProducts.length > 0 && (
          <div>
            <div className={classes.warningContainer}>
              <WarningIcon color="primary" className={classes.warningIcon} />
              <div className={classes.warningTxt}>
                The following items are only available to{" "}
                <span className={classes.emShippingMethod}>
                  ship to {isShipToSchool ? ship_to_school_label : "home"}
                </span>{" "}
                and will be removed from your cart if you change the shipping
                method:
              </div>
            </div>
            <div className={classes.productCards}>
              {invalidProducts.map((product, index) => {
                const {
                  thumbnail_image,
                  product_price,
                  product_name,
                } = product;
                return (
                  <div key={index} className={classes.card}>
                    <img
                      className={classes.image}
                      src={thumbnail_image}
                      alt="product"
                    />
                    <div className={classes.cardContent}>
                      <div className={classes.productName}>{product_name}</div>
                      <div className={classes.price}>{`$${product_price}`}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        <div className={classes.buttonsContainer}>
          <Button
            color="default"
            variant="contained"
            className={classNames(classes.button, classes.cancelButton)}
            onClick={closeDialog}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={changeShipping}
          >
            Change to ship to {isShipToSchool ? "home" : ship_to_school_label}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export const SwitchDeliveryWarningDialog = connect(state => {
  const {
    product: { selectedProducts, isShipToSchool },
    campaign: { ship_to_school_label },
  } = state;
  return { selectedProducts, isShipToSchool, ship_to_school_label };
})(_SwitchDeliveryWarningDialog);

const styles = makeStyles(theme => ({
  paperWidthFalse: {
    maxWidth: "100%",
  },
  paper: {
    margin: 0,
  },
  container: {
    padding: 32,
    width: 600,
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: 16,
    },
  },
  header: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "26px",
    paddingBottom: 16,
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
      lineHeight: "24px",
    },
  },
  warningContainer: {
    display: "flex",
    marginBottom: 40,
  },
  warningIcon: {
    marginRight: 16,
  },
  warningTxt: {
    fontSize: 16,
    letterSpacing: 0.2,
    lineHeight: "22px",
    flexGrow: 1,
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
      lineHeight: "20px",
    },
  },
  emShippingMethod: {
    fontWeight: 600,
  },
  productCards: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-start",
  },
  card: {
    display: "flex",
    width: 250,
    marginRight: 18,
    marginBottom: 24,
    minHeight: 75,
  },
  image: {
    width: 75,
    height: 75,
    marginRight: 16,
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexGrow: 1,
  },
  productName: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.2,
    lineHeight: "17px",
  },
  price: {
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: 0.25,
    color: theme.palette.primary.main,
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 56,
  },
  button: {
    minWidth: 140,
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.2,
    [theme.breakpoints.down("xs")]: {
      minWidth: "unset",
      fontSize: 14,
    },
  },
  cancelButton: {
    marginRight: "16px",
    color: "#FFFFFF",
  },
}));
