import { APIClient, volunteerRegistrationModes } from "../../lib";
import { uiTypes } from "..";

const volunteerTypes = {
  VOLUNTEER_CREATION_SUCCESS: "VOLUNTEER_CREATION_SUCCESS",
  VOLUNTEER_REGISTRATION_FORM_RESET: "VOLUNTEER_REGISTRATION_FORM_RESET",
  VOLUNTEERS_SUCCESS: "VOLUNTEERS_SUCCESS",
  VOLUNTEERS_CLEAR: "VOLUNTEERS_CLEAR",
  VOLUNTEER_USER_INFO_SET: "VOLUNTEER_USER_INFO_SET",
  VOLUNTEER_REGISTRATION_MODE_CHANGE: "VOLUNTEER_REGISTRATION_MODE_CHANGE",
};

const volunteerActions = {
  createVolunteer(volunteer, orgId, campaignId) {
    return async (dispatch, _getState) => {
      const response = await APIClient.post(
        `/orgs/${orgId}/campaigns/${campaignId}/volunteers`,
        volunteer,
      );
      const { error, code, errorMessage, data, errorData } = response;

      if (error) {
        // if account already exists
        if (
          code === 111 &&
          Array.isArray(errorData?.volunteerAccounts) &&
          errorData?.volunteerAccounts.length
        ) {
          let mode;
          const userData = {};
          const volunteerAccounts = errorData.volunteerAccounts;
          if (volunteerAccounts.length === 1) {
            const { onCampaign, volunteer_id } = volunteerAccounts[0];
            if (onCampaign) mode = volunteerRegistrationModes.LOGIN;
            else mode = volunteerRegistrationModes.ADD_TO_CAMPAIGN;
            userData.volunteerUserVolunteerId = volunteer_id;
          } else {
            mode = volunteerRegistrationModes.SELECT_USER;
            userData.volunteerAccounts = volunteerAccounts;
          }
          dispatch({
            type: volunteerTypes.VOLUNTEER_REGISTRATION_MODE_CHANGE,
            mode,
          });
          dispatch({
            type: volunteerTypes.VOLUNTEER_USER_INFO_SET,
            data: userData,
          });
          return {};
        } else return { errorMessage };
      }
      dispatch({ type: volunteerTypes.VOLUNTEER_CREATION_SUCCESS });
      const { sso_token } = data;
      const loginURL = `${process.env.REACT_APP_ADMIN_URL}/volunteer_sso?sso_token=${sso_token}&campaign_id=${campaignId}`;
      window.location.assign(encodeURI(loginURL));

      return {};
    };
  },
  addExistingVolunteerToCampaignWithLoginAuth(loginAndCampaignData) {
    return async (dispatch, _getState) => {
      const response = await APIClient.post(
        "/volunteer_campaigns/create-with-login-auth",
        loginAndCampaignData,
      );
      2;
      const { error, errorMessage, data } = response;

      if (error) return { errorMessage };

      dispatch({ type: volunteerTypes.VOLUNTEER_CREATION_SUCCESS });
      const { ssoLoginLink } = data;
      // window.open(encodeURI(loginLink), "_blank");
      window.location.assign(encodeURI(ssoLoginLink));
      return {};
    };
  },
  addExistingVolunteerToCampaignAttemptForgotPW(post) {
    return async (_dispatch, _getState) => {
      const response = await APIClient.post(
        "/volunteer_campaigns/create-attempt-forgot-pw",
        post,
      );
      const { error, errorMessage } = response;
      if (error) return { errorMessage };
      return {};
    };
  },
  adminGetVolunteerList(campaignId, authToken) {
    const config = { headers: { authorization: `Bearer ${authToken}` } };
    return async () => {
      const response = await APIClient.get(
        `/campaigns/${campaignId}/volunteers/autocomplete_list`,
        config,
      );
      const { data, error, errorMessage } = response;
      if (error) return { error, errorMessage };
      return { data };
    };
  },
  resetVolunteerRegistrationForm() {
    return { type: volunteerTypes.VOLUNTEER_REGISTRATION_FORM_RESET };
  },
  getVolunteers(
    campaignId,
    campaign_type_id,
    profit_based,
    volunteer_item_based,
    teamId,
    offset,
    limit,
    sort,
    search,
  ) {
    return async (dispatch, _getState) => {
      dispatch({ type: uiTypes.UI_LOADING });

      let url = `/campaigns/${campaignId}/volunteers?campaign_type_id=${campaign_type_id}&profit_based=${profit_based}&offset=${offset}&limit=${limit}&_sort=${sort}&q=${search}`;
      if (teamId) url += `&team_id=${teamId}`;
      if (volunteer_item_based) url += `&volunteer_item_based=true`;
      const response = await APIClient.get(url);

      const { data, errorMessage } = response;

      if (data) {
        dispatch({
          type: volunteerTypes.VOLUNTEERS_SUCCESS,
          refresh: offset === 0,
          ...data,
        });
      }
      if (errorMessage) {
        dispatch({ type: uiTypes.UI_DATA_FETCH_ERROR, errorMessage });
      }

      dispatch({ type: uiTypes.UI_LOADING_RESET });
    };
  },
  clearVolunteers() {
    return { type: volunteerTypes.VOLUNTEERS_CLEAR };
  },
  setVolunteerUserInfo(data) {
    return { type: volunteerTypes.VOLUNTEER_USER_INFO_SET, data };
  },
  changeVolunteerRegistrationMode(mode) {
    return { type: volunteerTypes.VOLUNTEER_REGISTRATION_MODE_CHANGE, mode };
  },
};

export { volunteerTypes, volunteerActions };
