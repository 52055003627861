import React, { useState } from "react";
import { Button, MenuItem, TextField, makeStyles } from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import { volunteerActions } from "../../state";
import { volunteerRegistrationModes } from "../../lib";
import classNames from "classnames";

function _VolunteerRegistrationUserSelector({
  volunteerAccounts,
  campaignId,
  allow_associated_volunteer_accounts,
}) {
  const classes = styles();
  const dispatch = useDispatch();
  const [volunteerId, setVolunteerId] = useState("");
  const [isOnCampaign, setIsOnCampaign] = useState(false);

  const handleClick = () => {
    dispatch(
      volunteerActions.setVolunteerUserInfo({
        volunteerUserVolunteerId: volunteerId,
      }),
    );

    const mode = isOnCampaign
      ? volunteerRegistrationModes.LOGIN
      : volunteerRegistrationModes.ADD_TO_CAMPAIGN;
    dispatch(volunteerActions.changeVolunteerRegistrationMode(mode));
  };

  return (
    <div className={classes.containerWrapper}>
      <div className={classes.container}>
        <div className={classes.instructions}>
          Multiple users share this email address.
        </div>
        <div className={classes.instructions}>
          Please select the user that you are registering.
        </div>
        {allow_associated_volunteer_accounts && (
          <div className={classNames(classes.instructions, classes.spaceTop)}>
            Additional, NEW users can be added following registration, in your{" "}
            <span className={classes.strong}>Account</span> or{" "}
            <span className={classes.strong}>Profile</span> settings.
          </div>
        )}

        {Array.isArray(volunteerAccounts) && (
          <TextField
            label="Select a user"
            value={volunteerId}
            select
            className={classes.input}
            onChange={({ target, currentTarget }) => {
              setVolunteerId(target.value);
              setIsOnCampaign(currentTarget.dataset.on_campaign === "true");
            }}
          >
            {volunteerAccounts.map(({ name, volunteer_id, onCampaign }, i) => (
              <MenuItem
                key={i}
                value={volunteer_id}
                data-on_campaign={onCampaign}
              >
                {name}
              </MenuItem>
            ))}
          </TextField>
        )}

        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleClick}
          disabled={!volunteerId}
        >
          Find my account
        </Button>

        {allow_associated_volunteer_accounts && (
          <div className={classes.addUserContainer}>
            <div className={classes.instructions}>
              To only register a NEW user with this email address,
            </div>
            <div className={classes.instructions}>
              login to your existing account.
            </div>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              target="_blank"
              href={`${process.env.REACT_APP_ADMIN_URL}/add_associated_volunteer_account?campaign_id=${campaignId}`}
            >
              Login and add user
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export const VolunteerRegistrationUserSelector = connect(state => {
  const {
    volunteer: { volunteerAccounts },
    campaign: { campaignId, allow_associated_volunteer_accounts },
  } = state;
  return {
    volunteerAccounts,
    campaignId,
    allow_associated_volunteer_accounts,
  };
})(_VolunteerRegistrationUserSelector);

const styles = makeStyles(theme => ({
  containerWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: 700,
    maxWidth: "100%",
  },
  instructions: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.2,
    lineHeight: "20px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      lineHeight: "18px",
    },
  },
  input: { width: "100%", marginTop: "24px" },
  button: {
    height: 56,
    width: "100%",
    marginTop: 18,
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.25,
    borderRadius: 28,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  addUserContainer: {
    marginTop: 42,
  },
  strong: {
    fontWeight: 600,
  },
  spaceTop: {
    marginTop: 6,
  },
}));
