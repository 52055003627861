import { submissionTypes } from "./actions";
const initialState = {
  showDialog: false,
  recipient: null,
  submissionSuccess: false,
};

const submissionReducers = (state = initialState, action) => {
  switch (action.type) {
    case submissionTypes.SUBMISSION_OPEN_DIALOG:
      return { ...state, showDialog: true, recipient: action.recipient };
    case submissionTypes.SUBMISSION_CLOSE_DIALOG:
      return {
        ...state,
        showDialog: false,
        recipient: null,
        submissionSuccess: false,
      };
    case submissionTypes.SUBMISSION_SUCCESS:
      return { ...state, submissionSuccess: true };
    case submissionTypes.SUBMISSION_RESET:
      return { ...state, submissionSuccess: false };
    default:
      return state;
  }
};

export { submissionReducers };
