import React from "react";
import { IconButton } from "@material-ui/core";
import TwitterIcon from "../../images/Twitter.svg";
import { connect } from "react-redux";
import { getUrls } from "../../state";
import { socialMediaStyles } from "./SocialMedia.styles";
import { socialMediaTextPrefill } from "./socialMediaTextPrefill";
import { AFFILIATE_SUB_CODES, timestamp } from "../../lib";

function _Twitter({ large, siteLink, orgName }) {
  const classes = socialMediaStyles();
  const link = encodeURIComponent(
    `${siteLink}&as=${AFFILIATE_SUB_CODES.TWITTER}&at=${timestamp()}`,
  );
  const text = socialMediaTextPrefill({ orgName });
  const href = `https://twitter.com/intent/tweet?text=${text}&url=${link}`;

  return (
    <IconButton
      href={href}
      target="_blank"
      className={large ? classes.largeButton : classes.button}
    >
      <img
        src={TwitterIcon}
        alt="twitter"
        className={large ? classes.largeIcon : classes.icon}
      ></img>
    </IconButton>
  );
}

export const Twitter = connect(state => {
  const { fullHomeUrlWithAffiliate } = getUrls(state);
  return {
    orgName: state.campaign.name,
    siteLink: fullHomeUrlWithAffiliate,
  };
})(_Twitter);
