import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";

function _UnderBannerHeader({ under_banner_text, under_banner_link }) {
  const classes = styles();
  if (!under_banner_text) return <Fragment />;

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        {under_banner_link && (
          <a
            href={under_banner_link}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.msg}
          >
            {under_banner_text}
          </a>
        )}
        {!under_banner_link && (
          <div className={classes.msg}>{under_banner_text}</div>
        )}
      </div>
    </div>
  );
}

export const UnderBannerHeader = connect(state => {
  const { under_banner_text, under_banner_link } = state.campaign;
  return { under_banner_text, under_banner_link };
})(_UnderBannerHeader);

const styles = makeStyles(theme => ({
  wrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  container: {
    minHeight: "80px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.primary.main,
    padding: "16px 32px",
    width: "100%",
    maxWidth: 2000,
    [theme.breakpoints.down("sm")]: {
      minHeight: "50px",
      padding: "8px 16px",
    },
  },
  msg: {
    color: theme.palette.primary.contrastText,
    fontSize: 24,
    letterSpacing: 0,
    fontWeight: 600,
    textAlign: "center",
    lineHeight: "30px",
    textDecoration: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      lineHeight: "20px",
    },
  },
}));
