import React, { useState, useEffect } from "react";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import { connect } from "react-redux";
import { VideoModal } from "..";

function _MainBanner({
  banner,
  small_banner,
  secondary_banner,
  secondary_small_banner,
  main_banner_video_link,
  forVolunteerPage,
}) {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));
  const classes = styles({ hasVideo: Boolean(main_banner_video_link) });
  const [src, setSrc] = useState(null);
  const [showVideoTrigger, setShowVideoTrigger] = useState(0);

  useEffect(() => {
    if (forVolunteerPage) {
      if (isMobile) {
        setSrc(
          secondary_small_banner
            ? secondary_small_banner
            : secondary_banner
            ? secondary_banner
            : small_banner
            ? small_banner
            : banner,
        );
      } else setSrc(secondary_banner ? secondary_banner : banner);
    } else {
      setSrc(isMobile && small_banner ? small_banner : banner);
    }
  }, [
    isMobile,
    forVolunteerPage,
    banner,
    small_banner,
    secondary_banner,
    secondary_small_banner,
  ]);

  if (!src) return <></>;
  return (
    <div className={classes.container}>
      <img
        className={classes.banner}
        src={src}
        alt="Banner"
        onClick={() => setShowVideoTrigger(showVideoTrigger + 1)}
      />
      {main_banner_video_link && (
        <VideoModal
          videoLink={main_banner_video_link}
          parentShowTrigger={showVideoTrigger}
        />
      )}
    </div>
  );
}

export const MainBanner = connect(state => {
  const {
    campaign: {
      banner,
      small_banner,
      secondary_banner,
      secondary_small_banner,
      main_banner_video_link,
    },
  } = state;
  return {
    banner,
    small_banner,
    secondary_banner,
    secondary_small_banner,
    main_banner_video_link,
  };
})(_MainBanner);

const styles = makeStyles(_theme => ({
  container: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    position: "relative",
    fontSize: 0,
    lineHeight: 0,
    cursor: ({ hasVideo }) => (hasVideo ? "pointer" : "unset"),
  },
  banner: {
    width: "100%",
    maxWidth: 2000,
    display: "block",
  },
}));
