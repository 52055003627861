import { makeStyles } from "@material-ui/core";
import { hexToRGBA } from "../../lib";

export const donationFormStyles = makeStyles(theme => ({
  formContainer: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  donationHashLink: {
    height: 1,
    visibility: "hidden",
    position: "absolute",
    marginTop: -80,
    [theme.breakpoints.down("sm")]: {
      marginTop: -56,
    },
  },
  formWrapper: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  overlay: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 99,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  disabledForm: {
    opacity: 0.4,
  },
  cusInfoAndCartWrapper: {
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "#FFFFFF",
    padding: 0,
  },
  cusInfoAndCartWrapper2Col: {
    backgroundColor: "#F5F5F5",
    padding: "88px 0 24px 0",
  },
  cusInfoAndCartContainer: {
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  cusInfoAndCartContainer2Col: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "start",
  },
  customerInfo: {
    width: 700,
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    borderRadius: 0,
    [theme.breakpoints.up("lg")]: {
      padding: 0,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 16px",
      width: "732px",
    },
  },
  customerInfo2Col: {
    width: 748,
    borderRadius: 8,
    [theme.breakpoints.up("lg")]: {
      padding: "0 24px 48px 24px",
    },
  },
  checkout: {
    fontSize: 32,
    fontWeight: 600,
    letterSpacing: 0.4,
    marginTop: 40,
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  formSubHeader: {
    textAlign: ({ isProduct }) => (isProduct ? "start" : "center"),
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.25,
    marginTop: "40px",
    paddingBottom: 8,
    lineHeight: "22px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "start",
    },
  },
  inputGroup: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  input: {
    width: "calc(50% - 12px)",
    marginTop: "24px",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
    },
  },
  splitInputSection: {
    width: "calc(50% - 12px)",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "100%",
    },
  },
  titleInput: {
    width: 142,
    [theme.breakpoints.down("sm")]: {
      width: () => "100%",
    },
  },
  firstNameInput: {
    width: ({ isGivver }) => (isGivver ? 180 : "100%"),
    [theme.breakpoints.down("sm")]: {
      width: () => "100%",
    },
  },
  aptInput: {
    width: 70,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  addressInput: {
    width: "calc(100% - 80px)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  fullWidthInput: {
    width: "100%",
    maxWidth: "100% !important",
  },
  ccIframe: {
    width: "100%",
    border: "solid 1px #222222",
    height: 56,
    padding: "18px 14px",
    marginTop: 24,
  },
  textarea: {
    resize: "auto !important",
  },
  volLabel: {
    textTransform: "capitalize",
  },
  ccInfoGroup: {
    width: "490px",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      maxWidth: "49%",
    },
  },
  ccInfoInput: {
    marginTop: "24px",
    width: "150px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "145px",
    },
  },
  anonymous: {
    marginRight: "12px",
  },
  processingFeeCheckbox: { marginTop: 12 },
  processingFeeCheckboxLabel: {
    fontSize: 16,
    letterSpacing: 0,
    lineHeight: "18px",
  },
  processingFeeParagraph: {
    marginTop: 6,
    color: hexToRGBA(theme.palette.text.primary, 0.87),
    fontSize: 14,
    letterSpacing: 0.17,
    lineHeight: "18px",
  },
  loader: {
    position: "absolute",
    color: theme.palette.secondary.contrastText,
  },
  endDate: {
    margin: "24px 0",
    color: theme.palette.text.secondary,
    fontSize: "14px",
    letterSpacing: "0.17px",
    textAlign: "center",
    lineHeight: "17px",
  },
  bar: {
    padding: "0 6px",
  },
  longLabel: {
    [theme.breakpoints.down("xs")]: {
      "& .MuiInputLabel-root, fieldset": {
        fontSize: 12,
        paddingRight: 16,
      },
    },
  },
  endedTxt: {
    color: theme.palette.secondary.main,
    fontSize: 32,
    fontWeight: 600,
    letterSpacing: 0.4,
    lineHeight: "35px",
    textAlign: "center",
    padding: "0 12px",
    marginTop: 32,
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
      lineHeight: "27px",
    },
  },

  /////////////  Product Delivery Info Styles  //////////////
  subHeaderBottomSpace: {
    marginBottom: "24px",
  },

  //////////////////// Journal /////////////////////
  journalInstructions: {
    marginTop: 12,
    color: hexToRGBA(theme.palette.text.primary, 0.87),
    fontSize: 16,
    lineHeight: "17px",
    whiteSpace: "pre-wrap",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      lineHeight: "15px",
    },
  },
  genderAndTitle: {
    width: "47% !important",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
    },
  },
  wideLabel: { maxWidth: "95%" },
  journalLogoLabel: {
    marginTop: 24,
    marginBottom: 8,
    color: hexToRGBA(theme.palette.text.primary, 0.87),
    fontSize: 16,
  },
}));
