import { makeStyles } from "@material-ui/core";

export const videoModalStyles = makeStyles(theme => ({
  playIcon: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: "auto",
    fontSize: ({ smallIcon, extraLargeIcon }) =>
      smallIcon ? 40 : extraLargeIcon ? 80 : 66,
    color: "#ffffff",
    cursor: "pointer",
  },
  dialogPaper: {
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      width: "100%",
      maxWidth: "100%",
      backgroundColor: "#000",
    },
  },
  wrapper: {
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      height: "100vh",
      display: "flex",
      alignItems: "center",
    },
  },
  container: {
    backgroundColor: "#000",
    width: 853,
    position: "relative",
    maxWidth: "100%",
    height: 0,
    paddingBottom: "56.25%",
    borderRadius: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  iframe: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    border: 0,
  },
  close: {
    position: "absolute",
    top: 2,
    right: 2,
    color: "#FF0000",
    fontSize: "30px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "26px",
    },
  },
}));
