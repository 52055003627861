import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import classNames from "classnames";
import { isMatchingCampaign } from "../../lib";

export function _Progress(props) {
  const {
    forVolunteer,
    forTeam,
    smallOrg,
    campaign: {
      orgFormattedGoal = 0,
      orgFormattedRaised = 0,
      orgPercentRaised = 0,
      orgPercentOfGoal = 0,
      showBonusGoal,
      bonusPercentRaised = 0,
      formattedBonusGoal = 0,
      volunteerFormattedGoal = 0,
      volunteerFormattedRaised = 0,
      volunteerPercentRaised = 0,
      volunteerPercentOfGoal = 0,
      teamFormattedGoal = 0,
      teamFormattedRaised = 0,
      teamPercentRaised = 0,
      teamPercentOfGoal = 0,
      showProfitProgress,
      showVolunteerItemProgress,
      team_name = "",
      first_name = "",
      last_name = "",
      campaign_type_id,
    },
  } = props;
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const isMatching = isMatchingCampaign(campaign_type_id);
  const [raised, setRaised] = useState("");
  const [raisedLabel, setRaisedLabel] = useState("");
  const [raisedFullTxt, setRaisedFullTxt] = useState("");
  const [percent, setPercent] = useState(0);
  const [percentOfGoal, setPercentOfGoal] = useState(0);
  const [bonusPercent, setBonusPercent] = useState(0);
  const [goal, setGoal] = useState("");
  const [hasBonus, setHasBonus] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const orgPage = Boolean(!forVolunteer && !forTeam && !smallOrg);

  useEffect(() => {
    const itemBased = forVolunteer && showVolunteerItemProgress;
    const raisedClause = showProfitProgress ? "Profit raised" : "Raised";
    setRaisedLabel(itemBased ? "Items sold" : raisedClause);
    switch (true) {
      case forVolunteer:
        setPercent(volunteerPercentRaised);
        setPercentOfGoal(volunteerPercentOfGoal);
        setGoal(
          itemBased ? volunteerFormattedGoal : `$${volunteerFormattedGoal}`,
        );
        setRaised(
          itemBased ? volunteerFormattedRaised : `$${volunteerFormattedRaised}`,
        );
        setRaisedFullTxt(
          itemBased
            ? `${volunteerFormattedRaised} Items sold by me`
            : `$${volunteerFormattedRaised} ${raisedClause} by me`,
        );
        setDisplayName(`${first_name} ${last_name}`);
        break;
      case forTeam:
        setPercent(teamPercentRaised);
        setPercentOfGoal(teamPercentOfGoal);
        setGoal(`$${teamFormattedGoal}`);
        setRaised(`$${teamFormattedRaised}`);
        setRaisedFullTxt(`$${teamFormattedRaised} ${raisedClause} by team`);
        setDisplayName(team_name);
        break;
      default:
        setPercent(orgPercentRaised);
        setPercentOfGoal(orgPercentOfGoal);
        setBonusPercent(bonusPercentRaised);
        setGoal(`$${orgFormattedGoal}`);
        setRaised(`$${orgFormattedRaised}`);
        setRaisedFullTxt(
          `$${orgFormattedRaised} ${raisedClause}${
            smallOrg ? " by campaign" : ""
          }`,
        );
        setHasBonus(showBonusGoal);
        setDisplayName("Campaign");
        break;
    }
  }, [
    orgFormattedRaised,
    volunteerFormattedRaised,
    teamFormattedRaised,
    bonusPercentRaised,
    formattedBonusGoal,
    showBonusGoal,
    forVolunteer,
    showVolunteerItemProgress,
    volunteerFormattedGoal,
    volunteerPercentRaised,
    forTeam,
    teamFormattedGoal,
    teamPercentRaised,
    orgFormattedGoal,
    orgPercentRaised,
    smallOrg,
    showProfitProgress,
    volunteerPercentOfGoal,
    teamPercentOfGoal,
    orgPercentOfGoal,
    first_name,
    last_name,
    team_name,
  ]);

  const classes = styles({
    percent,
    bonusPercent,
    smallOrg,
    hasBonus,
    orgPage,
    isMatching,
  });

  if (isMobile) {
    return (
      <div className={classes.mContainer}>
        {hasBonus && (
          <div
            className={classNames(
              smallOrg ? classes.mBonusGoalSmOrg : classes.mBonusGoal,
            )}
          >
            bonus goal ${formattedBonusGoal}
          </div>
        )}

        {!orgPage && <div className={classes.mDisplayName}>{displayName}</div>}

        <div className={classes.mBarWrapper}>
          <div className={classes.progressBar}>
            <div className={classes.progress}>
              <div className={classes.bonusOverlay}></div>
            </div>
            {showBonusGoal && <div className={classes.bonusProgress}></div>}
          </div>
        </div>

        <div className={classes.mFigures}>
          <div className={classes.mFigureSection}>
            <div className={classes.mNumber}>{raised}</div>
            <div className={classes.mLabel}>{raisedLabel}</div>
          </div>

          <div
            className={classNames(
              classes.mFigureSection,
              classes.mMiddleFigure,
            )}
          >
            <div className={classNames(classes.mNumber, classes.mGoal)}>
              {goal}
            </div>
            <div className={classes.mLabel}>Goal</div>
          </div>

          <div className={classes.mFigureSection}>
            <div className={classes.mNumber}>{percentOfGoal}%</div>
            <div className={classes.mLabel}>{raisedLabel}</div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.figures}>
          <div className={classes.figureSection}>
            <div className={classes.raised}>{raisedFullTxt}</div>
            <div className={classes.percent}>{`${percentOfGoal}% of goal`}</div>
          </div>

          <div className={classes.figureSection}>
            {!hasBonus && (
              <Fragment>
                <div className={classes.goal}>Goal</div>
                <div className={classes.goalAmount}>{goal}</div>
              </Fragment>
            )}
            {hasBonus && (
              <div>
                <span className={classes.crossedoutGoal}>Goal {goal}</span>
                <span className={classes.bonusGoal}>
                  {`Bonus Goal $${formattedBonusGoal}`}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className={classes.progressBar}>
          <div className={classes.progress}>
            <div className={classes.bonusOverlay}></div>
          </div>
          {showBonusGoal && <div className={classes.bonusProgress}></div>}
        </div>
      </div>
    </div>
  );
}

export const Progress = connect(state => {
  return { campaign: state.campaign };
})(_Progress);

const styles = makeStyles(theme => ({
  // DESKTOP
  wrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  container: {
    width: "100%",
    maxWidth: 900,
  },
  figures: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginBottom: ({ smallOrg }) => (smallOrg ? 8 : 16),
  },
  figureSection: {
    display: "flex",
    alignItems: "end",
  },
  raised: {
    fontSize: ({ smallOrg }) => (smallOrg ? 20 : 32),
    fontWeight: 600,
    letterSpacing: ({ smallOrg }) => (smallOrg ? 0.25 : 0.4),
    color: theme.palette.primary.main,
  },
  percent: {
    color: theme.palette.secondary.main,
    fontSize: ({ smallOrg }) => (smallOrg ? 16 : 20),
    fontWeight: 500,
    letterSpacing: ({ smallOrg }) => (smallOrg ? 0.2 : 0.25),
    marginLeft: 16,
    paddingBottom: ({ smallOrg }) => (smallOrg ? 1 : 2),
  },
  goal: {
    color: theme.palette.secondary.main,
    fontSize: ({ smallOrg }) => (smallOrg ? 16 : 20),
    fontWeight: 500,
    letterSpacing: ({ smallOrg }) => (smallOrg ? 0.2 : 0.25),
  },
  goalAmount: {
    fontSize: ({ smallOrg }) => (smallOrg ? 16 : 20),
    letterSpacing: ({ smallOrg }) => (smallOrg ? 0.2 : 0.25),
    paddingLeft: 8,
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  crossedoutGoal: {
    textDecoration: "line-through",
    color: theme.palette.secondary.main,
    marginRight: 18,
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.2,
  },
  bonusGoal: {
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    fontSize: ({ smallOrg }) => (smallOrg ? 18 : 20),
    fontWeight: 600,
    letterSpacing: 0.25,
  },

  // MOBILE
  mContainer: {
    width: ({ orgPage }) => (orgPage ? "100%" : "calc(100% - 32px)"),
    backgroundColor: "#F5F5F5",
    padding: ({ orgPage }) => (orgPage ? "32px 16px" : "20px 0"),
    margin: ({ orgPage }) => (orgPage ? 0 : "0 16px"),
    borderRadius: ({ orgPage }) => (orgPage ? 0 : 8),
  },
  mBarWrapper: {
    width: "100%",
    padding: ({ orgPage }) => (orgPage ? 0 : "0 16px"),
  },
  mFigures: {
    display: "flex",
    marginTop: 24,
  },
  mFigureSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "33.33333%",
  },
  mMiddleFigure: {
    borderRight: `1px solid ${theme.palette.text.secondary}`,
    borderLeft: `1px solid ${theme.palette.text.secondary}`,
  },
  mLabel: {
    fontSize: 12,
    letterSpacing: 0.15,
    textAlign: "center",
  },
  mNumber: {
    color: theme.palette.secondary.main,
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0.17,
    textAlign: "center",
    marginBottom: 8,
  },
  mBonusGoal: {
    marginBottom: 24,
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.2,
    color: theme.palette.primary.main,
    textAlign: "center",
    textTransform: "uppercase",
  },
  mBonusGoalSmOrg: {
    height: 32,
    marginTop: -20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 16,
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0.17,
    textAlign: "center",
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF",
    borderRadius: "8px 8px 0 0",
  },
  mGoal: {
    textDecoration: ({ hasBonus }) => (hasBonus ? "line-through" : "none"),
  },
  mDisplayName: {
    textAlign: "center",
    marginBottom: 20,
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "17px",
    color: theme.palette.primary.main,
    padding: "0 16px",
  },

  // PROGRESS BAR
  progressBar: {
    display: "flex",
    height: ({ smallOrg }) => (smallOrg ? 13 : 24),
    width: "100%",
    borderRadius: 20,
    backgroundColor: ({ isMatching }) => (isMatching ? "#FFFFFF" : "#F5F5F5"),
    [theme.breakpoints.down("sm")]: {
      backgroundColor: () => "#FFFFFF",
      height: ({ smallOrg }) => (smallOrg ? "13px" : "21px"),
    },
  },
  progress: {
    height: "100%",
    width: ({ percent }) => percent,
    backgroundColor: theme.palette.primary.main,
    borderRadius: ({ hasBonus }) => (hasBonus ? "20px 0 0 20px" : 20),
  },
  bonusOverlay: {
    height: "100%",
    backgroundColor: theme.palette.primary.light,
    width: "100%",
    borderRadius: 20,
  },
  bonusProgress: {
    height: "100%",
    width: ({ bonusPercent }) => bonusPercent,
    backgroundColor: theme.palette.primary.main,
    borderRadius: "0 20px 20px 0",
  },
}));
