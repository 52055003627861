import React, { useState, createRef, useLayoutEffect } from "react";
import { Container, makeStyles } from "@material-ui/core";
import parse from "html-react-parser";
import { VideoModal } from "..";

export function PictureAndParagraph({ picture, text_html, video_link }) {
  const [seeMore, setSeeMore] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const ref = createRef();
  const classes = styles({ picture, text_html, seeMore, showButton });

  const toggleSeeMore = () => setSeeMore(prev => !prev);

  useLayoutEffect(() => {
    if (ref?.current?.clientHeight < ref?.current?.scrollHeight) {
      setShowButton(true);
    }
  }, [ref]);

  if (!picture && !text_html) return <></>;
  return (
    <Container>
      <div className={classes.container}>
        {picture && (
          <div className={classes.pictureContainer}>
            <div className={classes.pictureWrapper}>
              <img className={classes.image} src={picture} alt="Org"></img>
              {video_link && <VideoModal videoLink={video_link} />}
            </div>
          </div>
        )}

        {text_html && (
          <div className={classes.textWrapper}>
            <div className={classes.textContainer} ref={ref}>
              <div className="quill" /* don't remove used for quill css */>
                {parse(`${text_html}`)}
              </div>
            </div>
            {showButton && (
              <div className={classes.readMoreContainer}>
                <div className={classes.readMore} onClick={toggleSeeMore}>
                  {!seeMore ? "Read more" : "Show less"}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Container>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: "100%",
    display: "flex",
    margin: "40px 0",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      margin: "32px 0",
    },
  },
  pictureContainer: {
    position: "relative",
    width: ({ text_html }) => (text_html ? "50%" : "100%"),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxHeight: ({ text_html, showButton }) =>
      text_html && showButton ? 386 : text_html ? 350 : 450,
    paddingRight: ({ text_html }) => (text_html ? 30 : 0),
    [theme.breakpoints.only("md")]: {
      paddingLeft: 16,
    },
    [theme.breakpoints.down("sm")]: {
      width: () => "100%",
      maxWidth: () => "100%",
      paddingRight: () => 16,
      paddingLeft: () => 16,
      maxHeight: () => "unset",
      paddingBottom: ({ text_html }) => (text_html ? 32 : 0),
    },
  },
  pictureWrapper: {
    position: "relative",
    lineHeight: 0,
    [theme.breakpoints.down("sm")]: {
      width: "fit-content",
      maxWidth: "100%",
    },
  },
  image: {
    maxWidth: "100%",
    maxHeight: ({ text_html }) => (text_html ? 350 : 450),
    [theme.breakpoints.down("sm")]: {
      maxHeight: () => 500,
    },
  },
  textWrapper: {
    width: ({ picture }) => (picture ? "50%" : "100%"),
    paddingLeft: ({ picture }) => (picture ? 30 : 0),
    display: "flex",
    flexDirection: "column",
    alignItems: ({ picture }) => (picture ? "unset" : "center"),
    [theme.breakpoints.down("sm")]: {
      width: () => "100%",
      maxWidth: () => "100%",
      padding: () => "0 16px",
      alignItems: () => "center",
    },
    [theme.breakpoints.only("md")]: {
      paddingRight: 16,
    },
  },
  textContainer: {
    width: "100%",
    maxHeight: ({ picture, seeMore }) =>
      seeMore ? "100%" : picture ? 350 : 450,
    overflow: ({ seeMore }) => (seeMore ? "visible" : "hidden"),
    maxWidth: ({ picture }) => (picture ? "100%" : 800),
    [theme.breakpoints.down("sm")]: {
      width: () => 800,
      maxWidth: () => "100%",
    },
  },
  readMoreContainer: {
    maxWidth: ({ picture }) => (picture ? "100%" : 800),
    [theme.breakpoints.down("sm")]: {
      width: () => 800,
      maxWidth: () => "100%",
    },
  },
  readMore: {
    marginTop: 10,
    zIndex: 90,
    textDecoration: "underline",
    cursor: "pointer",
    display: "inline-block",
    fontSize: 16,
    letterSpacing: 0,
  },
}));
